import React, { useEffect, useState } from 'react'

import { Button, Checkbox, Typography, Input } from "@material-tailwind/react";

import Header from '../Header';
import { Footer } from '../Footer';
import recommengineFeatureDescriptions from '../recommengineFeatureDescriptions';
import NavigationLinks from '../NavigationLinks';

import { collection, doc, updateDoc, addDoc } from "firebase/firestore";
import { db, auth } from '../../FirebaseConfig'



async function registerAgent(agentName, agentDescription) {
    try {
        let userId = auth.currentUser.uid
        const docRef = await addDoc(collection(db, "recommengine/users/"+ userId +"/data/agents"), {
            name: agentName,
            description: agentDescription,
            userId: userId,
            createdAt: new Date().toISOString(),
        }).catch((error) => {
            console.error("Error adding document: ", error);
            return false
        })

        return docRef
    } catch (e) {
        console.error("Error adding document: ", e);
        return false
    }
}

async function saveAgent(agentName, agentDescription) {
    console.log('save app');
    return registerAgent(agentName, agentDescription)
        .then((result) => {
            console.log(result);
            return result;
        })
        .catch((error) => {
            console.error("Error adding document: ", error);
            return false;
        });
}

export function AgentGenerator() {
    const [currentStep, setCurrentStep] = useState(1)
    const [step, setStep] = useState(1)
    const [agentName, setAgentName] = useState("")
    const [agentDescription, setAgentDescription] = useState("")
    const [agentRole, setAgentRole] = useState("")
    const [agentFeatures, setAgentFeatures] = useState({
        "map": false,
        "chat": false,
        "camera": false,
        "calendar": false,
        "pushNotifications": false,
        "onboardingQuestions": false,
        "userProfile": false,
        "social": false,
    })
    const [agentFeaturesDescription, setAgentFeaturesDescription] = useState([])
    const [errors, setErrors] = useState([])  

    const validateForm = () => {
        if (agentName && agentRole) {
            return true
        } else {
            setErrors(["Agent Name and Role are required"])
            return false
        }
    }

    const proceed = (step) => {
        console.log('proceed from current step ' + currentStep + ' to step ' + step)
        switch(currentStep) {
            case 1:
                if (validateForm()) {
                    setErrors([])
                    setStep(step)
                    setCurrentStep(step)
                }
                break
            case 2:
                if (validateForm()) {
                    setErrors([])
                    setStep(step)
                    setCurrentStep(step)
                }
                break;
            case 3:
                if (validateForm()) {
                    console.log('validated')
                    setErrors([])

                    new Promise(function(resolve) {
                        resolve(saveAgent(agentName, agentRole, agentFeatures))
                    }).then((result) => {
                        console.log('received result from step 3: ' + result)
                        if (result.id) {
                            console.log('agent saved: ' + result.id)
                            setStep(step + 1)
                            setCurrentStep(step + 1)
                        }
                    }).catch((error) => {
                        console.error("Error adding document: ", error);
                    })
                }
                break;
            default:
                setStep(step)
                setCurrentStep(step)
        }
    }
    
    const goDashboard = () => {
        // redirect to /dashboard
        window.location.href = '/dashboard'
    }

    const storeFeature = (event, feature) => {
        // console.log('event: ' + event.target.checked)
        // console.log('feature: ' + feature)

        // setAgentFeatures({ ...agentFeatures, feature: !agentFeatures.feature })
        if (event.target.checked) {
            // setAgentFeatures({ ...agentFeatures, [feature]: true })   
            // Make this the only checked item
            setAgentFeatures({ [feature]: true })
        }  else {
            setAgentFeatures({ [feature]: false })
        }

        // Group feature and description
        const featureDescriptions = recommengineFeatureDescriptions;

        // Find the feature description
        try {
            const featureDescription = featureDescriptions.find(f => f.name === feature).description

            if (event.target.checked) {
                setAgentFeaturesDescription([featureDescription])
                // console.log('Feature description: ' + featureDescription)
            } else {
                // setAgentFeaturesDescription(agentFeaturesDescription.filter(f => f !== featureDescription))
            }
        } catch (error) {
            console.log(error)
        }   
    }

    return (
        <div className='flex flex-col w-full h-full p-2 md:p-2'>
            <Header />

            <div className='flex flex-col md:flex-row w-full'>
                {/* <div>
                    <Typography variant='h4' className='ml-4'>
                        Create New Agent
                    </Typography>

                    <NavigationLinks />
                </div> */}

            {step === 1 && (
                <div className='flex flex-col mt-6 md:mt-2'>
                    <div className=''>
                        <Typography variant='h3'>Name of the agent</Typography>
                    </div>

                    <hr/>

                    <div className='p-2 md:p-10'>
                        <div className='w-1/6'>
                            <Input variant="outlined" label="Name" placeholder="Name" value={agentName} onChange={(e) => setAgentName(e.target.value)}/>
                        </div>
                        
                        <div className='w-1/6 mt-2'>
                            <Input variant="outlined" label="Role" placeholder="Role" value={agentRole} onChange={(e) => setAgentRole(e.target.value)} />
                        </div>

                        <div className='w-3/6 pt-4'>
                            {/* List errors */}
                            {errors.map((error, index) => (
                                <Typography color="red" key={index}>{error}</Typography>
                            ))}
                        </div>

                        <div className='p-2 md:p-10'>
                            <Button onClick={() => proceed(2)}>Continue</Button>
                        </div>
                    </div>
                </div>
            )}

            {step === 2 && (
                <div className='flex flex-col mt-6 md:mt-2'>
                    <div>
                        <Typography variant='h3'>Select a Context</Typography>
                    </div>

                    <hr/>

                    <div className='p-2 md:p-10'>
                        <div>
                            <Checkbox
                                label={
                                    <div>
                                        <Typography color="blue-gray" className="font-medium">
                                            Map
                                        </Typography>
                                        <Typography variant="small" color="gray" className="font-normal">
                                        📍 Show positions on a map.
                                        </Typography>
                                    </div>
                                }
                                onChange={(e) => storeFeature(e, "map")}
                                checked={agentFeatures.map}
                                containerProps={{
                                    className: "-mt-5",
                                }}
                            />
                        </div>

                        <div>
                            <Checkbox
                                label={
                                    <div>
                                    <Typography color="blue-gray" className="font-medium">
                                        Chat
                                    </Typography>
                                    <Typography variant="small" color="gray" className="font-normal">
                                    💬 Allow chat with AI or with other users.
                                    </Typography>
                                    </div>
                                }
                                onChange={(e) => storeFeature(e, "chat")}
                                checked={agentFeatures.chat}
                                containerProps={{
                                    className: "-mt-5",
                                }}
                            />
                        </div>

                        <div>
                            <Checkbox
                                label={
                                    <div>
                                    <Typography color="blue-gray" className="font-medium">
                                        Camera
                                    </Typography>
                                    <Typography variant="small" color="gray" className="font-normal">
                                    📷 Allow camera access
                                    </Typography>
                                    </div>
                                }
                                onChange={(e) => storeFeature(e, "camera")}
                                checked={agentFeatures.camera}
                                containerProps={{
                                    className: "-mt-5",
                                }}
                            />
                        </div>

                        <div>
                            <Checkbox
                                label={
                                    <div>
                                        <Typography color="blue-gray" className="font-medium">
                                        Calendar
                                        </Typography>
                                        <Typography variant="small" color="gray" className="font-normal">
                                        📅 Display a calendar
                                        </Typography>
                                    </div>
                                }
                                onChange={(e) => storeFeature(e, "calendar")}
                                checked={agentFeatures.calendar}
                                containerProps={{
                                    className: "-mt-5",
                                }}
                            />
                        </div>

                        <div>
                            <Checkbox
                                label={
                                    <div>
                                        <Typography color="blue-gray" className="font-medium">
                                            Social
                                        </Typography>
                                        <Typography variant="small" color="gray" className="font-normal">
                                        👥 Allow for friending and presence capabilities
                                        </Typography>
                                    </div>
                                }
                                onChange={() => setAgentFeatures({ ...agentFeatures, social: !agentFeatures.social })}
                                checked={agentFeatures.social}
                                containerProps={{
                                    className: "-mt-5",
                                }}
                            />
                        </div>

                        <div>
                            <Checkbox
                                label={
                                    <div>
                                        <Typography color="blue-gray" className="font-medium">
                                            Push Notifications
                                        </Typography>
                                        <Typography variant="small" color="gray" className="font-normal">
                                        🛎️ Allow for push notifications
                                        </Typography>
                                    </div>
                                }
                                onChange={(e) => storeFeature(e, "pushNotifications")}
                                checked={agentFeatures.pushNotifications}
                                containerProps={{
                                    className: "-mt-5",
                                }}
                            />
                        </div>

                        <div>
                            <Checkbox
                                label={
                                    <div>
                                        <Typography color="blue-gray" className="font-medium">
                                            Onboarding Questions
                                        </Typography>
                                        <Typography variant="small" color="gray" className="font-normal">
                                            🗒️ Require onboarding questions or create quiz
                                        </Typography>
                                    </div>
                                }
                                onChange={(e) => storeFeature(e, "onboardingQuestions")}
                                checked={agentFeatures.onboardingQuestions}
                                containerProps={{
                                    className: "-mt-5",
                                }}
                            />
                        </div>

                        <div>
                            <Checkbox
                                label={
                                    <div>
                                        <Typography color="blue-gray" className="font-medium">
                                            User profile
                                        </Typography>
                                        <Typography variant="small" color="gray" className="font-normal">
                                        👤 Allow for user profile
                                        </Typography>
                                    </div>
                                }
                                onChange={(e) => storeFeature(e, "userProfile")}
                                checked={agentFeatures.userProfile}
                                containerProps={{
                                    className: "-mt-5",
                                }}
                            />
                        </div>

                        <div>
                            <Checkbox
                                label={
                                    <div>
                                        <Typography color="blue-gray" className="font-medium">
                                            Custom Feature
                                        </Typography>
                                        <Typography variant="small" color="gray" className="font-normal">
                                        🤖 Add custom feature
                                        </Typography>
                                    </div>
                                }
                                onChange={(e) => storeFeature(e, "customFeature")}
                                checked={agentFeatures.customFeature}
                                containerProps={{
                                    className: "-mt-5",
                                }}
                            />
                        </div>
                        

                        <div className='p-2 md:p-10'>
                            <Button variant='outlined' onClick={() => setStep(1)}>Go Back</Button>
                            <Button className='ml-2' onClick={() => proceed(3)}>Continue</Button>
                        </div>
                    </div>
                </div>
            )}

            {step === 3 && (
                <div className='flex flex-col mt-6 md:mt-2'>
                    <div>
                        <Typography variant='h3'>Summary</Typography>
                    </div>

                    <hr/>

                    <div className='p-2 md:p-10'>
                        <Typography color="blue-gray" className="font-bold">
                        Agent Name
                        </Typography>
                        <Typography color="blue-gray" className="font-medium">
                            {agentName}
                        </Typography>

                        <Typography color="blue-gray" className="font-bold">
                            Package Name
                        </Typography>

                        <Typography color="blue-gray" className="font-medium">
                            {agentRole}
                        </Typography>

                        <Typography color="blue-gray" className="font-bold">
                            Features
                        </Typography>

                        {(agentFeaturesDescription).map(feature => (
                            <div>
                                <Typography color="blue-gray" className="font-medium" key={feature}>
                                    {feature}
                                </Typography>
                            </div>
                        ))}

                        <div className='p-2 md:p-10'>
                            <Button variant='outlined' onClick={() => setStep(2)}>Go Back</Button>
                            <Button className='ml-2' onClick={() => proceed(3)}>Save and Continue</Button>
                        </div>
                    </div>
                </div>
            )}

            {step === 4 && (
                <div className='flex flex-col m-6 md:mt-2 w-full'>
                    <div className='w-full text-center p-2 md:p-10'>
                        <Typography variant='h1'>All Done!</Typography>

                        <Button className='ml-2' onClick={() => goDashboard()}>Go to dashboard</Button>
                    </div>
                </div>
            )}

            </div>

            <Footer />
        </div>
    );
}