import React, { useState, useEffect } from 'react'
import {
    Card,
    Input,
    Checkbox,
    Button,
    Typography,
    Select,
  } from "@material-tailwind/react";

// import QuestionCard from '../question/QuestionCard'
import LoadingIndicator from '../LoadingIndicator';

import Header from '../Header';
// import { Footer } from './Footer';
import NavigationLinks from '../NavigationLinks';
// import Quiz from './Quiz';

import { collection, doc, updateDoc, addDoc } from "firebase/firestore";
import { db, auth } from '../../FirebaseConfig'

function QuizEditor(props) {
    const { standalone } = props;
    const [quizId, setQuizId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isEditing, setIsEditing] = useState(true);
    const [title, setTitle] = useState(props.title || "");
    const [currentUser, setCurrentUser] = useState(null);
    const [questions, setQuestions] = useState(props.questions || []);

    useEffect(() => {
        auth.onAuthStateChanged(user => {
            if (user) {
                setCurrentUser(user);
                setLoading(false);
            } else {
                setCurrentUser(null);
                setLoading(false);
            }
        })        
    }, [currentUser])

    if (loading) {
        return (
            <div className='flex flex-col w-full h-full'>
                <LoadingIndicator />
            </div>
        )
    }

    const addQuestion = (question) => {
        setLoading(true);
        question.answers = [];
        question.title = question;
        question.type = "text";

        setQuestions([...questions, question]);
        setLoading(false);
    }

    const removeQuestion = (index) => {
        const newQuestions = [...questions];
        newQuestions.splice(index, 1);

        setLoading(true);
        setQuestions(newQuestions);
        setLoading(false);
    }

    const saveQuestion = (question, index) => {
        setLoading(true);
        questions[index] = question;
        setLoading(false);
    }

    const addAnswer = (answer, index) => {
        setLoading(true);
        questions[index].answers.push(answer);
        setLoading(false);
    }

    const removeAnswer = (index, answerIndex) => {
        setLoading(true);
        questions[index].answers.splice(answerIndex, 1);
        setLoading(false);
    }

    const saveQuiz = () => {
        setLoading(true);

        if (quizId) {
            updateQuiz().then((docRef) => {
                setLoading(false);
            })
        } else {
            createQuiz().then((docRef) => {
                if (docRef) {
                    setQuizId(docRef.id);
                } 

                setLoading(false);
            })
        }
    }

    async function createQuiz() {
        try {
            // const docRef = await addDoc(collection(db, "quizzes"), {
            const docRef = await addDoc(collection(db, "recommengine/users/"+ currentUser.uid +"/data/quizzes"), {
                title: title,
                questions: questions,
                userId: auth.currentUser.uid,
                createdAt: new Date().toISOString(),
            }).catch((error) => {
                console.error("Error adding document: ", error);
                return false
            })
    
            return docRef
        } catch (e) {
            console.error("Error adding document: ", e);
            return false
        }
    }

    async function updateQuiz() {
        try {
            // const docRef = await updateDoc(doc(db, "quizzes", quizId), {
            const docRef = await updateDoc(doc(db, "recommengine/users/"+ currentUser.uid +"/data/quizzes", quizId), {
                title: title,
                questions: questions,
                userId: auth.currentUser.uid,
                createdAt: new Date().toISOString(),
            }).catch((error) => {
                console.error("Error updating document: ", error);
                return false
            })
    
            return docRef
        } catch (e) {
            console.error("Error updating document: ", e);
            return false
        }
    }

    if (loading) {
        return (
            <div className='flex flex-col w-full h-full'>
                <LoadingIndicator />
            </div>
        )
    }

    if (isEditing) {
        return (
            <div className='flex flex-col w-full h-full p-2 md:p-2'>
                { !standalone && (
                    <Header />
                )}

                <div className='flex flex-col md:flex-row w-full'>
                    { !standalone && (
                        <div>
                            <Typography variant='h3' className='ml-4'>
                                Create New Quiz
                            </Typography>

                            <NavigationLinks />
                        </div>
                    )}

                    <div className='flex flex-col mt-6 md:mt-2'>
                        
                        <div className='w-full'>
                            <div>
                                { !standalone && (
                                    <Typography variant='h2'>Name of the quiz</Typography>
                                )}

                                { standalone && (
                                    <Typography variant='h2'>{title}</Typography>
                                )}
                            </div>

                            <hr />
        
                            <div className='w-full p-2 md:p-6'>
                                <Input label="Quiz Title" value={title} onChange={(e) => setTitle(e.target.value)} />
                            

                                <div className='flex flex-col w-5/6 md:ml-0'>
                                    <Button className="mt-6" color="black" onClick={() => addQuestion({})}>
                                    Add New Question
                                    </Button>

                                    <div className='w-full h-1/2 p-2 md:p-4'>
                                    </div>

                                    {questions.map((question, index) => (
                                        <div key={index + 'question'} className='flex flex-col md:flex-row w-full md:ml-2 p-0'>
                                            <div>
                                                <Input label="Question" value={question.question} onChange={(e) => saveQuestion({question: e.target.value}, index)} />
                                            </div>
                                            <div>
                                                <Select label="Question Type" value={question.type} onChange={(e) => saveQuestion({type: e.target.value}, index)}>
                                                    <option value="text">Text</option>
                                                    <option value="code">Code</option>
                                                    <option value="select">Select</option>
                                                    <option value="image_select">Image Select</option>
                                                    <option value="single_choice">Single Choice</option>
                                                    <option value="multi_choice">Multi Choice</option>
                                                </Select>
                                            </div>
                                            <div>
                                                <Button className="mt-6" fullWidth color="red" onClick={() => removeQuestion(index)}>
                                                Remove Question
                                                </Button>
                                            </div>
                                        </div>
                                    ))}
                                    
                                </div>

                                <div className='flex flex-row ml-6'>
                                    {/* <Button className="mt-6" color="black">
                                    Save Questions
                                    </Button> */}

                                    <Button className="mt-6 ml-2" color="black" onClick={() => saveQuiz()}>
                                    Save Quiz
                                    </Button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className='flex flex-col'>

                {/* <div className='flex flex-col w-full ml-6 mt-6 text-center'>
                    <div>
                        <Typography variant='h1'>
                            {title}
                        </Typography>
                    </div>
                    <div>
                        <Typography variant='h6'>
                            {questions.length} Questions
                        </Typography>
                        </div>
                </div> */}

                {/* {questions.map((question, index) => (
                    <QuestionCard
                        key={index + 'question'}
                        question={question.question}
                        answers={question.answers}
                        type={question.type}
                    />
                ))} */}

                <div className='flex flex-row w-1/2 ml-6'>
                    <Button className="mt-2 mb-6" fullWidth color="black">
                    Submit
                    </Button>
                </div>
            </div>
        )
    }

}

export default QuizEditor