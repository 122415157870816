import { Typography, Button } from "@material-tailwind/react";
import General from '../layouts/General';

function AboutUs() {
  return (
    <General>
      <div className="max-w-4xl mx-auto">
        {/* Hero Section */}
        <section className="bg-gradient-to-b from-military-green-50 via-earth-brown-50 to-white py-16 rounded-sm border border-military-green-200">
          <div className="px-6">
            <Typography variant="h3" className="text-center mb-6">
              <span className="bg-gradient-to-r from-military-green-600 to-slate-blue-500 bg-clip-text text-transparent">
                Building the Future of Custom Business Apps
              </span>
            </Typography>
            
            <div className="space-y-6 text-military-green-600">
              <Typography>
                Founded by Brian Cary of BCP Solutions LLC, Recommengine is revolutionizing how businesses create and deploy custom mobile applications. Our platform empowers organizations to build tailored solutions without coding expertise.
              </Typography>

              <Typography>
                We're currently in beta testing, with our full platform launch scheduled for late 2024. Our mission is to democratize app development, making it accessible to businesses of all sizes.
              </Typography>

              <div className="bg-military-green-50/50 p-6 rounded-sm border-2 border-military-green-200">
                <Typography variant="h6" className="text-military-green-700 mb-2">
                  🚀 Coming Soon
                </Typography>
                <Typography className="text-sm">
                  We're working hard to bring you detailed information about our team, mission, and vision. Check back soon for updates about our journey and the future of Recommengine.
                </Typography>
              </div>
            </div>

            {/* New Benefits Section */}
            <div className="mt-12 space-y-8">
              <Typography variant="h4" className="text-military-green-700 text-center">
                Why Choose Recommengine?
              </Typography>
              
              <div className="grid md:grid-cols-2 gap-6">
                <div className="p-6 bg-military-green-50/30 rounded-lg border border-military-green-100">
                  <Typography variant="h6" className="text-military-green-700 mb-3">
                    🔧 Enhanced Efficiency
                  </Typography>
                  <Typography className="text-sm text-military-green-600">
                    Our field service management platform seamlessly integrates critical functions, 
                    empowering your team to diagnose issues, manage resources, and coordinate 
                    dispatches all in one place.
                  </Typography>
                </div>

                <div className="p-6 bg-military-green-50/30 rounded-lg border border-military-green-100">
                  <Typography variant="h6" className="text-military-green-700 mb-3">
                    ⭐ Improved Customer Experience
                  </Typography>
                  <Typography className="text-sm text-military-green-600">
                    Leveraging AI and advanced data analytics, our intelligent applications 
                    automate processes and deliver enhanced outcomes, resulting in superior 
                    customer satisfaction and engagement.
                  </Typography>
                </div>
              </div>
            </div>

            <div className="mt-8 flex flex-col items-center space-y-4">
              <a
                href="https://testflight.apple.com/join/xes7Lx3R"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block px-6 py-3 rounded-lg bg-gradient-to-r from-military-green-500 to-slate-blue-500 text-white text-center hover:opacity-90 transition-opacity"
              >
                Join iOS Beta
              </a>
              <a
                  href="https://appdistribution.firebase.dev/i/3aaf9f7e69a4e8a8"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-block px-6 py-3 rounded-lg bg-gradient-to-r from-military-green-500 to-slate-blue-500 text-white text-center hover:opacity-90 transition-opacity"
                >
                  Join Android Beta
                </a>
            </div>
          </div>
        </section>
      </div>
    </General>
  );
}

export default AboutUs;