import { useRouteError } from "react-router-dom";
import General from "../layouts/General";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <General>
      <div id="error-page" className="flex flex-col w-full h-full p-20 justify-center text-center">
        <h1>Oops!</h1>
        <p>Sorry, an unexpected error has occurred.</p>
        <div className="mt-20">
          <a className="" href="/">Go Home</a>
        </div>
      </div>
    </General>
  );
}