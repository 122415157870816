import React from 'react';

export function BetaDownloadButtons() {
  return (
    <div className="flex flex-col sm:flex-row items-center justify-center gap-4 px-4 sm:px-0">
      <a 
        href="https://testflight.apple.com/join/xes7Lx3R"
        target="_blank"
        rel="noopener noreferrer"
        className="w-full sm:w-auto px-4 sm:px-8 py-3 sm:py-4 bg-gradient-to-r from-military-green-500 to-slate-blue-500 text-white font-semibold rounded-lg hover:opacity-90 transition-all duration-300 shadow-lg hover:shadow-xl flex items-center justify-center gap-2"
      >
        <svg className="w-5 h-5 sm:w-6 sm:h-6" viewBox="0 0 24 24" fill="currentColor">
          <path d="M18.71 19.5c-.83 1.24-1.71 2.45-3.05 2.47-1.34.03-1.77-.79-3.29-.79-1.53 0-2 .77-3.27.82-1.31.05-2.3-1.32-3.14-2.53C4.25 17 2.94 12.45 4.7 9.39c.87-1.52 2.43-2.48 4.12-2.51 1.28-.02 2.5.87 3.29.87.78 0 2.26-1.07 3.81-.91.65.03 2.47.26 3.64 1.98-.09.06-2.17 1.28-2.15 3.81.03 3.02 2.65 4.03 2.68 4.04-.03.07-.42 1.44-1.38 2.83M13 3.5c.73-.83 1.94-1.46 2.94-1.5.13 1.17-.34 2.35-1.04 3.19-.69.85-1.83 1.51-2.95 1.42-.15-1.15.41-2.35 1.05-3.11z"/>
        </svg>
        Try iOS Beta
      </a>
      <a 
        href="https://appdistribution.firebase.dev/i/3aaf9f7e69a4e8a8"
        target="_blank"
        rel="noopener noreferrer"
        className="w-full sm:w-auto px-4 sm:px-8 py-3 sm:py-4 bg-gradient-to-r from-military-green-500 to-slate-blue-500 text-white font-semibold rounded-lg hover:opacity-90 transition-all duration-300 shadow-lg hover:shadow-xl flex items-center justify-center gap-2"
      >
        <svg className="w-5 h-5 sm:w-6 sm:h-6" viewBox="0 0 24 24" fill="currentColor">
          <path d="M16.61 15.15c-.46 0-.84-.37-.84-.84s.37-.84.84-.84.84.37.84.84-.37.84-.84.84m-9.22 0c-.46 0-.84-.37-.84-.84s.37-.84.84-.84.84.37.84.84-.37.84-.84.84m9.42-5.93l1.6-2.77c.09-.16.04-.36-.12-.45-.16-.09-.36-.04-.45.12l-1.63 2.82c-1.25-.55-2.66-.87-4.21-.87-1.55 0-2.96.32-4.21.87L6.16 6.12c-.09-.16-.29-.21-.45-.12-.16.09-.21.29-.12.45l1.6 2.77C4.26 10.81 2.5 13.39 2.5 16.32c0 .06.01.12.01.18h18.98c0-.06.01-.12.01-.18 0-2.93-1.76-5.51-4.69-7.1M7.06 18.69c-1.02 0-1.86-.84-1.86-1.86s.84-1.86 1.86-1.86 1.86.84 1.86 1.86-.84 1.86-1.86 1.86m9.88 0c-1.02 0-1.86-.84-1.86-1.86s.84-1.86 1.86-1.86 1.86.84 1.86 1.86-.84 1.86-1.86 1.86"/>
        </svg>
        Join Android Beta
      </a>
    </div>
  );
} 