import React, { useEffect, useState } from 'react';
import { collection, query, getDocs } from "firebase/firestore";
import General from '../../layouts/General';
import RequestsContent from './RequestsContent';
import { db, auth } from '../../FirebaseConfig';

const getMyRequests = async (userId) => {
    const querySnapshot = await getDocs(
        query(collection(db, "recommengine/users/" + userId + "/data/requests"))
    );

    const requests = [];

    querySnapshot.forEach((doc) => {
        const data = doc.data();
        requests.push({ 
            id: doc.id,
            ...data,
            priority: data.priority || 'medium',
            date: data.date ? new Date(data.date).toLocaleDateString() : 'N/A'
        });
    });

    return requests.sort((a, b) => {
        if (a.date === b.date) {
            return a.priority.localeCompare(b.priority);
        }
        return new Date(b.date) - new Date(a.date);
    });
}

export default function Requests() {
    const [myRequests, setMyRequests] = useState([]);
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async user => {
            try {
                if (user) {
                    setCurrentUser(user);
                    const requests = await getMyRequests(user.uid);
                    setMyRequests(requests);
                } else {
                    setCurrentUser(null);
                    setMyRequests([]);
                }
            } catch (error) {
                console.error('Error fetching requests:', error);
            } finally {
                setLoading(false);
            }
        });

        return () => unsubscribe();
    }, []);

    return (
        <General title={`Requests (${myRequests.length})`}>
            <RequestsContent currentUser={currentUser} myRequests={myRequests} />
        </General>
    );
}

