import { Typography } from "@material-tailwind/react";

export function FeaturesList() {
  return (
    <Typography className="text-center mt-6 text-lg md:text-xl text-military-green-700 max-w-3xl mx-auto opacity-0 animate-[fadeIn_0.5s_ease-out_0.2s_forwards] font-medium">
      <span className="flex flex-col items-center">
        Combine
        <span className="inline-flex flex-col md:flex-row flex-wrap justify-center gap-3 mx-2 mb-2 md:mb-0">
          <span className="flex items-center gap-1">
            <span className="transform hover:scale-110 transition-transform duration-200 hover:rotate-12">📸</span>
            <span className="font-semibold">Photos</span>
          </span>
          <span className="flex items-center gap-1">
            <span className="transform hover:scale-110 transition-transform duration-200 hover:-rotate-12">📍</span>
            <span className="font-semibold">Location</span>
          </span>
          <span className="flex items-center gap-1">
            <span className="transform hover:scale-110 transition-transform duration-200 hover:rotate-12">📅</span>
            <span className="font-semibold">Calendar</span>
          </span>
          <span className="flex items-center gap-1">
            <span className="transform hover:scale-110 transition-transform duration-200 hover:-rotate-12">💬</span>
            <span className="font-semibold">Chat</span>
          </span>
        </span>
        <span className="mt-2 md:mt-0">features to create powerful engines for any purpose.</span>
      </span>
    </Typography>
  );
} 