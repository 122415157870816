import React from 'react'
import {
    Typography,
    Button,
} from "@material-tailwind/react";

// import LoadingIndicator from '../LoadingIndicator';

export default function RegularButton({ text, url, onClick, filled = false }) {

    if (onClick) {
        return (
            <div className='flex flex-row md:flex-col mt-2 p-1'>
                <Button
                    color={filled ? "black" : "white"}
                    className={`font-bold border-solid border-2 border-black w-[200px] text-center ${
                        filled ? "bg-black text-white" : ""
                    }`}
                    onClick={onClick}
                >
                    {text}
                </Button>
            </div>
        );
    }

    return (
        <div className='flex flex-row md:flex-col mt-2 p-1'>
            <Typography
                as="a"
                href={url}
                color={filled ? "white" : "blue-gray"}
                className={`font-bold border-solid border-2 border-black w-[200px] p-1 text-center ${
                        filled ? "bg-black text-white" : ""
                    }`}
            >
                {text}
            </Typography>
        </div>
    );
}