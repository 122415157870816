import React from 'react';
import { Typography, Card, CardBody, Carousel, Dialog, DialogHeader, DialogBody, DialogFooter, CircularProgress } from "@material-tailwind/react";
import { useState } from 'react';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { serverTimestamp, doc, arrayUnion, updateDoc, getDoc, setDoc } from 'firebase/firestore';
import { app, db } from '../../FirebaseConfig';
import { getStorage } from 'firebase/storage';

const storage = getStorage(app);

export default function CameraSection({ photos, currentUser, engineId, onDeletePhoto, onPhotoAdded }) {
    const [activeIndex, setActiveIndex] = useState(0);
    const [isShareOpen, setIsShareOpen] = useState(false);
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [photoToDelete, setPhotoToDelete] = useState(null);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [selectedPhoto, setSelectedPhoto] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [uploadError, setUploadError] = useState(null);
    const [uploadSuccess, setUploadSuccess] = useState(false);

    const handleDownload = (photo) => {
        window.open(typeof photo === 'string' ? photo : photo.url, '_blank');
    };

    const handleCopyLink = async () => {
        try {
            const photoUrl = typeof photos[activeIndex] === 'string' 
                ? photos[activeIndex] 
                : photos[activeIndex].url;
            await navigator.clipboard.writeText(photoUrl);
            alert('Link copied to clipboard!');
        } catch (err) {
            console.error('Failed to copy link:', err);
        }
    };

    const handleEmailShare = () => {
        const subject = encodeURIComponent('Check out this engine photo');
        const body = encodeURIComponent(`I wanted to share this engine photo with you: ${photos[activeIndex].url}`);
        window.open(`mailto:?subject=${subject}&body=${body}`);
    };

    const handleDelete = async (photoUrl) => {
        setPhotoToDelete(photoUrl);
        setDeleteConfirmOpen(true);
    };

    const confirmDelete = async () => {
        try {
            await onDeletePhoto(photoToDelete);
            setDeleteConfirmOpen(false);
            setPhotoToDelete(null);
        } catch (error) {
            console.error('Failed to delete photo:', error);
            alert('Failed to delete photo. Please try again.');
        }
    };

    const handlePhotoClick = (photo) => {
        setSelectedPhoto(photo);
        setPreviewOpen(true);
    };

    return (
        <div className='flex flex-col w-full h-full'>
            <div className="flex justify-end mb-4 sticky top-0 z-10 bg-white pt-2">
                <div className="w-full md:w-1/3">
                    <label htmlFor="photo-upload" className="w-full bg-black hover:bg-gray-800 text-white font-bold py-2 px-3 rounded text-sm sm:text-base cursor-pointer flex justify-center items-center gap-2">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 sm:w-5 sm:h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5A3.375 3.375 0 0 0 6.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0 0 15 2.25h-1.5a2.251 2.251 0 0 0-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 0 0-9-9Z" />
                        </svg>
                        Upload Photo
                    </label>
                    <input
                        id="photo-upload"
                        type="file"
                        accept="image/*"
                        multiple
                        className="hidden"
                        onChange={async (e) => {
                            const files = Array.from(e.target.files);
                            setIsUploading(true);
                            setUploadError(null);
                            
                            try {
                                for (const file of files) {
                                    try {
                                        // Debug logs
                                        console.log('User ID:', currentUser.uid);
                                        console.log('File name:', file.name);
                                        const storagePath = `images/${currentUser.uid}/${file.name}`;
                                        console.log('Storage path:', storagePath);
                                        
                                        // Create a reference to the storage location
                                        const storageRef = ref(storage, storagePath);
                                        console.log('Storage reference:', storageRef);
                                        
                                        // Upload the file
                                        const snapshot = await uploadBytes(storageRef, file);
                                        console.log('Upload successful:', snapshot);
                                        
                                        // Get the download URL
                                        const downloadURL = await getDownloadURL(snapshot.ref);
                                        
                                        // Add the photo info to Firestore
                                        const photoDoc = {
                                            url: downloadURL,
                                            fileName: file.name,
                                            uploadedAt: new Date().toISOString(),
                                        };
                                        
                                        // Update the correct document path
                                        const engineRef = doc(db, 'recommengine', 'users', currentUser.uid, 'data', 'engines', engineId);
                                        
                                        // Get the current document
                                        const engineDoc = await getDoc(engineRef);
                                        
                                        if (engineDoc.exists()) {
                                            const currentData = engineDoc.data();
                                            const images = currentData.images || [];
                                            
                                            // Only add if the URL isn't already in the array
                                            if (!images.includes(downloadURL)) {
                                                await updateDoc(engineRef, {
                                                    images: [...images, downloadURL]
                                                });
                                            }
                                        } else {
                                            // Create new document if it doesn't exist
                                            await setDoc(engineRef, {
                                                images: [downloadURL]
                                            });
                                        }
                                        
                                    } catch (error) {
                                        console.error('Error uploading file:', error);
                                        alert(`Failed to upload ${file.name}. Please try again.`);
                                    }
                                }
                                
                                setUploadSuccess(true);
                                // Trigger data reload here - you'll need to pass this as a prop
                                if (onPhotoAdded) {
                                    await onPhotoAdded();
                                }
                            } catch (error) {
                                console.error('Error uploading file:', error);
                                setUploadError(error.message || 'Failed to upload photo(s). Please try again.');
                            } finally {
                                setIsUploading(false);
                                e.target.value = '';
                            }
                        }}
                    />
                </div>
            </div>

            <div className="flex-1 overflow-y-auto">
                {!photos || photos.length === 0 ? (
                    <div className='flex flex-col w-full p-4 border-2 mb-2'>
                        <Typography variant="h6" className='text-gray-500 mb-2'>No photos found</Typography>
                        <Typography className='text-gray-400 text-sm'>
                            • Click "Upload Photo(s)" to add engine images
                            • Supported formats: JPG, PNG, GIF
                            • Maximum file size: 10MB per image
                        </Typography>
                    </div>
                ) : (
                    <div className="flex flex-col md:flex-row gap-4">
                        <Card className="w-full md:w-2/3 h-full">
                            <CardBody className="p-0">
                                <Carousel 
                                    className="rounded-xl h-full" 
                                    autoplay={!(isShareOpen || deleteConfirmOpen || previewOpen)} 
                                    autoplayDelay={3000} 
                                    mobile={{ loop: true }}
                                    prevArrow={({ handlePrev }) => (
                                        <button
                                            onClick={handlePrev}
                                            className="absolute top-1/2 left-4 -translate-y-1/2 rounded-full p-2 bg-black/50 hover:bg-black/70 text-white transition-all"
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                                            </svg>
                                        </button>
                                    )}
                                    nextArrow={({ handleNext }) => (
                                        <button
                                            onClick={handleNext}
                                            className="absolute top-1/2 right-4 -translate-y-1/2 rounded-full p-2 bg-black/50 hover:bg-black/70 text-white transition-all"
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5L15.75 12l-7.5 7.5" />
                                            </svg>
                                        </button>
                                    )}
                                >
                                    {photos.map((photo, index) => (
                                        <div key={index} className="relative h-full">
                                            <img
                                                src={typeof photo === 'string' ? photo : photo.url}
                                                alt={`Photo ${index + 1}`}
                                                className="h-full w-full object-contain"
                                                onClick={() => handlePhotoClick({
                                                    url: typeof photo === 'string' ? photo : photo.url
                                                })}
                                            />
                                            <button
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleDelete(photo.url);
                                                }}
                                                className="absolute top-4 right-4 bg-red-500 hover:bg-red-600 text-white p-2 rounded-full"
                                                title="Delete photo"
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                                </svg>
                                            </button>
                                        </div>
                                    ))}
                                </Carousel>
                            </CardBody>
                        </Card>

                        <div className="w-full md:w-1/3 space-y-4">
                            <button 
                                onClick={() => handleDownload({ url: photos[activeIndex] })}
                                className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-4 rounded"
                            >
                                Download Photo
                            </button>
                            <button 
                                onClick={() => setIsShareOpen(true)}
                                className="w-full bg-green-500 hover:bg-green-600 text-white font-bold py-3 px-4 rounded"
                            >
                                Share Photo
                            </button>
                        </div>
                    </div>
                )}

                {/* Share Modal */}
                <div 
                    className={`fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 ${isShareOpen ? '' : 'hidden'}`}
                    onClick={() => setIsShareOpen(false)}
                >
                    <div 
                        className="bg-white rounded-lg shadow-xl max-w-md w-full"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="p-6">
                            <h3 className="text-lg font-medium mb-4">Share Photo</h3>
                            <div className="space-y-4">
                                <button
                                    onClick={handleCopyLink}
                                    className="w-full flex items-center justify-center gap-2 bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-4 rounded"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5A3.375 3.375 0 0 0 6.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0 0 15 2.25h-1.5a2.251 2.251 0 0 0-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 0 0-9-9Z" />
                                    </svg>
                                    Copy Link
                                </button>
                                
                                <button
                                    onClick={handleEmailShare}
                                    className="w-full flex items-center justify-center gap-2 bg-green-500 hover:bg-green-600 text-white font-bold py-3 px-4 rounded"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
                                    </svg>
                                    Share via Email
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Delete Confirmation Modal */}
                <div 
                    className={`fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 ${deleteConfirmOpen ? '' : 'hidden'}`}
                    onClick={() => setDeleteConfirmOpen(false)}
                >
                    <div 
                        className="bg-white rounded-lg shadow-xl max-w-md w-full"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="p-6">
                            <h3 className="text-lg font-medium mb-4">Confirm Deletion</h3>
                            {photoToDelete && (
                                <div className="mb-4">
                                    <img
                                        src={photos.find(p => p.url === photoToDelete)?.url}
                                        alt="Photo to delete"
                                        className="w-full max-h-48 object-contain rounded"
                                    />
                                </div>
                            )}
                            <p className="mb-4">Are you sure you want to delete this photo? This action cannot be undone.</p>
                            <div className="flex justify-end space-x-2">
                                <button
                                    onClick={() => setDeleteConfirmOpen(false)}
                                    className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={confirmDelete}
                                    className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
                                >
                                    Delete
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Photo Preview Modal */}
                <div 
                    className={`fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-[60] ${previewOpen ? '' : 'hidden'}`}
                    onClick={() => setPreviewOpen(false)}
                >
                    <div 
                        className="bg-white rounded-lg shadow-xl max-w-4xl w-full"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="flex justify-between items-center p-4 border-b">
                            <h3 className="text-lg font-medium">Photo Preview</h3>
                            <button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleDelete(selectedPhoto?.url);
                                }}
                                className="bg-red-500 hover:bg-red-600 text-white p-2 rounded-full"
                                title="Delete photo"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                </svg>
                            </button>
                        </div>
                        <div className="p-0">
                            {selectedPhoto && (
                                <img
                                    src={selectedPhoto.url}
                                    alt="Preview"
                                    className="w-full max-h-[80vh] object-contain"
                                />
                            )}
                        </div>
                        <div className="p-4 border-t flex justify-end">
                            <button
                                onClick={() => setPreviewOpen(false)}
                                className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>

                {/* Loading Indicator */}
                {isUploading && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                        <div className="bg-white p-6 rounded-lg">
                            <div className="flex items-center">
                                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
                                <span className="ml-4">Uploading photo(s)...</span>
                            </div>
                        </div>
                    </div>
                )}

                {/* Error Dialog */}
                {uploadError && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                        <div className="bg-white p-6 rounded-lg max-w-md">
                            <h3 className="text-lg font-bold mb-4">Upload Error</h3>
                            <p className="mb-4">{uploadError}</p>
                            <div className="flex justify-end">
                                <button
                                    onClick={() => setUploadError(null)}
                                    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {/* Success Dialog */}
                {uploadSuccess && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                        <div className="bg-white p-6 rounded-lg max-w-md">
                            <h3 className="text-lg font-bold mb-4">Success!</h3>
                            <p className="mb-4">Photo(s) uploaded successfully.</p>
                            <div className="flex justify-end">
                                <button
                                    onClick={() => setUploadSuccess(false)}
                                    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}