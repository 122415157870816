export const navItems = [
    { 
        title: 'Engines', 
        path: '/engines', 
        icon: '🔧',
        children: [
            { title: 'New Engine', path: '/engines/new' },
            { title: 'All Engines', path: '/engines' },
        ]
    },
    { 
        title: 'Requests', 
        path: '/requests', 
        icon: '📝',
        children: [
            { title: 'New Request', path: '/requests/new' },
            { title: 'All Requests', path: '/requests' },
        ]
    },
    { 
        title: 'Invoices', 
        path: '/invoices', 
        icon: '💰',
        children: [
            { title: 'New Invoice', path: '/invoices/new' },
            { title: 'All Invoices', path: '/invoices' },
        ]
    },
    { 
        title: 'Customers', 
        path: '/customers', 
        icon: '👥',
        children: [
            { title: 'New Customer', path: '/customers/new' },
            { title: 'All Customers', path: '/customers' },
        ]
    },
    { 
        title: 'Other',
        path: '/other',
        icon: '⚙️',
        children: [
            { title: 'Settings', path: '/settings' },
            { title: 'Profile', path: '/profile' },
            { title: 'Help', path: '/help' },
        ]
    }
]; 