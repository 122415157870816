import React, { useEffect, useState } from 'react'

import { Button, Checkbox, Typography, Input, List, ListItem, Card, Carousel } from "@material-tailwind/react";
import { getFirestore, collection, addDoc, query, where, getDocs } from "firebase/firestore";
import { getDatabase, ref, get } from "firebase/database";

// import Header from './Header'
// import { Footer } from './Footer'
// import NavigationLinks from './NavigationLinks'
// import LoadingIndicator from './LoadingIndicator';
import General from '../layouts/General';
import EngineContent from './EngineContent';
import LoadingIndicator from './LoadingIndicator';

import { db, auth } from '../FirebaseConfig'
import { FIRESTORE_PATHS, getCollectionPath } from '../config/firestore';

const database = getDatabase();

const CACHE_EXPIRY = 5 * 60 * 1000; // 5 minutes in milliseconds

const getMyEngines = async (userId) => {
    const querySnapshot = await getDocs(
        query(
            collection(db, getCollectionPath(FIRESTORE_PATHS.ENGINES(userId))),
            where("archived", "!=", true)
        )
    );

    const engines = [];
    const idToken = await auth.currentUser.getIdToken();
    
    for (const doc of querySnapshot.docs) {
        const data = doc.data();
        let timestamp = data.createdAt?.toDate?.() || new Date(data.createdAt);
        
        const timeAgo = timestamp && !isNaN(timestamp.getTime()) 
            ? getTimeAgo(timestamp) 
            : 'Unknown';

        // Check cache for stats
        const cacheKey = `engine_stats_${userId}_${doc.id}`;
        const cachedStats = getCachedStats(cacheKey);
        
        let stats;
        if (cachedStats) {
            stats = cachedStats;
            console.log('Using cached stats for engine:', doc.id);
        } else {
            stats = await fetchEngineStats(userId, doc.id, idToken);
            // Cache the results
            localStorage.setItem(cacheKey, JSON.stringify({
                stats,
                timestamp: Date.now()
            }));
        }
        
        engines.push({ 
            id: doc.id,
            ...data,
            ...stats,
            timeAgo,
            sortKey: `${data.name}_${doc.id}`
        });
    }

    return engines.sort((a, b) => (a.sortKey > b.sortKey) ? 1 : -1);
};

// Helper function to get cached stats
const getCachedStats = (cacheKey) => {
    const cached = localStorage.getItem(cacheKey);
    if (!cached) return null;

    const { stats, timestamp } = JSON.parse(cached);
    if (Date.now() - timestamp > CACHE_EXPIRY) {
        localStorage.removeItem(cacheKey);
        return null;
    }
    return stats;
};

// Helper function to fetch all stats for an engine
const fetchEngineStats = async (userId, engineId, idToken) => {
    let messageCount = 0;
    let calendarEventsCount = 0;
    let positionsCount = 0;

    try {
        // Fetch message count
        const dbUrl = database.app.options.databaseURL;
        const url = `${dbUrl}/recommengine/users/${userId}/engines/${engineId}/messages.json?auth=${idToken}`;
        const response = await fetch(url);
        const messages = await response.json();
        messageCount = messages ? Object.keys(messages).length : 0;

        // Fetch calendar events count
        const calendarEventsRef = collection(db, `recommengine/users/${userId}/data/engines/${engineId}/calendarEvents`);
        const calendarEventsSnapshot = await getDocs(calendarEventsRef);
        calendarEventsCount = calendarEventsSnapshot.size;

        // Fetch positions count
        const positionsRef = collection(db, `recommengine/users/${userId}/data/engines/${engineId}/positions`);
        const positionsSnapshot = await getDocs(positionsRef);
        positionsCount = positionsSnapshot.size;
    } catch (error) {
        console.error(`Error fetching stats for engine ${engineId}:`, error);
    }

    return {
        messageCount,
        calendarEventsCount,
        positionsCount
    };
};

const getTimeAgo = (date) => {
    const seconds = Math.floor((new Date() - date) / 1000);
    
    let interval = seconds / 31536000; // years
    if (interval > 1) return Math.floor(interval) + ' years ago';
    
    interval = seconds / 2592000; // months
    if (interval > 1) return Math.floor(interval) + ' months ago';
    
    interval = seconds / 86400; // days
    if (interval > 1) return Math.floor(interval) + ' days ago';
    
    interval = seconds / 3600; // hours
    if (interval > 1) return Math.floor(interval) + ' hours ago';
    
    interval = seconds / 60; // minutes
    if (interval > 1) return Math.floor(interval) + ' minutes ago';
    
    return Math.floor(seconds) + ' seconds ago';
};

export default function Engines() {
    const [myEngines, setMyEngines] = useState([]);
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                setCurrentUser(user);
                getMyEngines(user.uid).then((engines) => {
                    setMyEngines(engines);
                    setLoading(false);
                })
            } else {
                setCurrentUser(null);
                setLoading(false);
            }
        });

        return () => unsubscribe();
    }, []);

    return (
        <General title={`Engines (${myEngines.length})`}>
            {loading ? (
                <LoadingIndicator />
            ) : (
                <EngineContent currentUser={currentUser} engines={myEngines} />
            )}
        </General>
    )
}