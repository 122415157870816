import React, { useEffect, useState } from 'react';

import { getFirestore, collection, addDoc, query, where, getDocs } from "firebase/firestore";

import LoadingIndicator from './LoadingIndicator';

import { db, auth } from '../FirebaseConfig'

import General from '../layouts/General';
import GoalContent from './GoalContent';

import { FIRESTORE_PATHS, getCollectionPath } from '../config/firestore';

function Goals() {
    const [loading, setLoading] = useState(true);
    const [currentUser, setCurrentUser] = useState(null);
    const [myGoals, setMyGoals] = useState([]);
    const [activeGoal, setActiveGoal] = useState(null);

    const getMyGoals = async (userId) => {
        const querySnapshot = await getDocs(
            collection(db, getCollectionPath(FIRESTORE_PATHS.GOALS(userId)))
        );
        
        return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    };

    useEffect(() => {
        auth.onAuthStateChanged(user => {
            if (user) {
                setCurrentUser(user);
                getMyGoals(user.uid).then((goals) => {
                    setMyGoals(goals);
                })
                setLoading(false);
            } else {
                setCurrentUser(null);
                setLoading(false);
            }
        })        
    }, [currentUser])

    if (loading) {
        return (
            <div className='flex flex-col w-full h-full'>
                <LoadingIndicator />
            </div>
        )
    }

    return (
        <General title="Goals">
            <GoalContent currentUser={currentUser} myGoals={myGoals} activeGoal={activeGoal} setActiveGoal={setActiveGoal} />
        </General>
    )
}

export default Goals;
