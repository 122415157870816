import React, { useState, useEffect } from 'react';
import { List, ListItem, ListItemPrefix } from "@material-tailwind/react";
import { Link } from 'react-router-dom';
import { navItems } from '../config/navigation';

export default function NavigationLinks({ 
    collapsed, 
    onNavigate, 
    openSubMenu, 
    setOpenSubMenu,
    fullyCollapsed,
    setFullyCollapsed
}) {
    const [isAnimating, setIsAnimating] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            setIsScrolled(scrollPosition > 100);
            
            // Auto-collapse navigation on mobile when scrolling down
            if (window.innerWidth < 768 && scrollPosition > 100) { // 768px is typical mobile breakpoint
                setFullyCollapsed(true);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [setFullyCollapsed]);

    const handleMenuClick = (index) => {
        setIsAnimating(true);
        setOpenSubMenu(openSubMenu === index ? null : index);
        setTimeout(() => setIsAnimating(false), 300);
    };

    return (
        <List id="navigation-menu" className={`relative min-w-[40px] transition-all duration-300 
            ${isScrolled ? 'md:min-w-[40px]' : ''}`}>
            <button
                onClick={() => setFullyCollapsed(!fullyCollapsed)}
                className={`absolute ${fullyCollapsed ? 'left-2' : '-right-3'} top-2 bg-blue-gray-50 
                    rounded-full p-1 z-50 hover:bg-blue-gray-100 shadow-sm`}
                aria-label={fullyCollapsed ? "Expand menu" : "Collapse menu fully"}
                style={{ transform: fullyCollapsed ? 'none' : 'translateX(100%)' }}
            >
                {fullyCollapsed ? '→' : '←'}
            </button>

            {!fullyCollapsed && (
                <div className="transition-all duration-300">
                    {navItems.map((item, index) => (
                        <React.Fragment key={index}>
                            {item.children ? (
                                // Parent item with children
                                <div className="relative group">
                                    <ListItem 
                                        className={`hover:bg-blue-gray-50 cursor-pointer group relative ${
                                            collapsed ? 'w-fit px-4' : ''
                                        } ${fullyCollapsed ? 'w-0 overflow-hidden' : ''}`}
                                        onClick={() => handleMenuClick(index)}
                                        aria-label={collapsed ? item.title : undefined}
                                    >
                                        <ListItemPrefix>{item.icon}</ListItemPrefix>
                                        {!collapsed ? (
                                            <>
                                                <span className="mr-4">{item.title}</span>
                                                <span className="ml-auto mr-2">
                                                    {openSubMenu === index ? '▼' : '▶'}
                                                </span>
                                            </>
                                        ) : null}
                                    </ListItem>
                                </div>
                            ) : (
                                // Regular item without children
                                <Link 
                                    to={item.path} 
                                    className="block"
                                    aria-label={collapsed ? item.title : undefined}
                                    onClick={onNavigate}
                                >
                                    <ListItem className={`hover:bg-blue-gray-50 ${
                                        collapsed ? 'w-fit px-4' : ''
                                    }`}>
                                        <ListItemPrefix>{item.icon}</ListItemPrefix>
                                        {!collapsed && item.title}
                                    </ListItem>
                                </Link>
                            )}

                            {/* Nested items */}
                            {item.children && openSubMenu === index && (
                                <div className={`
                                    transition-all duration-200 ease-in-out
                                    ${collapsed 
                                        ? 'absolute left-full ml-2 bg-white shadow-lg rounded-lg p-2 z-50 min-w-[200px] block opacity-100 translate-x-0'
                                        : 'ml-4 opacity-100 max-h-[500px]'
                                    }
                                    ${!openSubMenu && collapsed
                                        ? 'opacity-0 translate-x-[-10px]'
                                        : ''
                                    }
                                    ${!openSubMenu && !collapsed
                                        ? 'opacity-0 max-h-0 overflow-hidden'
                                        : ''
                                    }`} 
                                    style={collapsed ? { top: `${(index * 48) - 8}px` } : {}}>
                                    {/* Add header text when collapsed */}
                                    {collapsed && (
                                        <div className="px-3 py-2 font-semibold text-gray-700 border-b border-gray-200 mb-2">
                                            {item.title}
                                        </div>
                                    )}
                                    {item.children.map((child, childIndex) => (
                                        <Link 
                                            key={childIndex} 
                                            to={child.path}
                                            onClick={() => {
                                                // Only close submenu if on mobile
                                                if (window.innerWidth < 768) {
                                                    setOpenSubMenu(null);
                                                }
                                                onNavigate();
                                            }}
                                            className="block transform transition-all duration-200"
                                            style={{
                                                ...(isAnimating && {
                                                    opacity: 0,
                                                    animation: 'slideIn 0.2s ease-out forwards',
                                                    animationDelay: `${childIndex * 50}ms`
                                                })
                                            }}
                                        >
                                            <ListItem className="hover:bg-blue-gray-50 py-2">
                                                <ListItemPrefix>
                                                    {collapsed ? '' : '└─'}
                                                </ListItemPrefix>
                                                {child.title}
                                            </ListItem>
                                        </Link>
                                    ))}
                                </div>
                            )}
                        </React.Fragment>
                    ))}
                </div>
            )}
        </List>
    );
}