import React, { useState } from 'react';
import { Typography } from "@material-tailwind/react";
import { formatDistanceToNow } from 'date-fns';
import { collection, addDoc, query, orderBy } from 'firebase/firestore';
import { db } from '../../FirebaseConfig'; // Adjust the path based on your file structure
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

export default function CalendarSection({ calendarEvents, currentUser, id, refreshEvents }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [newEvent, setNewEvent] = useState({ 
        name: '', 
        date: '', 
        startTime: '', 
        endTime: '', 
        description: '',
        location: '',
        isAllDay: true
    });
    const [formErrors, setFormErrors] = useState({});
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [locationCoords, setLocationCoords] = useState(null);
    const [isLoadingLocation, setIsLoadingLocation] = useState(false);

    const formatTimestamp = (timestamp) => {
        if (!timestamp) return 'Date unknown';
        try {
            const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp);
            if (isNaN(date.getTime())) {
                return 'Invalid date';
            }
            return {
                formatted: date.toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit'
                }),
                timeAgo: formatDistanceToNow(date, { addSuffix: true })
            };
        } catch (error) {
            console.error('Error formatting timestamp:', error);
            return 'Invalid date';
        }
    };

    const formatTimeForDisplay = (hour, minute) => {
        const period = hour < 12 ? 'AM' : 'PM';
        const displayHour = hour % 12 || 12;
        return `${displayHour}:${minute.toString().padStart(2, '0')} ${period}`;
    };

    const handleCreateEvent = async () => {
        try {
            // Reset errors at the start
            setFormErrors({});
            
            // Validate required fields with specific error messages
            const errors = {};
            if (!newEvent.name) errors.name = "Event name is required";
            if (!newEvent.date) errors.date = "Date is required";
            if (!newEvent.isAllDay) {
                if (!newEvent.startTime) errors.startTime = "Start time is required";
                if (!newEvent.endTime) errors.endTime = "End time is required";

                // Check if end time is after start time
                if (newEvent.startTime && newEvent.endTime) {
                    const [startHour, startMinute] = newEvent.startTime.split(':');
                    const [endHour, endMinute] = newEvent.endTime.split(':');
                    if (parseInt(endHour) < parseInt(startHour) || 
                        (parseInt(endHour) === parseInt(startHour) && parseInt(endMinute) <= parseInt(startMinute))) {
                        errors.endTime = "End time must be after start time";
                    }
                }
            }

            if (Object.keys(errors).length > 0) {
                setFormErrors(errors);
                return;
            }

            // Create date object from input, ensuring it's in the local timezone
            const [year, month, day] = newEvent.date.split('-').map(Number);
            const eventDate = new Date(year, month - 1, day);
            
            // No timezone offset adjustment needed since we're creating the date
            // directly with local components

            // Parse start and end times if not all-day event
            let startHour = 0, startMinute = 0, endHour = 23, endMinute = 59;
            if (!newEvent.isAllDay) {
                if (newEvent.startTime) {
                    [startHour, startMinute] = newEvent.startTime.split(':').map(Number);
                }
                if (newEvent.endTime) {
                    [endHour, endMinute] = newEvent.endTime.split(':').map(Number);
                }
            }

            const event = {
                name: newEvent.name,
                description: newEvent.description || '',
                location: newEvent.location || '',
                year: eventDate.getFullYear(),
                month: eventDate.getMonth() + 1,
                day: eventDate.getDate(),
                isAllDay: newEvent.isAllDay,
                startHour: startHour,
                startMinute: startMinute,
                endHour: endHour,
                endMinute: endMinute,
                timestamp: new Date(),
                timestampUTC: new Date().toISOString(),
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                sortableDate: new Date(
                    eventDate.getFullYear(),
                    eventDate.getMonth(),
                    eventDate.getDate(),
                    startHour,
                    startMinute
                ).toISOString(),
                id: Date.now().toString()
            };

            // Store the event in Firebase
            const calendarEventsRef = collection(db, `recommengine/users/${currentUser.uid}/data/engines/${id}/calendarEvents`);
            await addDoc(calendarEventsRef, event);

            // Reset form and close modal
            setNewEvent({ 
                name: '', 
                date: '', 
                startTime: '', 
                endTime: '', 
                description: '',
                location: '',
                isAllDay: true
            });
            setIsModalOpen(false);

            // Refresh the events list
            if (refreshEvents) {
                refreshEvents();
            }
        } catch (error) {
            console.error("Error creating event:", error);
            setFormErrors({ submit: "Failed to create event. Please try again." });
        }
    };

    const handleLocationClick = async (location) => {
        try {
            const response = await fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(location)}`);
            const data = await response.json();
            if (data && data[0]) {
                setLocationCoords([parseFloat(data[0].lat), parseFloat(data[0].lon)]);
                setSelectedLocation(location);
            }
        } catch (error) {
            console.error('Error geocoding location:', error);
        }
    };

    const handleGetMyLocation = () => {
        setIsLoadingLocation(true);
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
                async (position) => {
                    try {
                        const { latitude, longitude } = position.coords;
                        const response = await fetch(
                            `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
                        );
                        const data = await response.json();
                        const address = data.display_name;
                        setNewEvent({ ...newEvent, location: address });
                    } catch (error) {
                        console.error("Error getting location:", error);
                    } finally {
                        setIsLoadingLocation(false);
                    }
                },
                (error) => {
                    console.error("Error getting location:", error);
                    setIsLoadingLocation(false);
                }
            );
        }
    };

    // Sort events before rendering
    const sortedEvents = [...(calendarEvents || [])].sort((a, b) => {
        const dateA = new Date(a.year, a.month - 1, a.day, a.startHour, a.startMinute);
        const dateB = new Date(b.year, b.month - 1, b.day, b.startHour, b.startMinute);
        return dateB - dateA;
    });

    return (
        <div className='flex flex-col w-full h-full'>
            <div className="sticky top-0 z-40 bg-white pb-4">
                <button
                    className="w-full bg-black hover:bg-gray-800 text-white font-bold py-2 px-3 rounded text-sm sm:text-base"
                    onClick={() => setIsModalOpen(true)}
                >
                    + Add Event
                </button>
            </div>

            <div className='flex-1 overflow-y-auto'>
                <div className='flex flex-col w-full gap-2'>
                    {!sortedEvents || sortedEvents.length === 0 ? (
                        <div className='flex flex-col w-full p-4 border-2'>
                            <Typography variant="h6" className='text-gray-500'>
                                No events scheduled yet. Click "Add Calendar Event" to get started!
                            </Typography>
                        </div>
                    ) : (
                        sortedEvents.map((event, index) => (
                            <div 
                                className='flex flex-col w-full p-3 sm:p-4 border-2 rounded-lg' 
                                key={`calendar-event-${event.id || index}`}
                            >
                                <div className='flex flex-col sm:flex-row sm:justify-between gap-4'>
                                    <div className='flex-1'>
                                        <div className='flex items-center gap-2 mb-2 flex-wrap'>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 sm:h-5 sm:w-5 text-gray-600" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd" />
                                            </svg>
                                            <Typography variant="h6" className="font-medium text-base sm:text-lg break-words">
                                                {event.name}
                                            </Typography>
                                        </div>
                                        
                                        <div className='text-sm text-gray-600 mb-2'>
                                            {`${event.startHour % 12 || 12}:${event.startMinute.toString().padStart(2, '0')} ${event.startHour < 12 ? 'am' : 'pm'} - ${event.endHour % 12 || 12}:${event.endMinute.toString().padStart(2, '0')} ${event.endHour < 12 ? 'am' : 'pm'}`}
                                        </div>

                                        <div className='text-sm text-gray-600 mb-2'>
                                            {new Date(event.year, event.month - 1, event.day).toLocaleString('default', { month: 'short' })} {event.day}, {event.year}
                                            <span className='ml-2'>
                                                ({formatDistanceToNow(new Date(event.year, event.month - 1, event.day), { addSuffix: true })})
                                            </span>
                                        </div>

                                        {event.location && (
                                            <div className='text-sm text-gray-600 mb-2 flex items-center cursor-pointer'
                                                 onClick={() => handleLocationClick(event.location)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
                                                </svg>
                                                <span className='font-semibold'>Location:</span> 
                                                <span className='ml-1 text-blue-600 hover:underline'>{event.location} (click to view map)</span>
                                            </div>
                                        )}

                                        {event.description && (
                                            <div className='mt-2'>
                                                <Typography variant="small" className="text-gray-700 font-medium">Description:</Typography>
                                                <Typography variant="small" className="text-gray-600">
                                                    {event.description}
                                                </Typography>
                                            </div>
                                        )}

                                        {event.timestamp && (
                                            <div className='text-sm text-gray-500 mt-2'>
                                                {(() => {
                                                    const timeInfo = formatTimestamp(event.timestamp);
                                                    return typeof timeInfo === 'string' ? (
                                                        timeInfo
                                                    ) : (
                                                        <>
                                                            Created: {timeInfo.formatted}
                                                            <span className='ml-2'>({timeInfo.timeAgo})</span>
                                                        </>
                                                    );
                                                })()}
                                            </div>
                                        )}
                                    </div>

                                    {event.photoUrl && (
                                        <div className='flex-shrink-0'>
                                            <img 
                                                src={event.photoUrl} 
                                                alt={event.name}
                                                className='w-[300px] h-[200px] rounded-lg object-cover cursor-pointer'
                                                onClick={() => setSelectedImage(event.photoUrl)}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))
                    )}
                </div>
            </div>

            {isModalOpen && (
                <div className="fixed inset-0 flex justify-center items-center z-[9999] p-4">
                    <div className="absolute inset-0 bg-black opacity-50"></div>
                    <div className="bg-white p-4 sm:p-6 rounded-lg relative z-[10000] w-full max-h-[90vh] overflow-y-auto max-w-[95vw] sm:max-w-md">
                        <h2 className="text-xl font-bold mb-4">Schedule New Event</h2>
                        
                        {formErrors.submit && (
                            <div className="mb-4 text-red-500 text-sm">
                                Unable to create event. Please try again or contact support if the issue persists.
                            </div>
                        )}

                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2">
                                Event Name
                            </label>
                            <input
                                type="text"
                                placeholder="Enter event name"
                                className={`border p-2 w-full rounded ${formErrors.name ? 'border-red-500' : ''}`}
                                value={newEvent.name}
                                onChange={(e) => setNewEvent({...newEvent, name: e.target.value})}
                            />
                            {formErrors.name && (
                                <p className="text-red-500 text-xs mt-1">{formErrors.name}</p>
                            )}
                        </div>

                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2">
                                Date
                            </label>
                            <input
                                type="date"
                                className={`border p-2 w-full rounded ${formErrors.date ? 'border-red-500' : ''}`}
                                value={newEvent.date}
                                onChange={(e) => setNewEvent({...newEvent, date: e.target.value})}
                            />
                            {formErrors.date && (
                                <p className="text-red-500 text-xs mt-1">{formErrors.date}</p>
                            )}
                        </div>

                        <div className="mb-4">
                            <label className="flex items-center">
                                <input
                                    type="checkbox"
                                    checked={newEvent.isAllDay}
                                    onChange={(e) => setNewEvent({...newEvent, isAllDay: e.target.checked})}
                                    className="mr-2"
                                />
                                <span className="text-gray-700 text-sm font-bold">All Day</span>
                            </label>
                        </div>

                        {!newEvent.isAllDay && (
                            <div className="flex flex-col sm:flex-row gap-4 mb-4">
                                <div className="w-full sm:flex-1">
                                    <label className="block text-gray-700 text-sm font-bold mb-2">
                                        Start Time
                                    </label>
                                    <select
                                        className={`border p-2 w-full rounded ${formErrors.startTime ? 'border-red-500' : ''}`}
                                        value={newEvent.startTime}
                                        onChange={(e) => setNewEvent({...newEvent, startTime: e.target.value})}
                                    >
                                        <option value="">Select time</option>
                                        {Array.from({ length: 48 }, (_, i) => {
                                            const hour = Math.floor(i / 2);
                                            const minute = (i % 2) * 30;
                                            const timeValue = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
                                            return (
                                                <option key={timeValue} value={timeValue}>
                                                    {formatTimeForDisplay(hour, minute)}
                                                </option>
                                            );
                                        })}
                                    </select>
                                    {formErrors.startTime && (
                                        <p className="text-red-500 text-xs mt-1">{formErrors.startTime}</p>
                                    )}
                                </div>

                                <div className="w-full sm:flex-1">
                                    <label className="block text-gray-700 text-sm font-bold mb-2">
                                        End Time
                                    </label>
                                    <select
                                        className={`border p-2 w-full rounded ${formErrors.endTime ? 'border-red-500' : ''}`}
                                        value={newEvent.endTime}
                                        onChange={(e) => setNewEvent({...newEvent, endTime: e.target.value})}
                                    >
                                        <option value="">Select time</option>
                                        {Array.from({ length: 48 }, (_, i) => {
                                            const hour = Math.floor(i / 2);
                                            const minute = (i % 2) * 30;
                                            const timeValue = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
                                            return (
                                                <option key={timeValue} value={timeValue}>
                                                    {formatTimeForDisplay(hour, minute)}
                                                </option>
                                            );
                                        })}
                                    </select>
                                    {formErrors.endTime && (
                                        <p className="text-red-500 text-xs mt-1">{formErrors.endTime}</p>
                                    )}
                                </div>
                            </div>
                        )}

                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2">
                                Location
                            </label>
                            <div className="flex gap-2">
                                <input
                                    type="text"
                                    placeholder="Enter location"
                                    className="border p-2 w-full rounded"
                                    value={newEvent.location}
                                    onChange={(e) => setNewEvent({...newEvent, location: e.target.value})}
                                />
                                <button
                                    className="bg-gray-200 hover:bg-gray-300 px-3 py-2 rounded flex items-center gap-1"
                                    onClick={() => handleLocationClick(newEvent.location)}
                                    disabled={!newEvent.location}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                                    </svg>
                                    <span className="inline">Search</span>
                                </button>
                                <button
                                    className="bg-gray-200 hover:bg-gray-300 px-3 py-2 rounded flex items-center gap-1"
                                    onClick={handleGetMyLocation}
                                    disabled={isLoadingLocation}
                                >
                                    {isLoadingLocation ? (
                                        <span className="animate-spin">🔄</span>
                                    ) : (
                                        <>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
                                            </svg>
                                            <span className="inline">Get Location</span>
                                        </>
                                    )}
                                </button>
                            </div>
                        </div>

                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2">
                                Description
                            </label>
                            <textarea
                                placeholder="Enter description"
                                className="border p-2 w-full rounded h-24"
                                value={newEvent.description}
                                onChange={(e) => setNewEvent({...newEvent, description: e.target.value})}
                            />
                        </div>

                        <div className="flex justify-end">
                            <button
                                className="bg-black hover:bg-gray-800 text-white font-bold py-2 px-4 rounded mr-2"
                                onClick={handleCreateEvent}
                            >
                                Create
                            </button>
                            <button
                                className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded"
                                onClick={() => setIsModalOpen(false)}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {selectedImage && (
                <div className="fixed inset-0 flex justify-center items-center z-[100]" onClick={() => setSelectedImage(null)}>
                    <div className="absolute inset-0 bg-black opacity-50"></div>
                    <div className="relative z-[101] max-w-[90vw] max-h-[90vh]">
                        <img 
                            src={selectedImage} 
                            alt="Full size"
                            className="max-w-full max-h-[90vh] object-contain"
                        />
                    </div>
                </div>
            )}

            {selectedLocation && locationCoords && (
                <div className="fixed inset-0 flex justify-center items-center z-[9999]">
                    <div className="absolute inset-0 bg-black opacity-50" onClick={() => setSelectedLocation(null)}></div>
                    <div className="bg-white p-6 rounded-lg relative z-[10000] max-w-2xl w-full">
                        <h2 className="text-xl font-bold mb-4">{selectedLocation}</h2>
                        <div className="h-[400px] w-full">
                            <MapContainer 
                                center={locationCoords} 
                                zoom={13} 
                                style={{ height: '100%', width: '100%' }}
                            >
                                <TileLayer
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                />
                                <Marker position={locationCoords}>
                                    <Popup>{selectedLocation}</Popup>
                                </Marker>
                            </MapContainer>
                        </div>
                        <div className="flex justify-end mt-4">
                            <button
                                className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded"
                                onClick={() => setSelectedLocation(null)}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
