import React, { useState, useEffect } from 'react'
import {
    Button,
    Checkbox,
    Typography,
    Input,
    List,
    ListItem,
    ListItemPrefix,
    Card,
    Carousel,
    CardBody,
} from "@material-tailwind/react";

import Quiz from './quiz/Quiz';
import QuizEditor from './quiz/QuizEditor';

export default function QuizContent({ currentUser, myQuizzes, setActiveQuiz, activeQuiz, isEditing, setIsEditing }) {

    return (
        <div className='flex flex-row md:flex-col mt-2'>
            { activeQuiz ? (
                <div className='flex flex-col w-full h-full p-10'>
                    <Button color="black" onClick={() => setActiveQuiz(null)}>
                        Back
                    </Button>

                    {isEditing && (
                        <QuizEditor standalone quizId={activeQuiz.id} key={activeQuiz.title} title={activeQuiz.title} questions={activeQuiz.questions} />
                        )}

                    {!isEditing && (
                        <Quiz quizId={activeQuiz.id} key={activeQuiz.title} title={activeQuiz.title} questions={activeQuiz.questions} />
                    )}
                    
                </div>
            ) : (
                <div className='flex flex-col w-full h-full p-10'>
                    <Typography variant="h3">
                        {myQuizzes.length} Quizzes
                    </Typography>

                    { !isEditing && (
                        <Button className="mt-6" fullWidth color="black" onClick={() => setIsEditing(true)}>
                            Edit Quizzes
                        </Button>
                    )}

                    { isEditing && (
                        <Button className="mt-6" fullWidth color="black" onClick={() => setIsEditing(false)}>
                            View Quizzes
                        </Button>
                    )}  

                    <List>
                        {myQuizzes.map((quiz) => (
                            <ListItem
                                key={quiz.title}
                                onClick={() => setActiveQuiz(quiz)}
                            >
                                <ListItemPrefix>
                                    <Checkbox
                                        // variant="gradient"
                                        // color="red"
                                    />
                                </ListItemPrefix>
                                <Typography>{quiz.title}</Typography>
                            </ListItem>
                        ))}
                    </List>
                </div>
            )}
        </div>
    );
}