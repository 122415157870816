import {
    Button,
    Checkbox,
    Typography,
    Input,
    List,
    ListItem,
    Card,
    Carousel
} from "@material-tailwind/react";

import Header from './Header'
import { Footer } from './Footer'

function License() {
    return (
        <div className='flex flex-col p-2 md:p-2 w-full h-full'>
            <Header />
            <div className='flex flex-row md:flex-col w-full h-full text-left p-10'>
                <div>
                    <Typography variant='h4' className='ml-4'>Recommengine Mobile App License Agreement</Typography>

                    <div className='flex flex-col w-full h-full p-10'>
                        <div class="text-black">
                            Effective Date: July 11, 2024
                            <br/><br/>
                            PLEASE READ THIS LICENSE AGREEMENT (“AGREEMENT”) CAREFULLY BEFORE USING THE RECOMMENGINE MOBILE APP (“APP”). BY USING THE APP, YOU AGREE TO BE BOUND BY THE TERMS AND CONDITIONS OF THIS AGREEMENT. IF YOU DO NOT AGREE TO THE TERMS OF THIS AGREEMENT, DO NOT USE THE APP.
                            <br/><br/>
                            This Agreement is between you (“User”) and BCP Solutions, LLC (“Company”) and governs your use of the App.
                            <br/><br/>
                            <span class="font-bold">1. License Grant</span>
                            <br/><br/>
                            The Company grants the User a non-exclusive, non-transferable, revocable license to use the App for personal, non-commercial purposes, subject to the terms and conditions of this Agreement.
                            <br/><br/>
                            <span class="font-bold">2. In-App Purchases</span>
                            <br/><br/>
                            While the App is offered for free, the User may make in-app purchases to increase usage limits and access additional features. All in-app purchases are subject to the terms and conditions of this Agreement and the respective app store’s terms of service.
                            <br/><br/>
                            <span class="font-bold">3. Prohibited Uses</span>
                            <br/><br/>
                            The User agrees not to use the App to:
                            <br/><br/>
                                •	Upload, post, or otherwise transmit any content that is illegal, unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, or otherwise objectionable.<br/>
                                •	Violate any local, state, national, or international law.<br/>
                                •	Impersonate any person or entity or misrepresent your affiliation with a person or entity.<br/>
                                •	Transmit any content that infringes upon any patent, trademark, trade secret, copyright, or other proprietary rights of any party.<br/>
                                •	Transmit any material that contains software viruses or any other computer code, files, or programs designed to interrupt, destroy, or limit the functionality of any computer software or hardware or telecommunications equipment.<br/>
                                <br/><br/>
                                <span class="font-bold">4. Termination</span>
                            <br/><br/>
                            This Agreement is effective until terminated by either party. The User may terminate this Agreement at any time by uninstalling the App and destroying all copies in their possession. The Company may terminate this Agreement at any time without notice if the User fails to comply with any terms of this Agreement. Upon termination, the User must cease all use of the App and destroy all copies in their possession.
                            <br/><br/>
                            <span class="font-bold">5. Disclaimer of Warranties</span>
                            <br/><br/>
                            The App is provided “as is” and “as available” without warranty of any kind, either express or implied, including but not limited to the implied warranties of merchantability, fitness for a particular purpose, or non-infringement. The Company does not warrant that the App will meet the User’s requirements, be uninterrupted, timely, secure, or error-free.
                            <br/><br/>
                            <span class="font-bold">6. Limitation of Liability</span>
                            <br/><br/>
                            In no event shall the Company be liable for any direct, indirect, incidental, special, consequential, or exemplary damages, including but not limited to damages for loss of profits, goodwill, use, data, or other intangible losses (even if the Company has been advised of the possibility of such damages), resulting from the use or inability to use the App.
                            <br/><br/>
                            <span class="font-bold">7. Indemnification</span>
                            <br/><br/>
                            The User agrees to indemnify and hold harmless the Company, its affiliates, officers, directors, employees, agents, and licensors from any claim or demand, including reasonable attorneys’ fees, made by any third party due to or arising out of the User’s use of the App, violation of this Agreement, or violation of any rights of another.
                            <br/><br/>
                            <span class="font-bold">8. Governing Law</span>
                            <br/><br/>
                            This Agreement shall be governed by and construed in accordance with the laws of the state of [Your State], without regard to its conflict of law principles.
                            <br/><br/>
                            <span class="font-bold">9. Entire Agreement</span>
                            <br/><br/>
                            This Agreement constitutes the entire agreement between the User and the Company regarding the use of the App and supersedes all prior agreements and understandings, whether written or oral, regarding the subject matter hereof.
                            <br/><br/>
                            <span class="font-bold">10. Contact Information</span>
                            <br/><br/>
                            For any questions about this Agreement, please contact:
                            <br/><br/>
                            BCP Solutions, LLC
                            <br/>
                            <a href="mailto:brian@bcpsolutionsllc.com">brian@bcpsolutionsllc.com</a>
                            <br/>
                            <a href="https://www.bcpsolutionsllc.com/" target="_blank">www.bcpsolutionsllc.com</a>
                            <br/><br/>

                            By using the App, you acknowledge that you have read and understood this Agreement and agree to be bound by its terms and conditions.
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>

    );

}


export default License