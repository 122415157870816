import React, { useState } from "react";
import { Typography } from "@material-tailwind/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";

export function EngineOverview() {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <section className="bg-white py-12 border-b border-military-green-200">
      <div className="max-w-4xl mx-auto px-4">
        <div className="p-6 bg-gradient-to-r from-military-green-50/30 to-slate-blue-50/30 backdrop-blur-sm">
          <div className="font-mono text-xs text-military-green-600 mb-2">// RECOMMENGINE OVERVIEW</div>
          <div 
            onClick={() => setIsExpanded(!isExpanded)}
            className="cursor-pointer hover:opacity-80 flex items-center justify-between group"
          >
            <Typography variant="h3" className="text-military-green-700 font-bold mb-4 text-xl md:text-2xl">
              <svg className="w-6 h-6 inline-block mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7l5 5-5 5M5 7l5 5-5 5" />
              </svg>
              What is an Engine?
            </Typography>
            <ChevronDownIcon 
              className={`w-6 h-6 text-military-green-600 transition-transform duration-300 group-hover:text-military-green-800 -mt-4
                ${isExpanded ? 'rotate-180' : 'rotate-0'}`}
            />
          </div>
          
          <div className={`transition-all duration-300 ease-in-out overflow-hidden
            ${isExpanded ? 'max-h-[500px] opacity-100' : 'max-h-0 opacity-0'}`}
          >
            <Typography className="text-military-green-600">
              An engine is a customizable, standalone tool you build for specific tasks or workflows. Think of it as your personal business command center, tailored to your exact needs. Each engine combines powerful features like scheduling, client management, payment processing, and field operations into a seamless solution. Whether you're managing a lawn care business, planning events, or running a service company, your engine adapts to how you work - not the other way around.
            </Typography>
            <div className="mt-4 grid grid-cols-1 md:grid-cols-3 gap-4">
              <div className="p-3 bg-white/50 rounded">
                <div className="font-mono text-xs text-slate-blue-500 mb-1">01_customize</div>
                <p className="text-military-green-600 text-sm">Select and configure features that match your workflow</p>
              </div>
              <div className="p-3 bg-white/50 rounded">
                <div className="font-mono text-xs text-slate-blue-500 mb-1">02_automate</div>
                <p className="text-military-green-600 text-sm">Streamline repetitive tasks and business processes</p>
              </div>
              <div className="p-3 bg-white/50 rounded">
                <div className="font-mono text-xs text-slate-blue-500 mb-1">03_scale</div>
                <p className="text-military-green-600 text-sm">Grow your business with tools that evolve with you</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
} 