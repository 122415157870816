import React, { useState } from 'react';
import { Typography } from "@material-tailwind/react";
import { useAuth } from '../../contexts/AuthContext';
import { firebaseConfig } from '../../FirebaseConfig';

export default function ChatSectionContent({ chatMessages, engineId, onMessageSent }) {
    const { currentUser } = useAuth();
    const [newMessage, setNewMessage] = useState({ 
        text: '', 
        sender: '', 
        timestamp: ''
    });
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const sendMessage = async () => {
        if (!newMessage.text.trim()) return;
        setIsLoading(true);
        setError(null);

        try {
            const idToken = await currentUser.getIdToken();
            const url = `${firebaseConfig.databaseURL}/recommengine/users/${currentUser.uid}/engines/${engineId}/messages.json?auth=${idToken}`;
            
            const messageData = {
                text: newMessage.text,
                sender: currentUser.email,
                createdAt: new Date().toISOString()
            };

            const response = await fetch(url, {
                method: 'POST',
                body: JSON.stringify(messageData)
            });

            if (!response.ok) {
                throw new Error('Failed to send message');
            }

            setNewMessage({ text: '', sender: '', timestamp: '' });
            if (onMessageSent) await onMessageSent();
        } catch (error) {
            console.error('Error sending message:', error);
            setError('Failed to send message. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className='flex flex-col w-full h-full'>
            {error && (
                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4">
                    {error}
                </div>
            )}
            <div className='flex-1 overflow-y-auto p-4'>
                {chatMessages && chatMessages.length === 0 && (
                    <div className='flex flex-col w-full p-2 sm:p-4 border-2 mb-2'>
                        <Typography variant="h6" className='text-gray-500 text-sm sm:text-base'>
                            Start a conversation by sending a message below. Your chat history will appear here.
                        </Typography>
                    </div>
                )}

                {chatMessages && chatMessages.map((message, index) => (
                    <div 
                        className={`flex flex-col mb-2 sm:mb-4 ${
                            message.sender === currentUser?.email ? 'items-end' : 'items-start'
                        }`} 
                        key={`chat-message-${message.id || index}`}
                    >
                        <div className='text-xs sm:text-sm text-gray-500 mb-1 px-2'>
                            <span className='block truncate max-w-[200px] sm:max-w-none'>
                                {message.sender}
                            </span>
                        </div>
                        <div className={`max-w-[85%] sm:max-w-[70%] break-words rounded-lg px-3 py-2 sm:px-4 sm:py-2 ${
                            message.sender === currentUser?.email
                                ? 'bg-blue-500 text-white rounded-tr-none'
                                : 'bg-gray-200 text-black rounded-tl-none'
                        }`}>
                            <div className='flex flex-col text-sm sm:text-base'>
                                {message.text}
                            </div>
                            <div className='text-xs mt-1 opacity-75'>
                                {(() => {
                                    try {
                                        const messageDate = new Date(message.createdAt);
                                        const now = new Date();
                                        const diffInSeconds = Math.floor((now - messageDate) / 1000);
                                        
                                        if (diffInSeconds < 60) return 'just now';
                                        if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)} minutes ago`;
                                        if (diffInSeconds < 86400) return `${Math.floor(diffInSeconds / 3600)} hours ago`;
                                        if (diffInSeconds < 604800) return `${Math.floor(diffInSeconds / 86400)} days ago`;
                                        return messageDate.toLocaleDateString();
                                    } catch (e) {
                                        return message.createdAt;
                                    }
                                })()}
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <div className="sticky bottom-0 left-0 right-0 bg-white border-t-2 p-4">
                <div className="flex gap-2 max-w-screen-lg mx-auto">
                    <textarea
                        placeholder="Type your message..."
                        className="flex-1 border rounded-lg p-1.5 sm:p-2 text-xs sm:text-base resize-none min-h-[36px] sm:min-h-[42px]"
                        rows="1"
                        value={newMessage.text}
                        onChange={(e) => setNewMessage({...newMessage, text: e.target.value})}
                    />
                    <button
                        className={`bg-black hover:bg-gray-800 text-white font-bold py-1.5 px-2.5 sm:py-2 sm:px-4 rounded text-xs sm:text-base whitespace-nowrap ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                        onClick={sendMessage}
                        disabled={isLoading}
                    >
                        {isLoading ? 'Sending...' : 'Send'}
                    </button>
                </div>
            </div>
        </div>
    );
}
