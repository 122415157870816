import React, { useEffect, useState } from 'react'

import { Button, Checkbox, Typography, Input, List, ListItem, Card, Carousel } from "@material-tailwind/react";
import { getFirestore, collection, addDoc, query, where, getDocs } from "firebase/firestore";

import General from '../layouts/General';
import AgentContent from './AgentContent';

import { db, auth } from '../FirebaseConfig'
import RegularButton from './buttons/RegularButton';

const getMyAgents = async (userId) => {
    const querySnapshot = await getDocs(
        query(collection(db, "recommengine/users/"+ userId +"/data/agents"))
    );

    const agents = [];
    querySnapshot.forEach((doc) => {
        agents.push({ id: doc.id, ...doc.data() });
    });

    // console.log(agents);
    return agents;
}

export default function Agents() {
    const [myAgents, setMyAgents] = useState([]);
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        auth.onAuthStateChanged(user => {
            if (user) {
                setCurrentUser(user);

                if (loading) {
                    getMyAgents(user.uid).then((agents) => {
                        console.log(agents);
                        setMyAgents(agents);
                    })

                    setLoading(false);
                }
            } else {
                setCurrentUser(null);
                setLoading(false);
            }
        })        
    }, [currentUser])

    return (
        <General title="Agents">
            <RegularButton url={'/dashboard'} text="Back" />
            <AgentContent currentUser={currentUser} myAgents={myAgents} />
        </General>
    )
}