import React, { useState, useEffect } from 'react';
import { Typography, Button } from "@material-tailwind/react";
import { useParams, useNavigate, Link } from 'react-router-dom';
import { doc, getDoc } from "firebase/firestore";
import { db, auth } from '../../FirebaseConfig';
import LoadingIndicator from '../LoadingIndicator';
import General from '../../layouts/General';

function Invoice() {
    const { id } = useParams();
    const [invoice, setInvoice] = useState(null);
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((currentUser) => {
            setUser(currentUser);
            setLoading(false);
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const fetchInvoice = async () => {
            if (!user) return;

            try {
                const invoiceRef = doc(db, `recommengine/users/${user.uid}/data/invoices`, id);
                const invoiceSnap = await getDoc(invoiceRef);

                if (invoiceSnap.exists()) {
                    setInvoice({ id: invoiceSnap.id, ...invoiceSnap.data() });
                } else {
                    console.log('No such invoice!');
                }
            } catch (error) {
                console.error("Error fetching invoice: ", error);
            }
        };

        if (user) {
            fetchInvoice();
        }
    }, [id, user]);

    const getStatusColor = (status) => {
        switch (status?.toLowerCase()) {
            case 'paid':
                return 'bg-green-100 text-green-800';
            case 'pending':
                return 'bg-yellow-100 text-yellow-800';
            case 'overdue':
                return 'bg-red-100 text-red-800';
            default:
                return 'bg-blue-gray-100 text-blue-gray-800';
        }
    };

    const formatCurrency = (amount) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
        }).format(amount);
    };

    const renderRequiredField = (label, value, formatter = null) => {
        const displayValue = formatter ? formatter(value) : value;
        return (
            <div>
                <Typography variant='h6' color='blue-gray' className="text-sm font-medium text-gray-500">
                    {label}
                </Typography>
                {value ? (
                    <Typography className="text-base sm:text-lg">
                        {displayValue}
                    </Typography>
                ) : (
                    <Typography className="text-red-500 text-sm">
                        {label} is required
                    </Typography>
                )}
            </div>
        );
    };

    if (loading) {
        return (
            <General title="Invoice Details">
                <LoadingIndicator />
            </General>
        );
    }

    if (!user) {
        return (
            <General title="Invoice Details">
                <div className='flex flex-col w-full h-full items-center justify-center'>
                    <Typography variant='h4'>Please log in to view this</Typography>
                </div>
            </General>
        );
    }

    if (!invoice) {
        return (
            <General title="Invoice Details">
                <div className='flex flex-col w-full h-full items-center justify-center'>
                    <LoadingIndicator />
                </div>
            </General>
        );
    }

    return (
        <General title="Invoice Details">
            <div className='flex flex-col w-full p-3 sm:p-6'>
                <div className='flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6 gap-3'>
                    <Typography variant='h2' className="text-xl sm:text-2xl">
                        Invoice {invoice.invoiceNumber} 
                    </Typography>
                    <div className={`px-4 py-2 rounded-full text-sm font-medium ${getStatusColor(invoice.status)}`}>
                        {invoice.status?.charAt(0).toUpperCase() + invoice.status?.slice(1)}
                    </div>
                </div>
                
                <div className='bg-white shadow-md rounded px-4 sm:px-8 pt-4 sm:pt-6 pb-6 sm:pb-8 mb-4'>
                    <div className='grid grid-cols-1 gap-6'>
                        <div className='space-y-4'>
                            {renderRequiredField('Customer Information', invoice.customerName)}
                            
                            {renderRequiredField('Amount Due', formatCurrency(invoice.amount))}

                            {renderRequiredField('Due Date', new Date(invoice.dueDate).toLocaleDateString(undefined, {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric'
                            }))}
                            
                            {renderRequiredField('Created Date', new Date(invoice.date).toLocaleDateString(undefined, {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                                hour: '2-digit',
                                minute: '2-digit'
                            }))}
                        </div>
                    </div>

                    <div className='mt-6'>
                        <Typography variant='h6' color='blue-gray' className="text-sm font-medium text-gray-500">
                            Description
                        </Typography>
                        <Typography className="mt-2 text-gray-700 whitespace-pre-wrap">
                            {invoice.description || 'No description provided'}
                        </Typography>
                    </div>
                </div>

                <div className='flex flex-col items-center gap-2 mt-6'>
                    <Button 
                        onClick={() => navigate(`/invoices/edit/${id}`)}
                        className="flex items-center gap-2"
                        variant="filled"
                    >
                        Edit
                    </Button>
                    <button
                        onClick={() => navigate('/invoices')}
                        className="text-gray-600 underline cursor-pointer p-1"
                    >
                        Back to Invoices
                    </button>
                </div>
            </div>
        </General>
    );
}

export default Invoice;
