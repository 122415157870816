import React from 'react';
import General from '../layouts/General';

function Help() {
  return (
    <General title="Help">
      <div className="bg-white rounded-lg shadow p-3 sm:p-6">
        <h1 className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6">Help & FAQ</h1>
        
        <div className="space-y-6 sm:space-y-8">
          <section>
            <h2 className="text-lg sm:text-xl font-semibold mb-3 sm:mb-4">Engine Creation</h2>
            <div className="space-y-3 sm:space-y-4">
              <div>
                <h3 className="font-medium text-sm sm:text-base">How do I create a new engine?</h3>
                <p className="text-gray-600 text-sm sm:text-base">Click the "Create New Engine" button and follow the 4-step process:</p>
                <div className="text-gray-600 text-sm sm:text-base space-y-2 mt-2">
                  <div>1. Name your engine</div>
                  <div>2. Define its purpose</div>
                  <div>3. Choose features or a template</div>
                  <div>4. Review and confirm</div>
                </div>
              </div>
              <div>
                <h3 className="font-medium text-sm sm:text-base">What features are available?</h3>
                <div className="text-gray-600 text-sm sm:text-base space-y-2">
                  <div>• Maps for location tracking</div>
                  <div>• Chat for messaging</div>
                  <div>• Camera for photo galleries</div>
                  <div>• Calendar for scheduling</div>
                </div>
              </div>
              <div>
                <h3 className="font-medium text-sm sm:text-base">Can I use pre-made templates?</h3>
                <p className="text-gray-600 text-sm sm:text-base">Yes! Choose from industry-specific templates like Activity Management, Event Management, HVAC Business, and more. Each template comes pre-configured with recommended features.</p>
              </div>
            </div>
          </section>

          <section>
            <h2 className="text-lg sm:text-xl font-semibold mb-3 sm:mb-4">Customer Management</h2>
            <div className="space-y-3 sm:space-y-4">
              <div>
                <h3 className="font-medium text-sm sm:text-base">How do I add new customers?</h3>
                <p className="text-gray-600 text-sm sm:text-base">Use the "Add New Customer" button to create customer profiles with contact information, location, and status.</p>
              </div>
              <div>
                <h3 className="font-medium text-sm sm:text-base">Can I edit or archive customers?</h3>
                <p className="text-gray-600 text-sm sm:text-base">Yes, each customer card has "View" and "Edit" options. You can also archive customers to maintain their records without keeping them active.</p>
              </div>
            </div>
          </section>

          <section>
            <h2 className="text-lg sm:text-xl font-semibold mb-3 sm:mb-4">Invoices & Billing</h2>
            <div className="space-y-3 sm:space-y-4">
              <div>
                <h3 className="font-medium text-sm sm:text-base">How do I create and track invoices?</h3>
                <p className="text-gray-600 text-sm sm:text-base">Click "Add New Invoice" to create invoices. Each invoice shows the customer name, amount, date, and payment status (Paid, Pending, or Overdue).</p>
              </div>
              <div>
                <h3 className="font-medium text-sm sm:text-base">What do the different invoice statuses mean?</h3>
                <div className="text-gray-600 text-sm sm:text-base space-y-2">
                  <div>• Green: Paid - Payment received</div>
                  <div>• Yellow: Pending - Awaiting payment</div>
                  <div>• Red: Overdue - Payment past due</div>
                </div>
              </div>
            </div>
          </section>

          <section>
            <h2 className="text-lg sm:text-xl font-semibold mb-3 sm:mb-4">Service Requests</h2>
            <div className="space-y-3 sm:space-y-4">
              <div>
                <h3 className="font-medium text-sm sm:text-base">How do I manage service requests?</h3>
                <p className="text-gray-600 text-sm sm:text-base">Create new requests using the "Add New Request" button. Each request includes a unique number, customer details, service type, and priority level.</p>
              </div>
              <div>
                <h3 className="font-medium text-sm sm:text-base">What do the priority levels mean?</h3>
                <div className="text-gray-600 text-sm sm:text-base space-y-2">
                  <div>• Red: High priority - Urgent attention needed</div>
                  <div>• Yellow: Medium priority - Standard timeline</div>
                  <div>• Green: Low priority - Can be scheduled flexibly</div>
                </div>
              </div>
            </div>
          </section>

          <section className="border-t pt-4 sm:pt-6">
            <p className="text-gray-500 text-sm sm:text-base">
              Need additional help? Contact our support team at <a href="mailto:brian@bcpsolutionsllc.com" className="text-blue-600 hover:underline">brian@bcpsolutionsllc.com</a>
            </p>
          </section>
        </div>
      </div>
    </General>
  );
}

export default Help; 