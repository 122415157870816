import React, { useEffect, useState } from 'react'
import { getFirestore, collection, query, getDocs, where } from "firebase/firestore";
import General from '../../layouts/General';
import CustomersContent from './CustomersContent';
import { db, auth } from '../../FirebaseConfig'
import { FIRESTORE_PATHS, getCollectionPath } from '../../config/firestore';

const getMyCustomers = async (userId) => {
    try {
        const querySnapshot = await getDocs(
            query(
                collection(db, getCollectionPath(FIRESTORE_PATHS.CUSTOMERS(userId))),
                where("active", "==", true)
            )
        );

        const customers = querySnapshot.docs
            .map(doc => ({ id: doc.id, ...doc.data() }))
            .filter(customer => !customer.archived)
            .sort((a, b) => {
                const nameA = `${a.lastName}, ${a.firstName}`;
                const nameB = `${b.lastName}, ${b.firstName}`;
                return nameA.localeCompare(nameB);
            });

        return customers;
    } catch (error) {
        console.error('Error fetching customers:', error);
        return [];
    }
}

export default function Customers() {
    const [myCustomers, setMyCustomers] = useState([]);
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async user => {
            try {
                if (user) {
                    setCurrentUser(user);
                    const customers = await getMyCustomers(user.uid);
                    setMyCustomers(customers);
                } else {
                    setCurrentUser(null);
                    setMyCustomers([]);
                }
            } catch (error) {
                setError(error.message);
                console.error('Error in auth state change:', error);
            } finally {
                setLoading(false);
            }
        });

        return () => unsubscribe();
    }, []);

    if (loading) return <General title="Customers"><div>Loading...</div></General>;
    if (error) return <General title="Customers"><div>Error: {error}</div></General>;

    return (
        <General title={`Customers (${myCustomers.length})`}>
            <CustomersContent 
                currentUser={currentUser} 
                myCustomers={myCustomers} 
            />
        </General>
    );
}