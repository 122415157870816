const recommengineFeatureDescriptions = [
    {
        "name": "map",
        "displayName": "Map",
        "description": "📍 Add interactive location features with searchable maps and position tracking.",
    },
    {
        "name": "chat",
        "displayName": "Chat",
        "description": "💬 Enable real-time messaging with a sleek interface and timestamp tracking.",
    },
    {
        "name": "camera",
        "displayName": "Camera",
        "description": "📷 Create stunning photo galleries with multi-upload support and auto-playing carousel.",
    },
    {
        "name": "calendar",
        "displayName": "Calendar",
        "description": "📅 Display a calendar for event scheduling and date management.",
    },
    // {
    //     "name": "social",
    //     "displayName": "Social",
    //     "description": "👥 Allow for friending and presence capabilities",
    // },
    // {
    //     "name": "pushNotifications",
    //     "displayName": "Push Notifications",
    //     "description": "🛎️ Allow for push notifications",
    // },
    // {
    //     "name": "onboardingQuestions",
    //     "displayName": "Onboarding Questions",
    //     "description": "🗒️ Require onboarding questions or create quiz",
    // },
    // {
    //     "name": "userProfile",
    //     "displayName": "User Profile",
    //     "description": "👤 Allow for user profile",
    // },
    // {
    //     "name": "customFeature",
    //     "displayName": "Custom Feature",
    //     "description": "🤖 Add custom feature",
    // }
]

export default recommengineFeatureDescriptions