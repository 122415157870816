import React, { useState, useEffect } from 'react'
import {
    Button,
    Checkbox,
    Typography,
    Input,
    List,
    ListItem,
    Card,
    Carousel,
    CardBody
} from "@material-tailwind/react";

// const goals = [
//     "Personal Life",
//     "Work",
//     "School",
//     "Health",
//     "Relationships",
//     "Finances",
// ];

export default function AgentContent({ currentUser, myGoals, activeGoal, setActiveGoal }) {

    return (
        <div className='flex flex-row md:flex-col mt-2'>
            { activeGoal ? (
                <div className='flex flex-col p-10'>
                    <Button color="black" onClick={() => setActiveGoal(null)}>
                        Back
                    </Button>
                    
                    <Typography variant="h2">
                        {activeGoal}
                    </Typography>

                    <Typography variant="h5">
                        How important is this goal to you?
                    </Typography>
                    
                    <Input type="range" min="1" max="5"/>

                    <Typography variant="h5">
                        What do you need to get done before this goal can be achieved?
                    </Typography>

                    <Input type="text" />

                    <Typography variant="h5">
                        By what date do you want to achieve this goal?
                    </Typography>

                    <Input type="date" />

                    <div className='flex flex-col w-full'>
                        <Button className="mt-6" color="black">
                        Save Goal
                        </Button>

                        <Button className="mt-6" color="black">
                        Archive Goal
                        </Button>
                    </div>
                </div>
            ) : (

                <div className='flex flex-col w-full h-full p-10'>
                    {/* <Typography variant="h3">
                        Select a goal
                    </Typography> */}

                    <List>
                        {myGoals.map((goal) => (
                            <ListItem
                                key={goal}
                                onClick={() => setActiveGoal(goal)}
                            >
                                {/* <ListItemPrefix>
                                    <Checkbox
                                        variant="gradient"
                                        color="red"
                                    />
                                </ListItemPrefix> */}
                                <Typography>{goal}</Typography>
                            </ListItem>
                        ))}
                    </List>

                    <Button className="mt-6 ml-2" color="black">
                        Create New Goal
                    </Button>
                </div>

            )}
        </div>
    );
}