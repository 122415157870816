import React, { useState } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Button,
  Switch,
} from "@material-tailwind/react";
import { CheckIcon } from "@heroicons/react/24/solid";
import General from '../layouts/General';

export default function Pricing() {
  const [isAnnual, setIsAnnual] = useState(false);

  const proPrice = isAnnual ? 18.88 : 20.50;
  
  const features = {
    free: [
      "Scheduling & Calendar",
      "Client Management",
      "Team Coordination",
      "Location Tracking",
      "Photo Documentation",
      "Basic Reports",
      "Mobile App Access",
      "Email Support",
    ],
    pro: [
      "All Free Features",
      "Invoicing & Payments",
      "Custom Branding",
      "Advanced Analytics",
      "Priority Support",
      "API Access",
      "Unlimited Storage",
      "Custom Integrations",
    ],
  };

  return (
    <General>
      <div className="max-w-7xl mx-auto px-4 py-16">
        <div className="text-center mb-12">
          <Typography variant="h2" className="mb-4">
            Simple, Transparent Pricing
          </Typography>
          <div className="bg-blue-50 text-blue-800 p-4 rounded-lg mb-6">
            <Typography variant="h6" className="font-medium">
              🎉 Preview Period Special
            </Typography>
            <Typography>
              During our preview period, all Pro features are available for free with your trial!
            </Typography>
          </div>
          <Typography variant="lead" color="gray" className="mb-8">
            Choose the plan that's right for your business. Pro pricing is per admin seat.
          </Typography>
          
          <div className="flex items-center justify-center gap-4">
            <div className={`flex items-center gap-2 ${!isAnnual ? 'font-medium text-blue-500' : 'text-gray-500'}`}>
              <Typography>Monthly</Typography>
              {!isAnnual && (
                <span className="inline-block bg-blue-50 text-blue-500 text-xs px-2 py-1 rounded-full">
                  Current
                </span>
              )}
            </div>
            <Switch 
              checked={isAnnual}
              onChange={() => setIsAnnual(!isAnnual)}
              className="bg-blue-500"
            />
            <div className={`flex items-center gap-2 ${isAnnual ? 'font-medium text-blue-500' : 'text-gray-500'}`}>
              <Typography>Annual</Typography>
              {isAnnual && (
                <span className="inline-block bg-green-100 text-green-700 text-xs px-2 py-1 rounded-full">
                  Save 8%
                </span>
              )}
            </div>
          </div>
        </div>

        <div className="grid md:grid-cols-2 gap-8">
          {/* Free Trial Plan */}
          <Card className="border border-blue-gray-100">
            <CardHeader
              floated={false}
              className="bg-transparent shadow-none text-center p-6"
            >
              <Typography variant="h3" color="blue-gray" className="mb-2">
                Free Trial
              </Typography>
              <Typography variant="h1" color="blue-gray" className="mb-2">
                $0
              </Typography>
              <Typography color="gray" className="font-normal">
                10 days to explore all features
              </Typography>
            </CardHeader>
            <CardBody>
              <ul className="space-y-4">
                {features.free.map((feature, index) => (
                  <li key={index} className="flex items-center gap-4">
                    <CheckIcon className="h-5 w-5 text-green-500" />
                    <Typography color="gray">{feature}</Typography>
                  </li>
                ))}
              </ul>
              <Button
                size="lg"
                variant="outlined"
                className="w-full mt-8"
                onClick={() => window.location.href = '/register'}
              >
                Start Free Trial
              </Button>
            </CardBody>
          </Card>

          {/* Pro Plan */}
          <Card className="border-2 border-blue-500">
            <CardHeader
              floated={false}
              className="bg-transparent shadow-none text-center p-6"
            >
              <Typography variant="h3" color="blue-gray" className="mb-2">
                Pro
              </Typography>
              <div className="flex items-center justify-center gap-2">
                <Typography variant="h1" color="blue-gray">
                  ${proPrice.toFixed(2)}
                </Typography>
                <Typography color="gray" className="font-normal">
                  /month per admin
                </Typography>
              </div>
              {isAnnual && (
                <Typography className="text-green-500 font-medium">
                  Billed annually (${(proPrice * 12).toFixed(2)}/year)
                </Typography>
              )}
            </CardHeader>
            <CardBody>
              <ul className="space-y-4">
                {features.pro.map((feature, index) => (
                  <li key={index} className="flex items-center gap-4">
                    <CheckIcon className="h-5 w-5 text-blue-500" />
                    <Typography color="gray">{feature}</Typography>
                  </li>
                ))}
              </ul>
              <Button
                size="lg"
                className="w-full mt-8 bg-blue-500"
                onClick={() => window.location.href = '/register'}
              >
                Get Started
              </Button>
            </CardBody>
          </Card>
        </div>

        <div className="mt-12 text-center">
          <Typography color="gray" className="mb-4">
            All plans include unlimited customers. Admin seats are required for team members who need full system access.
          </Typography>
          <Typography color="gray">
            Need a custom solution? <a href="/contact" className="text-blue-500 hover:text-blue-700">Contact us</a>
          </Typography>
        </div>
      </div>
    </General>
  );
} 