import {
    Button,
    Checkbox,
    Typography,
    Input,
    List,
    ListItem,
    Card,
    Carousel
} from "@material-tailwind/react";

import Header from './Header'
import { Footer } from './Footer'

function PrivacyPolicy() {
    return (
        <div className='flex flex-col p-2 md:p-2 w-full h-full'>
            <Header />
            <div className='flex flex-row md:flex-col w-full h-full text-left p-10'>
                <div>
                    <Typography variant='h4' className='ml-4'>Privacy Policy for Recommengine</Typography>

                    <div className='flex flex-col w-full h-full p-10'>
                        <div class="text-black">
                            Last Updated: July 11, 2024
                            <br/><br/>
                            Welcome to Recommengine! We are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you use our mobile application, Recommengine.
                            <br/><br/>
                            1. Information We Collect
                            <br/><br/>
                            We may collect information about you in various ways. The information we may collect via the Application includes:
                            <br/><br/>
                                Personal Data: Personally identifiable information, such as your name, email address, and telephone number, that you voluntarily give to us when choosing to participate in various activities related to the Application.
                            <br/><br/>
                                Derivative Data: Information our servers automatically collect when you access the Application, such as your IP address, browser type, operating system, and the pages you have viewed directly before and after accessing the Application.
                            <br/><br/>
                                Financial Data: Financial information, such as data related to your payment method (e.g., valid credit card number, card brand, expiration date), that we may collect when you purchase, order, return, exchange, or request information about our services from the Application.
                            <br/><br/>
                                Mobile Device Data: Device information, such as your mobile device ID, model, and manufacturer, and information about the location of your device, if you permit the Application to access such information.
                            <br/><br/>
                            2. Use of Your Information
                            <br/><br/>
                            Having accurate information about you permits us to provide you with a smooth, efficient, and customized experience. Specifically, we may use information collected about you via the Application to:
                            <br/><br/>
                                Create and manage your account.<br/>
                                Email you regarding your account or order.<br/>
                                Fulfill and manage purchases, orders, payments, and other transactions related to the Application.<br/>
                                Increase the efficiency and operation of the Application.<br/>
                                Monitor and analyze usage and trends to improve your experience with the Application.<br/>
                                Notify you of updates to the Application.<br/>
                                Offer new products, services, and/or recommendations to you.<br/>
                                Perform other business activities as needed.<br/>
                            <br/><br/>
                            3. Disclosure of Your Information
                            <br/><br/>
                            We may share information we have collected about you in certain situations. Your information may be disclosed as follows:
                            <br/><br/>
                                By Law or to Protect Rights: If we believe the release of information about you is necessary to respond to legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may share your information as permitted or required by any applicable law, rule, or regulation.
                            <br/><br/>
                                Third-Party Service Providers: We may share your information with third parties that perform services for us or on our behalf, including payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance.
                            <br/><br/>
                                Marketing Communications: With your consent, or with an opportunity for you to withdraw consent, we may share your information with third parties for marketing purposes, as permitted by law.
                            <br/><br/>
                            4. Security of Your Information
                            <br/><br/>
                            We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.
                            <br/><br/>
                            5. Policy for Children
                            <br/><br/>
                            We do not knowingly solicit information from or market to children under the age of 13. If we learn that any information we have collected has been provided by a child under the age of 13, we will delete that information as quickly as possible.
                            <br/><br/>
                            6. Changes to This Privacy Policy
                            <br/><br/>
                            We may update this Privacy Policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal, or regulatory reasons.
                            <br/><br/>
                            7. Contact Us
                            <br/><br/>
                            If you have questions or comments about this Privacy Policy, please contact us at:
                            <br/><br/>
                            BCP Solutions, LLC
                            <br/>
                            <a href="mailto:brian@bcpsolutionsllc.com">brian@bcpsolutionsllc.com</a>
                            <br/>
                            <a href="https://www.bcpsolutionsllc.com/" target="_blank">www.bcpsolutionsllc.com</a>
                            </div>
                        </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default PrivacyPolicy