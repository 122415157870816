import { Typography, Button } from "@material-tailwind/react";
import General from '../layouts/General';

export function Affiliates() {
    return (
        <General>
            <section className="bg-gradient-to-b from-military-green-50 via-earth-brown-50 to-white py-16">
                <div className="max-w-4xl mx-auto px-4">
                    <div className="text-center mb-12">
                        <Typography variant="h2" className="text-4xl font-bold mb-3">
                            <span className="bg-gradient-to-r from-military-green-600 to-slate-blue-500 bg-clip-text text-transparent">
                                Affiliate Program
                            </span>
                        </Typography>
                        <Typography className="text-military-green-600 max-w-2xl mx-auto">
                            Join our growing network of partners and earn commissions by promoting our platform.
                        </Typography>
                    </div>

                    <div className="bg-white/80 backdrop-blur-sm border-2 border-military-green-200 p-8 rounded-sm space-y-6">
                        <div className="flex items-center gap-3 mb-4">
                            <div className="p-2 bg-military-green-50 rounded-sm">
                                <svg className="w-5 h-5 text-military-green-600" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </div>
                            <Typography variant="h4" className="font-bold text-military-green-800">
                                Coming Soon
                            </Typography>
                        </div>

                        <Typography className="text-military-green-600">
                            Our affiliate program is currently under development. Check back soon for more details about:
                        </Typography>

                        <ul className="space-y-3 text-military-green-600">
                            <li className="flex items-start gap-2">
                                <span className="text-green-500 mt-1">✓</span>
                                <span>Competitive commission structures</span>
                            </li>
                            <li className="flex items-start gap-2">
                                <span className="text-green-500 mt-1">✓</span>
                                <span>Professional promotional materials</span>
                            </li>
                            <li className="flex items-start gap-2">
                                <span className="text-green-500 mt-1">✓</span>
                                <span>Partnership opportunities</span>
                            </li>
                            <li className="flex items-start gap-2">
                                <span className="text-green-500 mt-1">✓</span>
                                <span>Exclusive affiliate resources</span>
                            </li>
                        </ul>

                        <div className="mt-8 flex flex-col items-center space-y-4">
                            <Button
                                href="mailto:brian@bcpsolutionsllc.com"
                                className="bg-gradient-to-r from-military-green-500 to-slate-blue-500"
                            >
                                Contact Us to Learn More
                            </Button>
                            <Typography variant="small" className="text-military-green-400">
                                or email us at <span className="text-military-green-500">brian@bcpsolutionsllc.com</span>
                            </Typography>
                        </div>
                    </div>
                </div>
            </section>
        </General>
    );
}

export default Affiliates;