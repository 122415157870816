export const FIRESTORE_PATHS = {
    ENGINES: (userId) => ['recommengine', 'users', userId, 'data', 'engines'],
    CUSTOMERS: (userId) => ['recommengine', 'users', userId, 'data', 'customers'],
    GOALS: (userId) => ['recommengine', 'users', userId, 'data', 'goals'],
    AGENTS: (userId) => ['recommengine', 'users', userId, 'data', 'agents'],
    QUIZZES: (userId) => ['recommengine', 'users', userId, 'data', 'quizzes'],
    INVOICES: (userId) => ['recommengine', 'users', userId, 'data', 'invoices'],
    REQUESTS: (userId) => ['recommengine', 'users', userId, 'data', 'requests'],
    // Sub-collections
    ENGINE_POSITIONS: (userId, engineId) => [...FIRESTORE_PATHS.ENGINES(userId), engineId, 'positions'],
    ENGINE_PHOTOS: (userId, engineId) => [...FIRESTORE_PATHS.ENGINES(userId), engineId, 'photos'],
    ENGINE_EVENTS: (userId, engineId) => [...FIRESTORE_PATHS.ENGINES(userId), engineId, 'calendarEvents']
};

// Helper to convert path array to string
export const getCollectionPath = (pathArray) => pathArray.join('/'); 