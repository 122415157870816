import React from 'react';
import {
    Card,
    Typography,
    Button,
} from "@material-tailwind/react";
import RegularButton from '../buttons/RegularButton';

export default function InvoicesContent({ currentUser, myInvoices }) {
    const getStatusColor = (status) => {
        switch (status.toLowerCase()) {
            case 'paid':
                return 'bg-green-100 text-green-800';
            case 'pending':
                return 'bg-yellow-100 text-yellow-800';
            case 'overdue':
                return 'bg-red-100 text-red-800';
            default:
                return 'bg-blue-gray-100 text-blue-gray-800';
        }
    };

    return (
        <div className='flex flex-col mt-2'>
            <Card className="h-full w-full">
                <div className="grid grid-cols-1 gap-4 p-4">
                    {[...myInvoices]
                        .sort((a, b) => {
                            if (a.archived !== b.archived) {
                                return a.archived ? 1 : -1;
                            }
                            return new Date(b.date) - new Date(a.date);
                        })
                        .map((invoice, index) => (
                            <a 
                                key={`invoice-${invoice.id}`}
                                href={'/invoices/' + invoice.id}
                                className="flex flex-col bg-white p-4 rounded-lg shadow-sm hover:shadow-md transition-shadow cursor-pointer"
                            >
                                <div className="flex flex-col space-y-2">
                                    <div className="flex justify-between">
                                        <Typography variant="small" color="blue-gray" className="font-bold text-base">
                                            {invoice.customerName}
                                        </Typography>
                                        {invoice.archived && (
                                            <span className="px-2 py-1 bg-gray-100 text-gray-600 rounded-full text-xs font-medium">
                                                Archived
                                            </span>
                                        )}
                                    </div>
                                    <Typography variant="small" color="gray" className="text-sm">
                                        Invoice #{invoice.invoiceNumber}
                                    </Typography>
                                    <Typography variant="small" color="gray" className="text-sm">
                                        {new Date(invoice.date).toLocaleDateString()}
                                    </Typography>
                                    <div className="flex items-center space-x-2">
                                        <Typography variant="small" color="blue-gray" className="font-normal">
                                            ${invoice.amount}
                                        </Typography>
                                        <div className={`inline-block px-3 py-1 rounded-full text-xs font-medium ${getStatusColor(invoice.status)}`}>
                                            {invoice.status.charAt(0).toUpperCase() + invoice.status.slice(1)}
                                        </div>
                                    </div>
                                </div>
                            </a>
                        ))}
                </div>
            </Card>
        </div>
    );
}