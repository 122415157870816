import React, { useState, useEffect } from 'react';
import {
  Card,
  Input,
  Button,
  Typography,
  Select,
  Option,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import { getFunctions, httpsCallable } from "firebase/functions";
import { app } from '../FirebaseConfig';
import { getFirestore, doc, setDoc, deleteDoc } from 'firebase/firestore';
import { PlusIcon } from "@heroicons/react/24/solid";

export default function StripeConnectForm({ currentUser, stripeAccount, onRefresh }) {
  const [formData, setFormData] = useState({
    email: currentUser?.email || '',
    business_type: 'individual',
    business_name: '',
    first_name: '',
    last_name: '',
    phone: '',
    dob_day: '',
    dob_month: '',
    dob_year: '',
    address_line1: '',
    address_city: '',
    address_state: '',
    address_postal_code: '',
    ssn_last_4: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isInitializing, setIsInitializing] = useState(true);
  const [isResetting, setIsResetting] = useState(false);
  const [showBankForm, setShowBankForm] = useState(false);
  const [bankFormData, setBankFormData] = useState({
    account_holder_name: '',
    routing_number: '',
    account_number: '',
    account_type: 'checking',
  });
  const [successMessage, setSuccessMessage] = useState(null);

  useEffect(() => {
    setTimeout(() => {
      setIsInitializing(false);
    }, 100);
  }, []);

  if (isInitializing) {
    return (
      <div className="flex justify-center items-center min-h-[200px]">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    try {
      const functions = getFunctions(app);
      const createPaymentEvent = httpsCallable(functions, 'createPaymentEvent');
      
      const result = await createPaymentEvent({ data: formData });
      
      if (result.data.success && result.data.accountLink) {
        const db = getFirestore(app);
        const stripeConfigRef = doc(db, 'recommengine/users', currentUser.uid, 'config');
        
        await setDoc(stripeConfigRef, {
          stripe: {
            accountId: result.data.account_id,
            accountLink: result.data.accountLink,
            accountLinkCreated: new Date().toISOString(),
            detailsSubmitted: result.data.details.details_submitted,
            payoutsEnabled: result.data.details.payouts_enabled,
            chargesEnabled: result.data.details.charges_enabled,
            requirements: result.data.details.requirements,
            dateCreated: result.data.details.created,
            businessType: result.data.details.business_type,
            country: result.data.details.country,
            defaultCurrency: result.data.details.default_currency,
            email: result.data.details.email,
            capabilities: result.data.details.capabilities,
            futureRequirements: result.data.details.future_requirements,
          },
        }, { merge: true });

      } else {
        throw new Error('Failed to create Stripe account');
      }
    } catch (error) {
      console.error('Error creating Stripe account:', error);
      setError(error.message || 'An error occurred while connecting to Stripe');
    } finally {
      setIsLoading(false);
    }
  };

  const handleReset = async () => {
    setIsResetting(true);
    try {
      const db = getFirestore(app);
      const stripeConfigRef = doc(db, 'recommengine/users', currentUser.uid, 'config');
      await deleteDoc(stripeConfigRef);
      window.location.reload();
    } catch (error) {
      console.error('Error resetting Stripe account:', error);
      setError(error.message || 'An error occurred while resetting Stripe account');
    } finally {
      setIsResetting(false);
    }
  };

  const handleAddBankAccount = async () => {
    console.log('Starting bank account addition process');
    setIsLoading(true);
    setError(null);
    try {
      const functions = getFunctions(app);
      const addBankAccount = httpsCallable(functions, 'addBankAccount');
      
      const bankAccountPayload = {
        account_id: stripeAccount.accountId,
        account_holder_name: bankFormData.account_holder_name,
        account_holder_type: 'individual',
        routing_number: bankFormData.routing_number,
        account_number: bankFormData.account_number,
        account_type: bankFormData.account_type,
        metadata: {
          user_id: currentUser.uid,
          user_email: currentUser.email,
          user_name: bankFormData.account_holder_name
        }
      };
      console.log('Calling addBankAccount with payload:', { ...bankAccountPayload });
      
      const result = await addBankAccount(bankAccountPayload);
      console.log('Received response from addBankAccount:', result);
      
      if (result.data.success) {
        console.log('Successfully added bank account, updating Firestore');
        // Update Firebase with bank account details
        const db = getFirestore(app);
        const stripeConfigRef = doc(db, 'recommengine/users', currentUser.uid, 'config');
        
        const bankAccountData = {
          stripe: {
            bankAccounts: {
              [result.data.bank_account.id]: {
                last4: result.data.bank_account.last4,
                bankName: result.data.bank_account.bank_name,
                routingNumber: result.data.bank_account.routing_number,
                dateAdded: new Date().toISOString()
              }
            }
          }
        };
        console.log('Updating Firestore with bank account data:', bankAccountData);

        await setDoc(stripeConfigRef, bankAccountData, { merge: true });
        console.log('Firestore update complete');

        setShowBankForm(false);
        setBankFormData({
          account_holder_name: '',
          routing_number: '',
          account_number: '',
          account_type: 'checking',
          account_holder_type: 'individual',
        });
        setSuccessMessage('Bank account successfully added!');
        if (onRefresh) onRefresh();
      } else {
        // Handle error from function result
        throw new Error(result.data.error || 'Failed to add bank account');
      }
    } catch (error) {
      console.error('Error adding bank account:', error);
      // Extract the error message from Firebase Functions response
      const errorMessage = error.message || error.details?.message || 'An error occurred while adding the bank account';
      setError(errorMessage);
      return;
    } finally {
      setIsLoading(false);
    }
    
    setShowBankForm(false);
  };

  // If stripe account exists, display account information
  if (stripeAccount) {
    return (
      <div className='flex flex-col w-full px-4 sm:px-6'>
        <Card className="bg-white shadow-md rounded px-4 sm:px-8 pt-6 pb-8 mb-4">
          <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between cursor-pointer" 
               onClick={() => setIsExpanded(!isExpanded)}>
            <Typography variant="h6" color="blue-gray" className="text-lg font-medium mb-2 sm:mb-0">
              Stripe Account Status
            </Typography>
            <div className="flex items-center gap-2">
              <div className={`w-3 h-3 rounded-full ${
                stripeAccount.detailsSubmitted && stripeAccount.payoutsEnabled 
                  ? 'bg-green-500' 
                  : 'bg-yellow-500'
              }`} />
              <Typography variant="small" className="text-gray-600">
                {stripeAccount.detailsSubmitted && stripeAccount.payoutsEnabled 
                  ? 'Active' 
                  : 'Pending'}
              </Typography>
            </div>
          </div>

          {!isExpanded ? (
            <Typography variant="small" color="gray" className="mt-2">
              Click to view details
            </Typography>
          ) : (
            <div className="space-y-6 mt-4">
              <div>
                <Typography variant="h6" color="blue-gray">
                  Account Details
                </Typography>
                <div className="grid grid-cols-2 gap-4 mt-2">
                  <div>
                    <Typography variant="small" color="gray" className="font-medium">
                      Business Type
                    </Typography>
                    <Typography>{stripeAccount.businessType}</Typography>
                  </div>
                  <div>
                    <Typography variant="small" color="gray" className="font-medium">
                      Email
                    </Typography>
                    <Typography>{stripeAccount.email}</Typography>
                  </div>
                </div>
              </div>

              <div>
                <Typography variant="h6" color="blue-gray">
                  Account Status
                </Typography>
                <div className="grid grid-cols-2 gap-4 mt-2">
                  <div>
                    <Typography variant="small" color="gray" className="font-medium">
                      Details Submitted
                    </Typography>
                    <Typography>{stripeAccount.detailsSubmitted ? "Yes" : "No"}</Typography>
                  </div>
                  <div>
                    <Typography variant="small" color="gray" className="font-medium">
                      Payouts Enabled
                    </Typography>
                    <Typography>{stripeAccount.payoutsEnabled ? "Yes" : "No"}</Typography>
                  </div>
                  <div>
                    <Typography variant="small" color="gray" className="font-medium">
                      Charges Enabled
                    </Typography>
                    <Typography>{stripeAccount.chargesEnabled ? "Yes" : "No"}</Typography>
                  </div>
                </div>
              </div>

              {stripeAccount.requirements?.currently_due?.length > 0 && (
                <div>
                  <Typography variant="h6" color="blue-gray">
                    Required Actions
                  </Typography>
                  <ul className="list-disc pl-6 mt-2">
                    {stripeAccount.requirements.currently_due.map((requirement, index) => (
                      <li key={index}>
                        <Typography>{requirement.replace(/_/g, ' ')}</Typography>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          )}
        </Card>

        <Card className="bg-white shadow-md rounded px-4 sm:px-8 pt-6 pb-8 mb-4 mt-4">
          <div className="flex justify-between items-center mb-4">
            <Typography variant="h6" color="blue-gray" className="text-lg font-medium">
              Bank Accounts
            </Typography>
            <Button
              size="sm"
              className="flex items-center gap-2"
              onClick={() => setShowBankForm(true)}
            >
              <PlusIcon className="h-4 w-4" />
              Add Bank Account
            </Button>
          </div>
          
          {/* Add this section to display bank accounts */}
          {stripeAccount?.bankAccounts && Object.entries(stripeAccount.bankAccounts).length > 0 ? (
            <div className="space-y-4">
              {Object.entries(stripeAccount.bankAccounts).map(([id, account]) => (
                <div key={id} className="flex justify-between items-center p-4 border rounded-lg">
                  <div>
                    <Typography variant="h6" className="text-sm font-medium">
                      {account.bankName}
                    </Typography>
                    <Typography variant="small" color="gray">
                      ••••{account.last4} • Added {new Date(account.dateAdded).toLocaleDateString()}
                    </Typography>
                  </div>
                  <Button
                    color="red"
                    variant="text"
                    size="sm"
                    onClick={async () => {
                      try {
                        const functions = getFunctions(app);
                        const deleteBankAccount = httpsCallable(functions, 'deleteBankAccount');
                        await deleteBankAccount({ 
                          account_id: stripeAccount.accountId,
                          bank_account_id: id 
                        });
                        
                        // Update Firestore to remove the bank account
                        const db = getFirestore(app);
                        const stripeConfigRef = doc(db, 'recommengine/users', currentUser.uid, 'config');
                        const bankAccounts = { ...stripeAccount.bankAccounts };
                        delete bankAccounts[id];
                        
                        await setDoc(stripeConfigRef, {
                          stripe: { bankAccounts }
                        }, { merge: true });
                        
                        setSuccessMessage('Bank account successfully removed');
                        if (onRefresh) onRefresh();
                      } catch (error) {
                        setError(error.message || 'Failed to remove bank account');
                      }
                    }}
                  >
                    Remove
                  </Button>
                </div>
              ))}
            </div>
          ) : (
            <Typography variant="small" color="gray" className="text-center py-4">
              No bank accounts connected yet
            </Typography>
          )}
        </Card>

        <Dialog open={showBankForm} handler={() => setShowBankForm(false)}>
          <DialogHeader>Add Bank Account</DialogHeader>
          <DialogBody divider>
            <form className="space-y-4">
              <Input
                label="Account Holder Name"
                value={bankFormData.account_holder_name}
                onChange={(e) => setBankFormData({
                  ...bankFormData,
                  account_holder_name: e.target.value
                })}
                required
              />
              
              <Select
                label="Account Holder Type"
                value={bankFormData.account_holder_type}
                onChange={(value) => setBankFormData({
                  ...bankFormData,
                  account_holder_type: value
                })}
              >
                <Option value="individual">Individual</Option>
                <Option value="company">Company</Option>
              </Select>
              
              <Input
                label="Routing Number"
                value={bankFormData.routing_number}
                onChange={(e) => setBankFormData({
                  ...bankFormData,
                  routing_number: e.target.value
                })}
                required
              />
              
              <Input
                label="Account Number"
                value={bankFormData.account_number}
                onChange={(e) => setBankFormData({
                  ...bankFormData,
                  account_number: e.target.value
                })}
                required
              />
              
              {/* <Select
                label="Account Type"
                value={bankFormData.account_type}
                onChange={(value) => setBankFormData({
                  ...bankFormData,
                  account_type: value
                })}
              >
                <Option value="checking">Checking</Option>
                <Option value="savings">Savings</Option>
              </Select> */}
            </form>
          </DialogBody>
          <DialogFooter>
            <Button
              variant="text"
              color="red"
              onClick={() => setShowBankForm(false)}
              className="mr-1"
            >
              Cancel
            </Button>
            <Button onClick={handleAddBankAccount} disabled={isLoading}>
              {isLoading ? "Adding..." : "Add Bank Account"}
            </Button>
          </DialogFooter>
        </Dialog>

        {/* Success Dialog */}
        <Dialog open={!!successMessage} handler={() => setSuccessMessage(null)}>
          <DialogHeader>Success</DialogHeader>
          <DialogBody divider>
            {successMessage || ""}
          </DialogBody>
          <DialogFooter>
            <Button
              variant="text"
              color="green"
              onClick={() => setSuccessMessage(null)}
              className="mr-1"
            >
              Close
            </Button>
          </DialogFooter>
        </Dialog>

        {/* Error Dialog */}
        <Dialog open={!!error} handler={() => setError(null)}>
          <DialogHeader>Error</DialogHeader>
          <DialogBody divider>
            {error || ""}
          </DialogBody>
          <DialogFooter>
            <Button
              variant="text"
              color="red"
              onClick={() => setError(null)}
              className="mr-1"
            >
              Close
            </Button>
          </DialogFooter>
        </Dialog>
      </div>
    );
  }

  return (
    <div className='flex flex-col w-full px-4 sm:px-6'>
      <Card className="bg-white shadow-md rounded px-4 sm:px-8 pt-6 pb-8 mb-4">
        <Typography variant="h6" color="blue-gray" className="text-lg font-medium mb-4">
          Connect with Stripe
        </Typography>
        
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Customer Information Section */}
            <div className='space-y-4'>
              <Typography variant="h6" color="blue-gray" className="text-sm font-medium text-gray-500 mb-2">
                Personal Information
              </Typography>
              
              <Select
                label="Business Type"
                value={formData.business_type}
                onChange={(value) => setFormData({...formData, business_type: value})}
                className="mb-4"
              >
                <Option value="individual">Individual</Option>
                <Option value="company">Company</Option>
              </Select>
              
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <Input
                  size="lg"
                  label="First Name"
                  value={formData.first_name}
                  onChange={(e) => setFormData({...formData, first_name: e.target.value})}
                  required
                />
                <Input
                  size="lg"
                  label="Last Name"
                  value={formData.last_name}
                  onChange={(e) => setFormData({...formData, last_name: e.target.value})}
                  required
                />
              </div>

              <Input
                size="lg"
                label="Email"
                type="email"
                value={formData.email}
                onChange={(e) => setFormData({...formData, email: e.target.value})}
                required
              />

              <Input
                size="lg"
                label="Phone"
                type="tel"
                value={formData.phone}
                onChange={(e) => setFormData({...formData, phone: e.target.value})}
                required
              />
            </div>

            {/* Address Section */}
            <div className='space-y-4'>
              <Typography variant="h6" color="blue-gray" className="text-sm font-medium text-gray-500 mb-2">
                Address & Additional Details
              </Typography>

              <Input
                size="lg"
                label="Address"
                value={formData.address_line1}
                onChange={(e) => setFormData({...formData, address_line1: e.target.value})}
                required
              />

              <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                <Input
                  size="lg"
                  label="City"
                  value={formData.address_city}
                  onChange={(e) => setFormData({...formData, address_city: e.target.value})}
                  required
                />
                <Input
                  size="lg"
                  label="State"
                  value={formData.address_state}
                  onChange={(e) => setFormData({...formData, address_state: e.target.value})}
                  required
                />
                <Input
                  size="lg"
                  label="Postal Code"
                  value={formData.address_postal_code}
                  onChange={(e) => setFormData({...formData, address_postal_code: e.target.value})}
                  required
                />
              </div>

              <div className="grid grid-cols-3 gap-4">
                <Input
                  label="DOB Day"
                  type="number"
                  value={formData.dob_day}
                  onChange={(e) => setFormData({...formData, dob_day: e.target.value})}
                  required
                />
                <Input
                  label="DOB Month"
                  type="number"
                  value={formData.dob_month}
                  onChange={(e) => setFormData({...formData, dob_month: e.target.value})}
                  required
                />
                <Input
                  label="DOB Year"
                  type="number"
                  value={formData.dob_year}
                  onChange={(e) => setFormData({...formData, dob_year: e.target.value})}
                  required
                />
              </div>

              <Input
                label="Last 4 of SSN"
                type="password"
                maxLength="4"
                value={formData.ssn_last_4}
                onChange={(e) => setFormData({...formData, ssn_last_4: e.target.value})}
                required
              />
            </div>
          </div>

          <div className="md:col-span-2 flex flex-col items-center gap-2 mt-6">
            <Button 
              type="submit" 
              className="w-full md:w-auto px-6"
              disabled={isLoading}
            >
              {isLoading ? "Connecting..." : "Connect with Stripe"}
            </Button>
          </div>
        </form>
      </Card>

      <Dialog open={!!error} handler={() => setError(null)}>
        <DialogHeader>Error</DialogHeader>
        <DialogBody divider>
          {error || ""}
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={() => setError(null)}
            className="mr-1"
          >
            Close
          </Button>
        </DialogFooter>
      </Dialog>
    </div>
  );
} 