import React from 'react';
import appstorepreview1 from '../images/appstorepreview1.png';
import appstorepreview2 from '../images/appstorepreview2.png';
import appstorepreview3 from '../images/appstorepreview3.png';
import appstorepreview4 from '../images/appstorepreview4.png';
import appstorepreview5 from '../images/appstorepreview5.png';

export function ExamplesCarousel({ 
  currentSlide, 
  handlePrevSlide, 
  handleNextSlide, 
  handleSlideChange 
}) {
  return (
      <div className="max-w-7xl mx-auto px-4 relative">
        {/* Carousel container */}
        <div className="overflow-hidden">
          <div 
            className="flex transition-transform duration-500 ease-out"
            style={{ transform: `translateX(-${currentSlide * 100}%)` }}
          >
            {/* Example 1: Field Service */}
            <div className="min-w-full flex-shrink-0">
              <div className="bg-gradient-to-r from-blue-100 to-purple-100 rounded-2xl p-8">
                <div className="grid md:grid-cols-2 gap-8 items-center">
                  <div>
                    <h3 className="text-2xl font-bold mb-4 md:pl-8">Field Service App</h3>
                    <ul className="space-y-3 md:pl-8">
                      <li className="flex items-center gap-2">
                        <span className="text-green-500">✓</span>
                        <span>Schedule & dispatch teams</span>
                      </li>
                      <li className="flex items-center gap-2">
                        <span className="text-green-500">✓</span>
                        <span>Track location & progress</span>
                      </li>
                      <li className="flex items-center gap-2">
                        <span className="text-green-500">✓</span>
                        <span>Capture photos & signatures</span>
                      </li>
                      <li className="flex items-center gap-2">
                        <span className="text-green-500">✓</span>
                        <span>Process payments on-site</span>
                      </li>
                    </ul>
                  </div>
                  <div className="flex justify-center">
                    <div className="bg-white p-4 rounded-xl shadow-lg transform rotate-3">
                      <img 
                        src={appstorepreview1} 
                        alt="Field Service App Preview" 
                        className="w-48 rounded-lg"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Example 2: Home Services */}
            <div className="min-w-full flex-shrink-0">
              <div className="bg-gradient-to-r from-green-100 to-blue-100 rounded-2xl p-8">
                <div className="grid md:grid-cols-2 gap-8 items-center">
                  <div>
                    <h3 className="text-2xl font-bold mb-4 md:pl-8">Home Services App</h3>
                    <ul className="space-y-3 md:pl-8">
                      <li className="flex items-center gap-2">
                        <span className="text-green-500">✓</span>
                        <span>Before/After photo documentation</span>
                      </li>
                      <li className="flex items-center gap-2">
                        <span className="text-green-500">✓</span>
                        <span>GPS tracking for service areas</span>
                      </li>
                      <li className="flex items-center gap-2">
                        <span className="text-green-500">✓</span>
                        <span>Real-time job status updates</span>
                      </li>
                      <li className="flex items-center gap-2">
                        <span className="text-green-500">✓</span>
                        <span>Customer notification system</span>
                      </li>
                    </ul>
                  </div>
                  <div className="flex justify-center">
                    <div className="bg-white p-4 rounded-xl shadow-lg transform -rotate-2">
                      <img 
                        src={appstorepreview2} 
                        alt="Home Services App Preview" 
                        className="w-48 rounded-lg"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Example 3: Tool Tracking */}
            <div className="min-w-full flex-shrink-0">
              <div className="bg-gradient-to-r from-yellow-100 to-orange-100 rounded-2xl p-8">
                <div className="grid md:grid-cols-2 gap-8 items-center">
                  <div>
                    <h3 className="text-2xl font-bold mb-4 md:pl-8">Tool Tracking App</h3>
                    <ul className="space-y-3 md:pl-8">
                      <li className="flex items-center gap-2">
                        <span className="text-green-500">✓</span>
                        <span>Tool inventory management</span>
                      </li>
                      <li className="flex items-center gap-2">
                        <span className="text-green-500">✓</span>
                        <span>Location pinning for stored items</span>
                      </li>
                      <li className="flex items-center gap-2">
                        <span className="text-green-500">✓</span>
                        <span>Maintenance schedule tracking</span>
                      </li>
                      <li className="flex items-center gap-2">
                        <span className="text-green-500">✓</span>
                        <span>Borrowing & return system</span>
                      </li>
                    </ul>
                  </div>
                  <div className="flex justify-center">
                    <div className="bg-white p-4 rounded-xl shadow-lg transform rotate-1">
                      <img 
                        src={appstorepreview3} 
                        alt="Tool Tracking App Preview" 
                        className="w-48 rounded-lg"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Example 4: Lawn Care */}
            <div className="min-w-full flex-shrink-0">
              <div className="bg-gradient-to-r from-green-100 to-teal-100 rounded-2xl p-8">
                <div className="grid md:grid-cols-2 gap-8 items-center">
                  <div>
                    <h3 className="text-2xl font-bold mb-4 md:pl-8">Lawn Care App</h3>
                    <ul className="space-y-3 md:pl-8">
                      <li className="flex items-center gap-2">
                        <span className="text-green-500">✓</span>
                        <span>Route optimization for service areas</span>
                      </li>
                      <li className="flex items-center gap-2">
                        <span className="text-green-500">✓</span>
                        <span>Weather-based scheduling</span>
                      </li>
                      <li className="flex items-center gap-2">
                        <span className="text-green-500">✓</span>
                        <span>Property photo documentation</span>
                      </li>
                      <li className="flex items-center gap-2">
                        <span className="text-green-500">✓</span>
                        <span>Recurring service management</span>
                      </li>
                    </ul>
                  </div>
                  <div className="flex justify-center">
                    <div className="bg-white p-4 rounded-xl shadow-lg transform rotate-2">
                      <img 
                        src={appstorepreview4} 
                        alt="Lawn Care App Preview" 
                        className="w-48 rounded-lg"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Example 5: Event Planning */}
            <div className="min-w-full flex-shrink-0">
              <div className="bg-gradient-to-r from-purple-100 to-pink-100 rounded-2xl p-8">
                <div className="grid md:grid-cols-2 gap-8 items-center">
                  <div>
                    <h3 className="text-2xl font-bold mb-4 md:pl-8">Event Planning App</h3>
                    <ul className="space-y-3 md:pl-8">
                      <li className="flex items-center gap-2">
                        <span className="text-green-500">✓</span>
                        <span>Guest list management</span>
                      </li>
                      <li className="flex items-center gap-2">
                        <span className="text-green-500">✓</span>
                        <span>Venue booking & coordination</span>
                      </li>
                      <li className="flex items-center gap-2">
                        <span className="text-green-500">✓</span>
                        <span>Timeline & checklist tracking</span>
                      </li>
                      <li className="flex items-center gap-2">
                        <span className="text-green-500">✓</span>
                        <span>Vendor collaboration tools</span>
                      </li>
                    </ul>
                  </div>
                  <div className="flex justify-center">
                    <div className="bg-white p-4 rounded-xl shadow-lg transform -rotate-1">
                      <img 
                        src={appstorepreview5} 
                        alt="Event Planning App Preview" 
                        className="w-48 rounded-lg"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Carousel Controls */}
        <div className="absolute top-1/2 -translate-y-1/2 flex justify-between w-full px-4">
          <button 
            onClick={handlePrevSlide}
            className="p-2 rounded-full bg-white shadow-lg hover:bg-gray-100 transition-colors"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
          </button>
          <button 
            onClick={handleNextSlide}
            className="p-2 rounded-full bg-white shadow-lg hover:bg-gray-100 transition-colors"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </button>
        </div>

        {/* Carousel Indicators */}
        <div className="flex justify-center gap-2 mt-4">
          {[0, 1, 2, 3, 4].map((index) => (
            <button
              key={index}
              onClick={() => handleSlideChange(index)}
              className={`w-2 h-2 rounded-full transition-colors ${
                currentSlide === index ? 'bg-blue-600' : 'bg-gray-300'
              }`}
            />
          ))}
        </div>
      </div>
  );
} 