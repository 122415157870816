

function QuestionResponseText(props) {
    const { quizId, question, answers } = props;
    return (
        <div className="question-response-text">
            <p>{props.question}</p>
            {answers?.map((answer, index) => {
                return <p key={index}>{answer.answer}</p>;
            })}
        </div>
    )
}

export default QuestionResponseText