import {
    Button,
    Checkbox,
    Typography,
    Input,
    List,
    ListItem,
    Card,
    Carousel
} from "@material-tailwind/react";

import Header from './Header'
import { Footer } from './Footer'

function Products() {
    return (
        <div className='flex flex-col p-2 md:p-2 w-full h-full'>
            <Header />
            <div className='flex flex-row md:flex-col w-full h-full text-center p-10'>
                <div>
                    <Typography variant='h4' className='ml-4'>Products</Typography>
                    {/* <Typography variant='h1' className='ml-4'></Typography>
                    <Typography variant='p' className='ml-4'></Typography> */}

                    <div className='flex flex-col w-full h-full p-10'>
                        {/* <Card color="transparent" shadow={false} className="w-1/2 pt-10 pb-10 ml-auto mr-auto">
                            
                        </Card> */}
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
}

export default Products