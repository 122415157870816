import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import {
    Card,
    Input,
    Checkbox,
    Button,
    Typography,
  } from "@material-tailwind/react";

// import QuestionCard from '../question/QuestionCard'
// import LoadingIndicator from '../LoadingIndicator';
// import Header from '../Header'
// import { Footer } from '../Footer'
// import NavigationLinks from '../NavigationLinks'

import { db, auth } from '../../FirebaseConfig'
import { doc, getDoc } from "firebase/firestore";

import General from '../../layouts/General';
import AgentContent from './AgentContent';

function Agent() {
    // const { currentUser } = props;
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [currentUser, setCurrentUser] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [activeAgent, setActiveAgent] = useState({});

    const getAgent = async () => {
        if (!currentUser) return false

        try {
            const docRef = await getDoc(doc(db, "recommengine/users/"+ currentUser.uid +"/data/agents", id));
            const docSnap = docRef.data();
            return docSnap
        } catch (e) {
            console.error("Error getting document: ", e);
            return false
        }
    }

    useEffect(() => {
        // get id from url
        console.log('agent id: ', id);

        // get current user
        // auth.currentUser && setCurrentUser(auth.currentUser);

        // auth.authStateReady && setLoading(true);

        auth.onAuthStateChanged(user => {
            if (user && id) {
                setCurrentUser(user);
                if (loading) {

                    // Get agent by id
                    getAgent().then((result) => {
                        if (result) {
                            console.log('result: ', result);
                            setActiveAgent(result);
                            console.log('active agent: ', result);
                            setLoading(false);
                        }
                    });
                }
            } else {
                setCurrentUser(null);
                // setLoading(false);
            }
        })
    }, [currentUser]);

    return (
        <General title="Agent">
             <AgentContent currentUser={currentUser} activeAgent={activeAgent} loading={loading} />
        </General>
    )
}

export default Agent