import React, { useState, useEffect } from 'react'
import { Typography } from "@material-tailwind/react";
import LoadingIndicator from '../LoadingIndicator';

export default function CalendarAgent({ loading }) {
    if (loading) {
        return (
            <div className='flex flex-col w-full h-full justify-center items-center'>
                <LoadingIndicator />
            </div>
        )
    }

    return (
        <div className='flex flex-col w-full h-full'>
            {/* Sticky header */}
            <div className="sticky top-0 z-10 bg-white pb-4">
                <Typography variant='h2' className="text-center">
                    Calendar
                </Typography>
            </div>

            {/* Scrollable content area */}
            <div className='flex-1 overflow-y-auto'>
                <div className='flex flex-col w-full gap-2 p-4'>
                    {/* Calendar content will go here */}
                    <div className='flex flex-col w-full p-4 border-2'>
                        <Typography variant="h6" className='text-gray-500'>
                            No calendar events yet. Add an event to get started!
                        </Typography>
                    </div>
                </div>
            </div>

            {/* Optional: Sticky footer area */}
            <div className="sticky bottom-0 left-0 right-0 bg-white border-t-2 p-4">
                {/* Calendar controls will go here */}
            </div>
        </div>
    );
}