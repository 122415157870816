import {
    Button,
    Checkbox,
    Typography,
    Input,
    Card,
    List,
    ListItem,
    ListItemPrefix
} from "@material-tailwind/react";
import { v4 as uuidv4 } from 'uuid';

function QuestionResponseSingleCheck(props) {
    const { quizId, question, answers } = props;

    return (
        <Card className='w-5/6 mt-6 md:m-6 p-2'>
            <Typography variant="h4" color="blue-gray">
                {question}
            </Typography>
            <List>
                {answers?.map((answer, index) => {
                    const uniqueId = `${index}-${quizId}-${answer.answerId}-${uuidv4()}`;
                    return <ListItem className="p-0" key={`${index}-${quizId}-${answer.answerId}`}>
                        <label
                            htmlFor={uniqueId}
                            className="flex w-full cursor-pointer items-center px-3 py-2"
                        >
                            <ListItemPrefix className="mr-3">
                                <Checkbox
                                    id={uniqueId}
                                    key={answer.answerId + '-' + quizId}
                                    ripple={false}
                                    className="hover:before:opacity-0"
                                    containerProps={{
                                        className: "p-0",
                                    }}
                                />
                            </ListItemPrefix>
                            <Typography color="blue-gray" className="font-medium">
                                {answer.answer}
                            </Typography>
                        </label>
                    </ListItem> 
                })}
            </List>
        </Card>
    )
}

export default QuestionResponseSingleCheck