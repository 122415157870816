import React, { useState } from 'react';
import { Typography } from "@material-tailwind/react";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { formatDistanceToNow } from 'date-fns';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { useMap } from 'react-leaflet';
import { addDoc, collection, Timestamp } from 'firebase/firestore';
import { db } from '../../FirebaseConfig';  // Adjust the path based on your file structure
import { auth } from '../../FirebaseConfig';
import { FIRESTORE_PATHS, getCollectionPath } from '../../config/firestore';

// Fix Leaflet default marker icon issue
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

function ChangeView({ center, zoom }) {
    const map = useMap();
    map.setView(center, zoom);
    return null;
}

export default function MapSection({ mapPositions, engineId, onPositionAdded }) {
    const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
    const [isMapModalOpen, setIsMapModalOpen] = useState(false);
    const [selectedPosition, setSelectedPosition] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [searchMapCenter, setSearchMapCenter] = useState([37.7749, -122.4194]);
    const [searchMapZoom, setSearchMapZoom] = useState(13);
    const [selectedSearchResult, setSelectedSearchResult] = useState(null);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [newLocation, setNewLocation] = useState(null);
    const [locationTitle, setLocationTitle] = useState('');
    const [locationNotes, setLocationNotes] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoadingLocation, setIsLoadingLocation] = useState(false);

    const provider = new OpenStreetMapProvider();

    const defaultCenter = [37.7749, -122.4194]; // Default to San Francisco coordinates

    // Sort positions in reverse chronological order
    const sortedPositions = Array.isArray(mapPositions) 
        ? [...mapPositions].sort((a, b) => {
            // Assuming there's a timestamp field, if not you'll need to add one
            return new Date(b.timestamp) - new Date(a.timestamp);
        })
        : [];

    const handleLocationClick = (position) => {
        if (!auth.currentUser) {
            alert('Please sign in to view location details');
            return;
        }
        setSelectedPosition(position);
        setIsMapModalOpen(true);
    };

    const formatTimestamp = (timestamp) => {
        if (!timestamp) return 'Date unknown';
        try {
            // Convert Firestore timestamp to JS Date
            const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp);
            // Check if date is valid
            if (isNaN(date.getTime())) {
                return 'Invalid date';
            }
            return {
                formatted: date.toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit'
                }),
                timeAgo: formatDistanceToNow(date, { addSuffix: true })
            };
        } catch (error) {
            console.error('Error formatting timestamp:', error);
            return 'Invalid date';
        }
    };

    const handleSearch = async () => {
        if (!auth.currentUser) {
            alert('Please sign in to search locations');
            return;
        }
        if (!searchTerm) return;
        
        try {
            const results = await provider.search({ query: searchTerm });
            setSearchResults(results);
            if (results.length > 0) {
                setSearchMapCenter([results[0].y, results[0].x]);
                setSearchMapZoom(16);
            }
        } catch (error) {
            console.error('Error searching location:', error);
        }
    };

    const handleMarkerClick = (result) => {
        setNewLocation({
            latitude: result.y,
            longitude: result.x,
            address: result.label
        });
        setLocationTitle(result.label);
        setIsCreateModalOpen(true);
    };

    const handleCreateLocation = async () => {
        if (!auth.currentUser?.uid || !engineId) {
            console.error('Missing required data:', { user: !!auth.currentUser, engineId: !!engineId });
            alert('Please sign in to save locations');
            return;
        }

        setIsSubmitting(true);
        try {
            const positionData = {
                title: locationTitle,
                notes: locationNotes,
                latitude: newLocation.latitude,
                longitude: newLocation.longitude,
                address: newLocation.address,
                timestamp: Timestamp.now(),
                userId: auth.currentUser.uid
            };

            // Ensure all required fields are present and valid before saving
            if (!locationTitle.trim() || 
                !Number.isFinite(newLocation.latitude) || 
                !Number.isFinite(newLocation.longitude)) {
                throw new Error('Missing or invalid required fields');
            }

            await addDoc(
                collection(db, getCollectionPath(FIRESTORE_PATHS.ENGINE_POSITIONS(auth.currentUser.uid, engineId))),
                positionData
            );
            
            // Reset form and close modals
            setIsCreateModalOpen(false);
            setIsSearchModalOpen(false);
            setLocationTitle('');
            setLocationNotes('');
            setNewLocation(null);
            setSelectedSearchResult(null);
            setSearchResults([]);
            setSearchTerm('');

            // Call the callback to refresh positions
            if (onPositionAdded) {
                onPositionAdded();
            }
        } catch (error) {
            console.error('Error creating position:', error);
            alert('Failed to save location. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleGetMyLocation = () => {
        setIsLoadingLocation(true);
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
                async (position) => {
                    try {
                        const { latitude, longitude } = position.coords;
                        const response = await fetch(
                            `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
                        );
                        const data = await response.json();
                        const address = data.display_name;
                        
                        // Update map center and create new location
                        setSearchMapCenter([latitude, longitude]);
                        setSearchMapZoom(16);
                        handleMarkerClick({
                            y: latitude,
                            x: longitude,
                            label: address
                        });
                    } catch (error) {
                        console.error("Error getting location:", error);
                        alert("Failed to get location details. Please try again.");
                    } finally {
                        setIsLoadingLocation(false);
                    }
                },
                (error) => {
                    console.error("Error getting location:", error);
                    alert("Unable to get your location. Please ensure location permissions are enabled.");
                    setIsLoadingLocation(false);
                }
            );
        } else {
            alert("Geolocation is not supported by your browser");
            setIsLoadingLocation(false);
        }
    };

    return (
        <div className='flex flex-col w-full h-full'>
            <div className="sticky top-0 z-40 bg-white pb-4">
                <button
                    className="w-full bg-black hover:bg-gray-800 text-white font-bold py-2 px-3 rounded text-sm sm:text-base"
                    onClick={() => setIsSearchModalOpen(true)}
                >
                    + Add Location
                </button>
            </div>

            <div className="flex-1 overflow-y-auto pb-20 md:pb-4">
                {isSearchModalOpen && (
                    <div 
                        className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 p-2"
                        onClick={() => setIsSearchModalOpen(false)}
                    >
                        <div 
                            className="bg-white p-3 sm:p-6 rounded-lg w-full max-w-lg max-h-[90vh] overflow-y-auto"
                            onClick={(e) => e.stopPropagation()}
                        >
                            <h2 className="text-lg sm:text-xl font-bold mb-3">Search Location</h2>
                            <div className="flex flex-col sm:flex-row gap-2 mb-3">
                                <input
                                    type="text"
                                    placeholder="Enter address or location"
                                    className="border p-2 flex-1"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
                                />
                                <button
                                    className="bg-black hover:bg-gray-800 text-white font-bold py-2 px-4 rounded text-sm"
                                    onClick={handleSearch}
                                >
                                    Search
                                </button>
                                <button
                                    className="bg-gray-200 hover:bg-gray-300 px-3 py-2 rounded flex items-center gap-1"
                                    onClick={handleGetMyLocation}
                                    disabled={isLoadingLocation}
                                >
                                    {isLoadingLocation ? (
                                        <span className="animate-spin">🔄</span>
                                    ) : (
                                        <>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
                                            </svg>
                                            <span className="hidden sm:inline">Get Location</span>
                                        </>
                                    )}
                                </button>
                            </div>

                            <div className="h-[50vh] sm:h-96 mb-4">
                                <MapContainer
                                    center={searchMapCenter}
                                    zoom={searchMapZoom}
                                    style={{ height: '100%', width: '100%' }}
                                    touchZoom={true}
                                    dragging={true}
                                >
                                    <ChangeView center={searchMapCenter} zoom={searchMapZoom} />
                                    <TileLayer
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                    />
                                    {searchResults.map((result, index) => (
                                        <Marker
                                            key={index}
                                            position={[result.y, result.x]}
                                            eventHandlers={{
                                                click: () => setSelectedSearchResult(result)
                                            }}
                                        >
                                            <Popup>
                                                <div className="flex flex-col gap-2">
                                                    <div className="font-medium">{result.label}</div>
                                                    <button
                                                        className="bg-black hover:bg-gray-800 text-white font-bold py-1 px-2 rounded text-sm"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleMarkerClick(result);
                                                        }}
                                                    >
                                                        Save Location
                                                    </button>
                                                </div>
                                            </Popup>
                                        </Marker>
                                    ))}
                                </MapContainer>
                            </div>

                            {searchResults.length > 0 && (
                                <div className="mb-4 max-h-40 overflow-y-auto">
                                    {searchResults.map((result, index) => (
                                        <div
                                            key={index}
                                            className="p-2 hover:bg-gray-100 flex justify-between items-center"
                                        >
                                            <div 
                                                className="cursor-pointer flex-grow"
                                                onClick={() => {
                                                    setSearchMapCenter([result.y, result.x]);
                                                    setSearchMapZoom(16);
                                                    setSelectedSearchResult(result);
                                                }}
                                            >
                                                {result.label}
                                            </div>
                                            <button
                                                className="ml-2 bg-black hover:bg-gray-800 text-white font-bold py-1 px-2 rounded text-sm"
                                                onClick={() => handleMarkerClick(result)}
                                            >
                                                Save
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            )}

                            <div className="flex justify-end">
                                <button
                                    className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded"
                                    onClick={() => {
                                        setIsSearchModalOpen(false);
                                        setSearchResults([]);
                                        setSearchTerm('');
                                    }}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {isMapModalOpen && selectedPosition && (
                    <div 
                        className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 p-2"
                        onClick={() => setIsMapModalOpen(false)}
                    >
                        <div 
                            className="bg-white p-3 sm:p-6 rounded-lg w-full max-w-lg max-h-[90vh] overflow-y-auto"
                            onClick={(e) => e.stopPropagation()}
                        >
                            <div className="flex justify-between items-center mb-3">
                                <h2 className="text-lg sm:text-xl font-bold">{selectedPosition.title || 'Unnamed Location'}</h2>
                                <button
                                    className="text-gray-500 hover:text-gray-700"
                                    onClick={() => setIsMapModalOpen(false)}
                                >
                                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            </div>
                            
                            <div className="h-[40vh] sm:h-64 mb-3 rounded overflow-hidden">
                                <MapContainer
                                    center={[
                                        selectedPosition.latitude || defaultCenter[0],
                                        selectedPosition.longitude || defaultCenter[1]
                                    ]}
                                    zoom={13}
                                    style={{ height: '100%', width: '100%' }}
                                    touchZoom={true}
                                    dragging={true}
                                >
                                    <TileLayer
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                    />
                                    <Marker
                                        position={[selectedPosition.latitude, selectedPosition.longitude]}
                                        eventHandlers={{
                                            click: (e) => {
                                                e.originalEvent.stopPropagation();
                                            }
                                        }}
                                    >
                                        <Popup>
                                            <div className="font-medium">
                                                {selectedPosition.title || 'Unnamed Location'}
                                            </div>
                                            {selectedPosition.address && (
                                                <div className="text-sm text-gray-600 mt-1">
                                                    {selectedPosition.address}
                                                </div>
                                            )}
                                        </Popup>
                                    </Marker>
                                </MapContainer>
                            </div>

                            <div className="space-y-2">
                                <div className="text-sm text-gray-600">
                                    {selectedPosition.latitude && selectedPosition.longitude
                                        ? `${Number(selectedPosition.latitude).toFixed(6)}°, ${Number(selectedPosition.longitude).toFixed(6)}°`
                                        : 'Coordinates not available'}
                                </div>
                                <div>
                                    <Typography variant="small" className="text-gray-700 font-medium">Notes:</Typography>
                                    <Typography variant="small" className="text-gray-600">
                                        {selectedPosition.notes || 'No location notes available'}
                                    </Typography>
                                </div>
                                {selectedPosition.photoUrl && (
                                    <div>
                                        <img 
                                            src={selectedPosition.photoUrl} 
                                            alt={selectedPosition.name} 
                                            className="max-h-48 object-cover rounded"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}

                {isCreateModalOpen && newLocation && (
                    <div 
                        className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 p-2"
                        onClick={() => {
                            setIsCreateModalOpen(false);
                            setLocationTitle('');
                            setLocationNotes('');
                            setNewLocation(null);
                        }}
                    >
                        <div 
                            className="bg-white p-3 sm:p-6 rounded-lg w-full max-w-lg max-h-[90vh] overflow-y-auto"
                            onClick={(e) => e.stopPropagation()}
                        >
                            <h2 className="text-lg sm:text-xl font-bold mb-3">Create New Location</h2>
                            
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                    Title
                                </label>
                                <input
                                    type="text"
                                    className="w-full border rounded-md p-2"
                                    value={locationTitle}
                                    onChange={(e) => setLocationTitle(e.target.value)}
                                    placeholder="Enter location title"
                                />
                            </div>

                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                    Notes
                                </label>
                                <textarea
                                    className="w-full border rounded-md p-2"
                                    value={locationNotes}
                                    onChange={(e) => setLocationNotes(e.target.value)}
                                    placeholder="Enter location notes"
                                    rows={3}
                                />
                            </div>

                            <div className="text-sm text-gray-600 mb-4">
                                Location: {newLocation.address}
                                <br />
                                Coordinates: {newLocation.latitude.toFixed(6)}°, {newLocation.longitude.toFixed(6)}°
                            </div>

                            <div className="flex flex-col-reverse sm:flex-row justify-end gap-2">
                                <button
                                    className="w-full sm:w-auto bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded text-sm"
                                    onClick={() => {
                                        setIsCreateModalOpen(false);
                                        setLocationTitle('');
                                        setLocationNotes('');
                                        setNewLocation(null);
                                    }}
                                    disabled={isSubmitting}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="w-full sm:w-auto bg-black hover:bg-gray-800 text-white font-bold py-2 px-4 rounded disabled:bg-gray-400 text-sm"
                                    onClick={handleCreateLocation}
                                    disabled={isSubmitting || !locationTitle.trim()}
                                >
                                    {isSubmitting ? 'Creating...' : 'Create Location'}
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                <div className='flex flex-col w-full'>
                    {!Array.isArray(mapPositions) || mapPositions.length === 0 ? (
                        <div className='flex flex-col w-full sm:w-full p-3 sm:p-4 border-2 mb-2'>
                            <Typography variant="h6" className='text-gray-500 mb-2'>
                                No locations saved yet
                            </Typography>
                            <Typography variant="small" className='text-gray-600'>
                                Click the "Search Location" button above to add your first location to the map.
                            </Typography>
                        </div>
                    ) : (
                        sortedPositions.map((position, index) => (
                            <div 
                                className='flex flex-col w-full sm:w-full p-3 sm:p-4 border-2 mb-2 cursor-pointer hover:bg-gray-50' 
                                key={`map-position-${position?.id || index}`}
                                onClick={() => handleLocationClick(position)}
                            >
                                <div className='flex items-center gap-2 mb-2'>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-600" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
                                    </svg>
                                    <Typography variant="h6" className="font-medium">
                                        {position?.title || 'Unnamed Location'}
                                    </Typography>
                                </div>
                                <div className='text-sm text-gray-600 mb-2'>
                                    {position?.latitude && position?.longitude ? (
                                        `${Number(position.latitude).toFixed(6)}°, ${Number(position.longitude).toFixed(6)}°`
                                    ) : 'Coordinates not available'}
                                </div>
                                <div className='text-sm text-gray-500 mb-2'>
                                    {position?.timestamp ? (
                                        (() => {
                                            const timeInfo = formatTimestamp(position.timestamp);
                                            return typeof timeInfo === 'string' ? (
                                                timeInfo  // Display error message if returned
                                            ) : (
                                                <>
                                                    Created: {timeInfo.formatted}
                                                    <span className='ml-2'>({timeInfo.timeAgo})</span>
                                                </>
                                            );
                                        })()
                                    ) : 'Date unknown'}
                                </div>
                                <div className='mt-2'>
                                    <Typography variant="small" className="text-gray-700 font-medium">Notes:</Typography>
                                    <Typography variant="small" className="text-gray-600">
                                        {position?.notes || 'No location notes available'}
                                    </Typography>
                                </div>
                            </div>
                        ))
                    )}
                </div>
            </div>
        </div>
    );
}