import React, { useState, useEffect } from 'react';
import { Button, Typography, Input } from "@material-tailwind/react";
import Header from './Header';
import { Footer } from './Footer';
import { collection, addDoc } from "firebase/firestore";
import { db, auth } from '../FirebaseConfig';  // Update the path to your FirebaseConfig file if necessary

function Businesses() {
    const [business, setBusiness] = useState({
        name: '',
        phoneNumber: '',
        email: '',
        website: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        category: '',
        description: ''
    });

    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

    useEffect(() => {
        // Monitor authentication state
        const unsubscribe = auth.onAuthStateChanged((user) => {
            setIsUserLoggedIn(!!user);
        });

        // Clean up subscription on unmount
        return () => unsubscribe();
    }, []);

    const handleChange = (e) => {
        setBusiness({ ...business, [e.target.name]: e.target.value });
    };

    const handleSubmit = async () => {
        if (!auth.currentUser) {
            alert("You must be logged in to add a business.");
            return;
        }

        try {
            const userId = auth.currentUser.uid;
            const docRef = await addDoc(collection(db, "recommengine/users/" + userId + "/data/businesses"), {
                ...business,
                userId: userId,
                createdAt: new Date().toISOString(),
            });

            if (docRef) {
                alert("Business added successfully!");
                setBusiness({
                    name: '',
                    phoneNumber: '',
                    email: '',
                    website: '',
                    address: '',
                    city: '',
                    state: '',
                    zip: '',
                    category: '',
                    description: ''
                });
            }
        } catch (e) {
            console.error("Error adding business: ", e);
            alert("Error adding business. Please try again.");
        }
    };

    return (
        <div className='flex flex-col min-h-screen'>
            <Header />
            
            {/* Updated Hero Section */}
            <section className="bg-gradient-to-b from-white via-blue-50 to-white py-16">
                <div className='max-w-7xl mx-auto px-4'>
                    <Typography variant='h1' className='text-center text-gray-800 font-bold text-5xl md:text-6xl lg:text-7xl tracking-tight leading-none mb-8'>
                        <span className="bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
                            Add Your Business
                        </span>
                    </Typography>

                    {/* Form Section */}
                    <div className='max-w-3xl mx-auto bg-white p-8 rounded-xl shadow-md'>
                        <div className='space-y-4'>
                            <Input 
                                label="Name" 
                                className="w-full" 
                                name="name" 
                                value={business.name} 
                                onChange={handleChange} 
                            />
                            <Input 
                                label="Phone Number" 
                                className="w-full" 
                                name="phoneNumber" 
                                value={business.phoneNumber} 
                                onChange={handleChange} 
                            />
                            <Input 
                                label="Email" 
                                className="w-full" 
                                name="email" 
                                value={business.email} 
                                onChange={handleChange} 
                            />
                            <Input 
                                label="Website" 
                                className="w-full" 
                                name="website" 
                                value={business.website} 
                                onChange={handleChange} 
                            />
                            <Input 
                                label="Address" 
                                className="w-full" 
                                name="address" 
                                value={business.address} 
                                onChange={handleChange} 
                            />
                            <Input 
                                label="City" 
                                className="w-full" 
                                name="city" 
                                value={business.city} 
                                onChange={handleChange} 
                            />
                            <Input 
                                label="State" 
                                className="w-full" 
                                name="state" 
                                value={business.state} 
                                onChange={handleChange} 
                            />
                            <Input 
                                label="Zip" 
                                className="w-full" 
                                name="zip" 
                                value={business.zip} 
                                onChange={handleChange} 
                            />
                            <Input 
                                label="Category" 
                                className="w-full" 
                                name="category" 
                                value={business.category} 
                                onChange={handleChange} 
                            />
                            <Input 
                                label="Description" 
                                className="w-full" 
                                name="description" 
                                value={business.description} 
                                onChange={handleChange} 
                            />
                        </div>

                        {!isUserLoggedIn && (
                            <Typography color="red" className='mt-4 text-center'>
                                You must be logged in to add a business.
                            </Typography>
                        )}

                        <div className="mt-8 text-center">
                            <Button 
                                className='bg-gradient-to-r from-blue-500 to-purple-500 text-white font-bold py-3 px-8 rounded-full hover:shadow-lg transform hover:scale-105 transition-all duration-300'
                                onClick={handleSubmit} 
                                disabled={!isUserLoggedIn}
                            >
                                Confirm business information
                            </Button>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    );
}

export default Businesses;