import React, { useState, useEffect } from 'react';
import General from '../layouts/General';
import StripeConnectForm from './StripeConnectForm';
import { getDoc, doc } from "firebase/firestore";
import { db, auth } from '../FirebaseConfig';

function Settings() {
  const [currentUser, setCurrentUser] = useState(null);
  const [stripeAccount, setStripeAccount] = useState(null);

  const getStripeAccount = async (userId) => {
    const configDoc = await getDoc(doc(db, "recommengine", "users", userId, "config"));
    return configDoc.data()?.stripe || null;
  }

  useEffect(() => {
    auth.onAuthStateChanged(user => {
      if (user) {
        setCurrentUser(user);
        getStripeAccount(user.uid).then((account) => {
          setStripeAccount(account);
        });
      } else {
        setCurrentUser(null);
      }
    });
  }, []);

  return (
    <General title="Settings">
        <StripeConnectForm 
            currentUser={currentUser} 
            stripeAccount={stripeAccount} 
            onRefresh={() => getStripeAccount(currentUser.uid).then((account) => {
              setStripeAccount(account);
            })}
        />
    </General>
  );
}

export default Settings; 