import React, { useEffect, useRef, useState } from 'react';
import { PlayCircleIcon, PauseCircleIcon, ArrowPathIcon } from "@heroicons/react/24/solid";
import { getStorage, ref as storageRef, getDownloadURL } from "firebase/storage";
import { getFunctions, httpsCallable } from 'firebase/functions';
import { app, firebaseConfig } from '../../FirebaseConfig';
import { Dialog, DialogHeader, DialogBody, DialogFooter, Button } from "@material-tailwind/react";

export default function SpeechControl({ text, currentUser, engineId }) {
    const [isPlaying, setIsPlaying] = useState(false);
    const [audioUrl, setAudioUrl] = useState(null);
    const audioRef = useRef(new Audio());
    const [error, setError] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleTextToSpeech = async () => {
        if (!text || !currentUser) return;
        setIsLoading(true);

        try {
            const functions = getFunctions(app);
            const generateSpeech = httpsCallable(functions, 'generateSpeech');
            
            // console.log('Making request with:', {
            //     text: text,
            //     userId: currentUser.uid,
            //     engineId: engineId
            // });
            
            const result = await generateSpeech({
                text: text,
                userId: currentUser.uid,
                engineId: engineId
            });
            
            console.log('Received response:', result);

            const storage = getStorage(app);
            
            console.log('Constructing audio path with:', {
                userId: currentUser.uid,
                engineId: engineId,
                filename: result.data.filename
            });
            const audioPath = `recordings/${currentUser.uid}/${engineId}/${result.data.filename}`;
            console.log('Constructed audio path:', audioPath);

            const audioFileRef = storageRef(storage, audioPath);
            console.log('Created storage reference:', audioFileRef);

            try {
                const url = await getDownloadURL(audioFileRef);
                console.log('Successfully retrieved download URL:', url);
                setAudioUrl(url);
                playAudio(url);
            } catch (storageError) {
                console.error('Error getting download URL:', storageError);
                throw storageError; // Re-throw to be caught by outer catch block
            }
        } catch (error) {
            console.error("Error generating speech:", error);
            const errorMessage = error.message || "An error occurred while generating speech";
            setError(errorMessage);
            setOpenDialog(true);
        } finally {
            setIsLoading(false);
        }
    };

    const playAudio = (url) => {
        audioRef.current.src = url;
        audioRef.current.play();
        setIsPlaying(true);
    };

    const toggleAudio = () => {
        if (isPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    useEffect(() => {
        audioRef.current.onended = () => setIsPlaying(false);
        return () => {
            audioRef.current.pause();
            audioRef.current = new Audio();
        };
    }, []);

    return (
        <>
            <button
                onClick={audioUrl ? toggleAudio : handleTextToSpeech}
                className="p-2 rounded-full hover:bg-gray-100"
                disabled={isLoading}
            >
                {isLoading ? (
                    <ArrowPathIcon className="h-6 w-6 text-military-green-600 animate-spin" />
                ) : isPlaying ? (
                    <PauseCircleIcon className="h-6 w-6 text-military-green-600" />
                ) : (
                    <PlayCircleIcon className="h-6 w-6 text-military-green-600" />
                )}
            </button>

            <Dialog open={openDialog} handler={() => setOpenDialog(false)}>
                <DialogHeader>Error</DialogHeader>
                <DialogBody>
                    {error || ""}
                </DialogBody>
                <DialogFooter>
                    <Button 
                        variant="text" 
                        color="red" 
                        onClick={() => setOpenDialog(false)}
                        className="mr-1"
                    >
                        Close
                    </Button>
                </DialogFooter>
            </Dialog>
        </>
    );
} 