

function QuestionResponseCode(props) {
    const { question } = props;

    return (
        <div>
            <div className="question-response-code">
                <pre>
                    <code>
                        {question.response}
                    </code>
                </pre>
            </div>
        </div>
    )
}

export default QuestionResponseCode