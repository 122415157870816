import React, { useEffect, useState } from 'react'
import { getFirestore, collection, query, getDocs } from "firebase/firestore";
import General from '../../layouts/General';
import InvoicesContent from './InvoicesContent';
import { db, auth } from '../../FirebaseConfig'

const getMyInvoices = async (userId) => {
    try {
        const querySnapshot = await getDocs(
            query(collection(db, "recommengine/users/"+ userId +"/data/invoices"))
        );

        const invoices = [];

        querySnapshot.forEach((doc) => {
            const data = doc.data();
            invoices.push({ 
                id: doc.id,
                invoiceNumber: data.invoiceNumber || 'No Number',
                ...data,
                status: data.status || 'pending',
                date: data.date ? new Date(data.date).toLocaleDateString() : 'N/A'
            });
        });

        return invoices.sort((a, b) => {
            if (a.date === b.date) {
                return a.status.localeCompare(b.status);
            }
            return new Date(b.date) - new Date(a.date);
        });
    } catch (error) {
        console.error('Error fetching invoices:', error);
        return [];
    }
}

export default function Invoices() {
    const [myInvoices, setMyInvoices] = useState([]);
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async user => {
            try {
                if (user) {
                    setCurrentUser(user);
                    const invoices = await getMyInvoices(user.uid);
                    setMyInvoices(invoices);
                } else {
                    setCurrentUser(null);
                    setMyInvoices([]);
                }
            } catch (error) {
                setError(error.message);
                console.error('Error in auth state change:', error);
            } finally {
                setLoading(false);
            }
        });

        return () => unsubscribe();
    }, []);

    if (loading) return <General title="Invoices"><div>Loading...</div></General>;
    if (error) return <General title="Invoices"><div>Error: {error}</div></General>;

    return (
        <General title={`Invoices (${myInvoices.length})`}>
            <InvoicesContent 
                currentUser={currentUser} 
                myInvoices={myInvoices} 
            />
        </General>
    );
}