import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { collection, addDoc, doc, getDoc, setDoc, getDocs, query, where, runTransaction } from "firebase/firestore";
import { db, auth } from '../../FirebaseConfig';
import General from '../../layouts/General';
import RegularButton from '../buttons/RegularButton';
import { Typography, Input, Button } from "@material-tailwind/react";
import CustomerSearch from '../customers/CustomerSearch';

const createRequest = async (userId, requestData) => {
  try {
    const newRequestId = await runTransaction(db, async (transaction) => {
      const configRef = doc(db, 'recommengine', 'users', userId, 'config');
      const configDoc = await transaction.get(configRef);
      
      let nextRequestNumber = 1;
      if (configDoc.exists()) {
        nextRequestNumber = (configDoc.data().lastRequestNumber || 0) + 1;
      }

      transaction.set(configRef, { lastRequestNumber: nextRequestNumber }, { merge: true });

      const requestRef = doc(collection(db, 'recommengine', 'users', userId, 'data', 'requests'));
      const formattedRequestNumber = `REQ-${String(nextRequestNumber).padStart(6, '0')}`;
      
      transaction.set(requestRef, {
        ...requestData,
        requestNumber: formattedRequestNumber,
        date: new Date().toISOString(),
      });

      return requestRef.id;
    });

    return newRequestId;
  } catch (error) {
    console.error("Error adding request: ", error);
    throw error;
  }
};

const requestTypes = [
  "Support",
  "Feature Request",
  "Bug Report",
  "Other"
];

const priorityLevels = [
  "Low",
  "Medium",
  "High",
  "Urgent"
];

const updateRequest = async (userId, requestId, requestData) => {
  try {
    await setDoc(
      doc(db, `recommengine/users/${userId}/data/requests`, requestId),
      {
        ...requestData,
        lastUpdated: new Date().toISOString(),
      },
      { merge: true }
    );
    return requestId;
  } catch (error) {
    console.error("Error updating request: ", error);
    throw error;
  }
};

const CreateRequest = () => {
  const { customerId, id: requestId } = useParams();
  const [isEditing, setIsEditing] = useState(false);
  const [request, setRequest] = useState({
    customerName: '',
    customerId: customerId || '',
    requestType: '',
    details: '',
    priority: '',
    email: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    country: 'United States',
    requestNumber: '',
  });
  const [customerInfo, setCustomerInfo] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user);
      setLoading(false);
    });

    const fetchCustomerDetails = async () => {
      if (customerId && currentUser) {
        try {
          const customerRef = doc(db, `recommengine/users/${currentUser.uid}/data/customers`, customerId);
          const customerSnap = await getDoc(customerRef);
          if (customerSnap.exists()) {
            const customerData = customerSnap.data();
            setCustomerInfo(customerData);
            setRequest(prevRequest => ({
              ...prevRequest,
              customerId: customerId,
              customerName: customerData.name,
              email: customerData.email || '',
              address: customerData.address || '',
              city: customerData.city || '',
              state: customerData.state || '',
              zipCode: customerData.zipCode || '',
              country: customerData.country || 'United States',
            }));
          }
        } catch (error) {
          console.error("Error fetching customer details: ", error);
        }
      }
    };

    const fetchRequest = async () => {
      if (requestId && currentUser) {
        setIsEditing(true);
        try {
          const requestRef = doc(db, `recommengine/users/${currentUser.uid}/data/requests`, requestId);
          const requestSnap = await getDoc(requestRef);
          if (requestSnap.exists()) {
            const requestData = requestSnap.data();
            setRequest(requestData);
          }
        } catch (error) {
          console.error("Error fetching request: ", error);
        }
      }
    };

    fetchCustomerDetails();
    fetchRequest();

    return () => unsubscribe();
  }, [customerId, currentUser, requestId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRequest(prevRequest => ({
      ...prevRequest,
      [name]: value
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    
    if (!request.customerName.trim()) {
      newErrors.customerName = 'Customer name is required';
    }
    
    if (!request.email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(request.email)) {
      newErrors.email = 'Please enter a valid email';
    }
    
    if (!request.requestType) {
      newErrors.requestType = 'Request type is required';
    }
    
    if (!request.priority) {
      newErrors.priority = 'Priority is required';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!currentUser) {
      console.error('No user logged in');
      return;
    }

    if (!validateForm()) {
      return;
    }

    try {
      if (isEditing) {
        await updateRequest(currentUser.uid, requestId, request);
      } else {
        await createRequest(currentUser.uid, request);
      }
      navigate('/requests');
    } catch (error) {
      console.error('Error saving request:', error);
    }
  };

  const handleCustomerSelect = (customer) => {
    setRequest(prev => ({
      ...prev,
      customerId: customer.id,
      customerName: `${customer.firstName} ${customer.lastName}`,
      email: customer.email || '',
      address: customer.address || '',
      city: customer.city || '',
      state: customer.state || '',
      zipCode: customer.zipCode || '',
      country: customer.country || 'United States',
    }));
    setCustomerInfo(customer);
  };

  const handleClearCustomer = () => {
    setCustomerInfo(null);
    setRequest(prev => ({
      ...prev,
      customerId: '',
      customerName: '',
      email: '',
      address: '',
      city: '',
      state: '',
      zipCode: '',
      country: 'United States',
    }));
  };

  const customerSearchSection = (
    <CustomerSearch 
      onCustomerSelect={handleCustomerSelect}
      currentUser={currentUser}
    />
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <General title={isEditing ? "Edit Request" : "Create New Request"}>
      <div className='flex flex-col w-full px-2 sm:px-4 lg:px-6 max-w-[calc(100vw-3rem)]'>
        <div className='bg-white shadow-md rounded px-2 sm:px-4 lg:px-6 pt-4 sm:pt-6 pb-6 sm:pb-8 mb-4'>
          <form onSubmit={handleSubmit}>
            <div className='flex flex-col 2xl:grid 2xl:grid-cols-2 2xl:gap-x-4'>
              {/* Customer Information Section */}
              <div className='mb-6 lg:mb-0'>
                {request.requestNumber && (
                  <div className="p-3 bg-gray-50 rounded mb-4">
                    <Typography variant="small" className="font-medium">
                      Request Number: {request.requestNumber}
                    </Typography>
                  </div>
                )}
                <div>
                  <Typography variant='h6' color='blue-gray' className="text-sm font-medium text-gray-500 mb-2">
                    Customer Information
                  </Typography>
                  
                  {!customerId && !customerInfo && customerSearchSection}
                  
                  {(customerId || customerInfo) ? (
                    <div className="bg-gray-50 p-4 rounded">
                      <div className="flex justify-between items-start mb-2">
                        <Typography variant="h6" color="blue-gray" className="text-sm font-medium">
                          Selected Customer
                        </Typography>
                        <Button
                          size="sm"
                          variant="text"
                          color="red"
                          onClick={handleClearCustomer}
                          className="p-2"
                        >
                          Clear
                        </Button>
                      </div>
                      <p><strong>Name:</strong> {customerInfo.firstName} {customerInfo.lastName}</p>
                      <p><strong>Email:</strong> {customerInfo.email}</p>
                      <p><strong>Address:</strong> {customerInfo.address}</p>
                      <p><strong>City:</strong> {customerInfo.city}</p>
                      <p><strong>State:</strong> {customerInfo.state}</p>
                      <p><strong>Zip Code:</strong> {customerInfo.zipCode}</p>
                      <p><strong>Country:</strong> {customerInfo.country}</p>
                    </div>
                  ) : (
                    <div className="space-y-6">
                      <div className="space-y-4 sm:space-y-6">
                        <Input
                          size="lg"
                          label="Customer Name"
                          name="customerName"
                          value={request.customerName}
                          onChange={handleChange}
                          required
                          className="w-full"
                          error={errors.customerName}
                        />
                        {errors.customerName && (
                          <p className="text-red-500 text-xs">{errors.customerName}</p>
                        )}
                        <Input
                          size="lg"
                          label="Customer Email"
                          name="email"
                          type="email"
                          value={request.email}
                          onChange={handleChange}
                          required
                          className="w-full"
                          error={errors.email}
                        />
                        {errors.email && (
                          <p className="text-red-500 text-xs">{errors.email}</p>
                        )}
                        <Input
                          size="lg"
                          label="Street Address"
                          name="address"
                          value={request.address}
                          onChange={handleChange}
                          className="w-full"
                        />
                      </div>
                      
                      <div className="flex flex-col sm:flex-row gap-4 sm:gap-6">
                        <Input
                          size="lg"
                          label="City"
                          name="city"
                          value={request.city}
                          onChange={handleChange}
                          className="w-full"
                        />
                        <Input
                          size="lg"
                          label="State"
                          name="state"
                          value={request.state}
                          onChange={handleChange}
                          className="w-full"
                        />
                      </div>
                      
                      <div className="flex flex-col sm:flex-row gap-4 sm:gap-6">
                        <Input
                          size="lg"
                          label="Zip Code"
                          name="zipCode"
                          value={request.zipCode}
                          onChange={handleChange}
                          className="w-full"
                        />
                        <Input
                          size="lg"
                          label="Country"
                          name="country"
                          value={request.country}
                          onChange={handleChange}
                          className="w-full"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* Request Details Section */}
              <div className='mb-6 lg:mb-0'>
                <div>
                  <Typography variant='h6' color='blue-gray' className="text-sm font-medium text-gray-500 mb-2">
                    Request Details
                  </Typography>
                  <div className="space-y-4">
                    <select
                      className="w-full p-2 sm:p-3 border border-gray-300 rounded-lg focus:border-blue-500 text-sm sm:text-base"
                      name="requestType"
                      value={request.requestType}
                      onChange={handleChange}
                      required
                    >
                      <option value="">Select Request Type</option>
                      {requestTypes.map((type) => (
                        <option key={type} value={type.toLowerCase()}>
                          {type}
                        </option>
                      ))}
                    </select>

                    <select
                      className="w-full p-2 sm:p-3 border border-gray-300 rounded-lg focus:border-blue-500 text-sm sm:text-base"
                      name="priority"
                      value={request.priority}
                      onChange={handleChange}
                      required
                    >
                      <option value="">Select Priority</option>
                      {priorityLevels.map((level) => (
                        <option key={level} value={level.toLowerCase()}>
                          {level}
                        </option>
                      ))}
                    </select>

                    <textarea
                      className="w-full p-2 sm:p-3 border border-gray-300 rounded-lg focus:border-blue-500 min-h-[80px] sm:min-h-[100px] text-sm sm:text-base"
                      placeholder="Request Details"
                      name="details"
                      value={request.details}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col items-center gap-2 mt-6">
              <RegularButton 
                onClick={handleSubmit} 
                type="submit" 
                text={isEditing ? "Update Request" : "Create Request"}
                variant="black"
              />
              <button
                onClick={() => navigate('/requests')}
                className="text-gray-600 underline cursor-pointer p-1"
              >
                Back to Requests
              </button>
            </div>
          </form>
        </div>
      </div>
    </General>
  );
};

export default CreateRequest;

