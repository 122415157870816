import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Typography, Input, Button } from "@material-tailwind/react";
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  TypingIndicator,
} from '@chatscope/chat-ui-kit-react';
import { getFunctions, httpsCallable } from "firebase/functions";
import { app } from '../FirebaseConfig';
import SpeechControl from './engines/SpeechControl';

const ChatSidebar = ({ isOpen, onClose, currentUser }) => {
  const [inputText, setInputText] = useState('');
  const [apiKey, setApiKey] = useState(null);

  const [messages, setMessages] = useState([
    {
      message: "Hey there! I'm Rico, your business companion. How can I assist you today?",
      sentTime: "just now",
      sender: "Rico",
    },
  ]);
  const [isTyping, setIsTyping] = useState(false);

  const [messageCount, setMessageCount] = useState(() => {
    return parseInt(localStorage.getItem('chatMessageCount')) || 0;
  });

  const [lastResetDate, setLastResetDate] = useState(() => {
    return localStorage.getItem('chatLastResetDate') || new Date().toDateString();
  });

  const [latestResponse, setLatestResponse] = useState(null);

  const MESSAGE_LIMIT = 50;

  const fetchApiKey = useCallback(async () => {
    try {
      const functions = getFunctions(app);
      const getOpenAIConfig = httpsCallable(functions, 'getOpenAIConfig');
      const result = await getOpenAIConfig();
      if (result?.data?.apiKey) {
        setApiKey(result.data.apiKey);
      }
    } catch (error) {
      console.error("Error fetching OpenAI config:", error);
    }
  }, []);

  useEffect(() => {
    if (!apiKey) {
      fetchApiKey();
    }
  }, []);

  const checkAndResetDaily = () => {
    const today = new Date().toDateString();
    if (today !== lastResetDate) {
      setMessageCount(0);
      setLastResetDate(today);
      localStorage.setItem('chatMessageCount', '0');
      localStorage.setItem('chatLastResetDate', today);
    }
  };

  const handleSendRequest = async (message) => {
    if (!apiKey) {
      setMessages(prev => [...prev, {
        message: "Chat service is currently unavailable. Please try again later.",
        sender: "Rico",
      }]);
      return;
    }

    checkAndResetDaily();
    if (messageCount >= MESSAGE_LIMIT) {
      setMessages(prev => [...prev, {
        message: `You've reached your daily message limit of ${MESSAGE_LIMIT}. Please try again tomorrow.`,
        sender: "Rico",
      }]);
      return;
    }

    const newMessage = {
      message,
      direction: 'outgoing',
      sender: "user",
    };

    const newCount = messageCount + 1;
    setMessageCount(newCount);
    localStorage.setItem('chatMessageCount', newCount.toString());
    setMessages((prevMessages) => [...prevMessages, newMessage]);
    setIsTyping(true);

    try {
      const response = await processMessageToChatGPT([...messages, newMessage]);
      const content = response.choices[0]?.message?.content;
      if (content) {
        const chatGPTResponse = {
          message: content,
          sender: "Rico",
        };
        setMessages((prevMessages) => [...prevMessages, chatGPTResponse]);
        setLatestResponse(content);
      }
    } catch (error) {
      console.error("Error processing message:", error);
    } finally {
      setIsTyping(false);
    }
  };

  async function processMessageToChatGPT(chatMessages) {
    if (!apiKey) {
      throw new Error("API key is not available");
    }
    
    const apiMessages = chatMessages.map((messageObject) => {
      const role = messageObject.sender === "Rico" ? "assistant" : "user";
      return { role, content: messageObject.message };
    });

    const apiRequestBody = {
      "model": "gpt-3.5-turbo",
      "messages": [
        { role: "system", content: "I'm a business owner using ChatGPT for to organize my business" },
        ...apiMessages,
      ],
    };

    try {
      const response = await fetch("https://api.openai.com/v1/chat/completions", {
        method: "POST",
        headers: {
          "Authorization": "Bearer " + apiKey,
          "Content-Type": "application/json",
          "Origin": window.location.origin,
        },
        mode: 'cors',
        body: JSON.stringify(apiRequestBody),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return response.json();
    } catch (error) {
      console.error("Error calling OpenAI API:", error);
      throw error;
    }
  }

  const getLastRicoMessage = useMemo(() => {
    return messages.filter(m => m.sender === "Rico").slice(-1)[0]?.message || null;
  }, [messages]);

  const renderMessages = useCallback((messages) => {
    return messages.map((message, i) => (
      <Message 
        key={i}
        model={{
          ...message,
          direction: message.sender === "Rico" ? "incoming" : "outgoing",
          position: "normal"
        }}
      >
        {message.sender === "Rico" && (
          <Message.Footer>
            <SpeechControl 
              text={message.message}
              currentUser={currentUser}
              engineId="rico"
            />
          </Message.Footer>
        )}
      </Message>
    ));
  }, [currentUser]);

  const typingIndicator = useMemo(() => (
    isTyping ? <TypingIndicator content="Rico is typing" /> : null
  ), [isTyping]);

  return (
    <div className="flex flex-col h-[50vh] md:h-[90vh] w-full bg-white z-[1001]">
      <div className="p-4">
        <Typography variant="h6" className="uppercase tracking-wide text-gray-800 font-medium text-sm">
          <span className="text-blue-700">👋</span> Hey! Chat with Rico
        </Typography>
      </div>

      <MainContainer className="flex-1 overflow-hidden flex flex-col">
        <ChatContainer className="flex-1 relative">
          <MessageList 
            scrollBehavior="smooth" 
            typingIndicator={typingIndicator}
            className="pb-16"
          >
            {renderMessages(messages)}
          </MessageList>
          <MessageInput 
            placeholder="Send a Message" 
            onSend={handleSendRequest}
            attachButton={false}
          />
        </ChatContainer>
      </MainContainer>
    </div>
  );
};

export default React.memo(ChatSidebar);
