

function QuestionResponseImageSelect() {
    return (
        <div>
            <img src="" alt="" />
        </div>
    )
}

export default QuestionResponseImageSelect