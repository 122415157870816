import React, { useState, useEffect } from 'react';
import { auth } from '../FirebaseConfig';
import { updateProfile, updateEmail } from "firebase/auth";

import General from '../layouts/General';
import { Input, Typography, Dialog, DialogHeader, DialogBody, DialogFooter } from "@material-tailwind/react";
import RegularButton from './buttons/RegularButton';

const InfoField = ({ label, value }) => (
  <div className='space-y-1'>
    <Typography variant='small' color='blue-gray' className="font-medium">
      {label}
    </Typography>
    <Typography className="text-gray-700">
      {value || '-'}
    </Typography>
  </div>
);

function Profile() {
  const [user, setUser] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [displayName, setDisplayName] = useState('');
  const [email, setEmail] = useState('');
  const [errorDialog, setErrorDialog] = useState({ open: false, message: '' });

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      if (user) {
        setDisplayName(user.displayName || '');
        setEmail(user.email || '');
      }
    });

    return () => unsubscribe();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (displayName !== user.displayName) {
        await updateProfile(auth.currentUser, { displayName });
      }
      if (email !== user.email) {
        await updateEmail(auth.currentUser, email);
      }
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating profile:', error);
      setErrorDialog({ open: true, message: error.message });
    }
  };

  const handleCloseError = () => setErrorDialog({ open: false, message: '' });

  return (
    <General title="Profile">
      <div className='flex flex-col w-full px-4 sm:px-6'>
        <div className='bg-white shadow-md rounded px-4 sm:px-8 pt-6 pb-8 mb-4'>
          {user && (
            <div className="space-y-4">
              {isEditing ? (
                <form onSubmit={handleSubmit} className="grid grid-cols-1 gap-6">
                  <div className='space-y-4'>
                    <Typography variant='h6' color='blue-gray' className="text-sm font-medium text-gray-500 mb-2">
                      Personal Information
                    </Typography>
                    <Input
                      size="lg"
                      label="Name"
                      value={displayName}
                      onChange={(e) => setDisplayName(e.target.value)}
                    />
                    <Input
                      size="lg"
                      label="Email"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="md:col-span-2 flex flex-col items-center gap-2 mt-4">
                    <RegularButton
                      onClick={handleSubmit}
                      type="submit"
                      text="Save Changes"
                      variant="black"
                    />
                    <button
                      onClick={() => setIsEditing(false)}
                      className="text-gray-600 underline cursor-pointer p-1"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              ) : (
                <div className='space-y-4'>
                  <Typography variant='h6' color='blue-gray' className="text-sm font-medium text-gray-500 mb-2">
                    Personal Information
                  </Typography>
                  <div className="space-y-4">
                    <InfoField label="Name" value={user.displayName} />
                    <InfoField label="Email" value={user.email} />
                  </div>
                  <div className="md:col-span-2 flex flex-col items-center gap-2 mt-4">
                    <RegularButton
                      onClick={() => setIsEditing(true)}
                      text="Edit Profile"
                      variant="black"
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <Dialog open={errorDialog.open} handler={handleCloseError}>
        <DialogHeader>Error</DialogHeader>
        <DialogBody>
          Failed to update profile: {errorDialog.message}
        </DialogBody>
        <DialogFooter>
          <RegularButton
            onClick={handleCloseError}
            text="Close"
            variant="black"
          />
        </DialogFooter>
      </Dialog>
    </General>
  );
}

export default Profile; 