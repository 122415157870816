
import QuestionResponseCode from "./QuestionResponseCode";
import QuestionResponseImageSelect from "./QuestionResponseImageSelect";
import QuestionResponseMultiCheck from "./QuestionResponseMultiCheck";
import QuestionResponseSelect from "./QuestionResponseSelect";
import QuestionResponseSingleCheck from "./QuestionResponseSingleCheck";
import QuestionResponseText from "./QuestionResponseText";

function QuestionCard(props) {
    const { quizId, question, answers, type, isEditing } = props;

    // console.log(question)
    // console.log(answers)
    // console.log(type)

    if (isEditing) {
        return <QuestionResponseSingleCheck question={question} answers={answers} isEditing />;;
    }

    switch (type) {
        case "text":
            return <QuestionResponseText question={question} />;
        case "code":
            return <QuestionResponseCode question={question} />;
        case "select":
            return <QuestionResponseSelect question={question} />;
        case "image_select":
            return <QuestionResponseImageSelect question={question} />;
        case "single_choice":
            return <QuestionResponseSingleCheck quizId={quizId} question={question} answers={answers} />;
        case "multi_choice":
            return <QuestionResponseMultiCheck question={question} />;
        default:
            return <QuestionResponseText quizId={quizId} question={question} answers={answers} />;
     }
}

export default QuestionCard