import React, { useState } from 'react';
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from '../../FirebaseConfig';

const CustomerSearch = ({ onCustomerSelect, currentUser }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [showResults, setShowResults] = useState(false);

  const searchCustomers = async (search) => {
    if (!currentUser || search.length < 2) {
      setSearchResults([]);
      return;
    }

    try {
      const customersRef = collection(db, `recommengine/users/${currentUser.uid}/data/customers`);
      const q = query(
        customersRef,
        where('firstName', '>=', search),
        where('firstName', '<=', search + '\uf8ff')
      );
      const querySnapshot = await getDocs(q);
      const customers = [];
      querySnapshot.forEach((doc) => {
        customers.push({ id: doc.id, ...doc.data() });
      });
      setSearchResults(customers);
    } catch (error) {
      console.error("Error searching customers: ", error);
    }
  };

  const handleClear = () => {
    setSearchTerm('');
    setSearchResults([]);
    onCustomerSelect(null);
  };

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      marginBottom: '20px'
    }}>
      <label htmlFor="customerSearch" style={{
        marginBottom: '5px',
        fontWeight: 'bold'
      }}>Search Customer:</label>
      <div style={{ display: 'flex', gap: '10px' }}>
        <input
          type="text"
          id="customerSearch"
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            searchCustomers(e.target.value);
            setShowResults(true);
          }}
          placeholder="Start typing customer name..."
          style={{
            padding: '10px',
            border: '1px solid #ddd',
            borderRadius: '4px',
            flex: 1
          }}
        />
        <button
          onClick={handleClear}
          style={{
            padding: '10px',
            border: '1px solid #ddd',
            borderRadius: '4px',
            cursor: 'pointer',
            backgroundColor: '#f5f5f5'
          }}
        >
          Clear
        </button>
      </div>
      
      {showResults && searchTerm.length >= 2 && (
        <div style={{
          position: 'absolute',
          top: '100%',
          left: 0,
          right: 0,
          backgroundColor: 'white',
          border: '1px solid #ddd',
          borderRadius: '4px',
          zIndex: 1000,
          maxHeight: '200px',
          overflowY: 'auto'
        }}>
          {searchResults.length > 0 ? (
            searchResults.map((customer) => (
              <div
                key={customer.id}
                onClick={() => {
                  onCustomerSelect(customer);
                  setShowResults(false);
                  setSearchTerm('');
                }}
                style={{
                  padding: '10px',
                  cursor: 'pointer',
                  borderBottom: '1px solid #eee',
                  ':hover': {
                    backgroundColor: '#f5f5f5'
                  }
                }}
              >
                {customer.firstName} {customer.lastName} - {customer.email}
              </div>
            ))
          ) : (
            <div style={{
              padding: '10px',
              textAlign: 'center',
              color: '#666'
            }}>
              No customers found
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CustomerSearch; 