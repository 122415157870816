import React from 'react';

export function PreLaunchNotice() {
  return (
    <div className="text-center opacity-0 animate-[fadeIn_0.5s_ease-out_0.4s_forwards]">
      <div className="inline-block px-6 py-3 bg-white/80 backdrop-blur-sm border border-military-green-200 rounded-sm transform hover:scale-[1.02] transition-all duration-200 shadow-sm hover:shadow-md">
        <span className="text-sm text-military-green-700 font-medium">
          🚀 <span className="font-semibold">Pre-Launch Preview:</span> Join our early access program! Features and UI are actively being refined based on user feedback.
        </span>
      </div>
    </div>
  );
} 