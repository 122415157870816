
function QuestionResponseSelect({ question }) {
    const { options } = question;
    const { response } = question;

    return (
        <div>
            <select
                className="form-select"
                aria-label="Default select example"
                onChange={(e) => {
                    question.response = e.target.value;
                }}
            >
                {options.map((option, index) => (
                    <option
                        key={index}
                        value={option}
                    >
                        {option}
                    </option>
                ))}
            </select>
        </div>
    )
}

export default QuestionResponseSelect