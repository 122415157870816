import React, { useState, useEffect } from 'react'
import {
    // Button,
    // Checkbox,
    Typography,
    // Input,
    // List,
    // ListItem,
    // Card,
    // Carousel,
    // CardBody,
} from "@material-tailwind/react";

import LoadingIndicator from '../LoadingIndicator';

export default function MapAgent({ loading }) {

    // console.log('activeAgent: ', activeAgent);

    if (loading) {
        return (
            <div className='flex flex-col w-full h-full'>
                <LoadingIndicator />
            </div>
        )
    }

    return (
        <div className='flex flex-row md:flex-col mt-2 border-solid border-2 border-black w-[100%] p-1 text-center'>
            <Typography variant='h2'>
                Map
            </Typography>

            {/* Display map */}

        </div>
    );
}