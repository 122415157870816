import React, { useState, useEffect } from 'react'
import {
    Card,
    Input,
    Checkbox,
    Button,
    Typography,
  } from "@material-tailwind/react";

import QuestionCard from '../question/QuestionCard'
import LoadingIndicator from '../LoadingIndicator';

function Quiz(props) {
    const { quizId, questions, title } = props;
    const [loading, setLoading] = useState(true);
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        setLoading(false);
        console.log('questions: ', questions)
    }, []);

    if (loading) {
        return (
            <div className='flex flex-col w-full h-full'>
                <LoadingIndicator />
            </div>
        )
    }

    if (isEditing) {
        return (
            <div className='flex flex-col'>
                <div className='flex flex-row w-1/2 ml-6'>
                    {/* <div className='flex flex-row w-1/2 ml-6'>
                        <Input label="Quiz Title" value={title} onChange={(e) => setTitle(e.target.value)} />
                    </div> */}
                    <Button className="mt-6" fullWidth color="black" onClick={() => setIsEditing(false)}>
                    Save Questions
                    </Button>
                </div>
            </div>
        )
    } else {
        return (
            <div className='flex flex-col'>

                <div className='flex flex-col w-full ml-6 mt-6 text-center'>
                    <div>
                        <Typography variant='h1'>
                            {title}
                        </Typography>
                    </div>
                    <div>
                        <Typography variant='h6'>
                            {questions.length} Questions
                        </Typography>
                        </div>
                </div>


                {questions.map((question, index) => (
                    <QuestionCard
                        key={index + 'question' + quizId}
                        quizId={quizId}
                        question={question.question}
                        answers={question.answers}
                        type={question.type}
                    />
                ))}

                <div className='flex flex-row w-1/2 ml-6'>
                    <Button className="mt-2 mb-6" fullWidth color="black">
                    Submit
                    </Button>
                </div>
            </div>
        )
    }

}

export default Quiz