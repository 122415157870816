import { Typography } from "@material-tailwind/react";
import General from '../layouts/General';

function Contact() {
  return (
    <General>
      <div className='flex flex-col w-full h-full text-center'>
        <section className="bg-white py-16">
          <div className="max-w-4xl mx-auto px-4">
            {/* Contact content with consistent styling */}
            <div className="bg-white/80 backdrop-blur-sm border-2 border-military-green-200 rounded-sm p-8">
              <Typography variant="h6" className="text-military-green-700 mb-6">
                We'd love to hear from you.
              </Typography>
              
              <a 
                href="mailto:brian@bcpsolutionsllc.com" 
                className="text-military-green-600 hover:text-military-green-700 transition-colors inline-block break-words"
              >
                brian@bcpsolutionsllc.com
              </a>
            </div>
          </div>
        </section>
      </div>
    </General>
  );
}

export default Contact;