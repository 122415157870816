import React, { useEffect, useState } from 'react';

// import {
//     Button,
//     Checkbox,
//     Typography,
//     Input,
//     Card,
//     List,
//     ListItem,
//     ListItemPrefix
// } from "@material-tailwind/react";

import { getFirestore, collection, addDoc, query, where, getDocs } from "firebase/firestore";

// import Header from './Header';
// import { Footer } from './Footer';
// import recommengineFeatureDescriptions from './recommengineFeatureDescriptions';
// console.log(recommengineFeatureDescriptions);

// import NavigationLinks from './NavigationLinks'
// import LoadingIndicator from './LoadingIndicator';

import { db, auth } from '../FirebaseConfig'

import General from '../layouts/General';
import QuizContent from './QuizContent';
import RegularButton from './buttons/RegularButton';

function Quizzes() {
    const [loading, setLoading] = useState(true);
    const [currentUser, setCurrentUser] = useState(null);
    const [quizzes, setMyQuizzes] = useState([]);
    const [activeQuiz, setActiveQuiz] = useState(null);
    const [isEditing, setIsEditing] = useState(false);

    const getMyQuizzes = async (userId) => {
        const querySnapshot = await getDocs(
            query(collection(db, "recommengine/users/"+ userId +"/data/quizzes"))
        );
    
        const quizzes = [];
    
        querySnapshot.forEach((doc) => {
            // console.log('an app found: ' + doc.data().name);
            quizzes.push(doc.data());
        });
    
        console.log(quizzes);
        return quizzes;
    }

    // const quizzes = [
    //     {
    //         id: 1,
    //         title: "Quiz 1",
    //         questions: [
    //             {
    //                 question: "What do I want to prioritize?",
    //                 answers: [
    //                     {
    //                         answerId: "1", answer: "Personal Life",
    //                     },
    //                     {
    //                         answerId: "2", answer: "Work",
    //                     },
    //                     {
    //                         answerId: "3", answer: "School",
    //                     },
    //                     {
    //                         answerId: "4", answer: "Health",
    //                     },
    //                     {
    //                         answerId: "5", answer: "Relationships",
    //                     },
    //                     {
    //                         answerId: "6", answer: "Finances",
    //                     },
    //                 ],
    //                 type: "single_choice",
    //             },
    //             {
    //                 question: "How many miles do I want to run today?",
    //                 answers: [
    //                     {
    //                         answerId: "1", answer: "0",
    //                     },
    //                     {
    //                         answerId: "2", answer: "1-3",
    //                     },
    //                     {
    //                         answerId: "3", answer: "3-5",
    //                     },
    //                     {
    //                         answerId: "4", answer: "5-8",
    //                     },
    //                     {
    //                         answerId: "5", answer: "8-10",
    //                     },
    //                     {
    //                         answerId: "6", answer: "10+",
    //                     },
    //                 ],
    //                 type: "single_choice",
    //             },
    //             {
    //                 question: "Where do I want to sleep tonight",
    //                 answers: [
    //                     {
    //                         answerId: "1", answer: "My bed",
    //                     },
    //                     {
    //                         answerId: "2", answer: "In the cabin",
    //                     },
    //                     {
    //                         answerId: "3", answer: "In my van",
    //                     },
    //                 ],
    //                 type: "single_choice",
    //             },
    //         ],
    //     },
    //     {
    //         id: 2,
    //         title: "Quiz 2",
    //         questions: [
    //             {
    //                 question: "What do I want to prioritize?",
    //                 answers: [
    //                     {
    //                         answerId: "1", answer: "Personal Life",
    //                     },
    //                     {
    //                         answerId: "2", answer: "Work",
    //                     },
    //                     {
    //                         answerId: "3", answer: "School",
    //                     },
    //                     {
    //                         answerId: "4", answer: "Health",
    //                     },
    //                     {
    //                         answerId: "5", answer: "Relationships",
    //                     },
    //                     {
    //                         answerId: "6", answer: "Finances",
    //                     },
    //                 ],
    //                 type: "single_choice",
    //             },
    //             {
    //                 question: "How many miles do I want to run today?",
    //                 answers: [
    //                     {
    //                         answerId: "1", answer: "0",
    //                     },
    //                     {
    //                         answerId: "2", answer: "1-3",
    //                     },
    //                     {
    //                         answerId: "3", answer: "3-5",
    //                     },
    //                     {
    //                         answerId: "4", answer: "5-8",
    //                     },
    //                     {
    //                         answerId: "5", answer: "8-10",
    //                     },
    //                     {
    //                         answerId: "6", answer: "10+",
    //                     },
    //                 ],
    //                 type: "single_choice",
    //             },
    //             {
    //                 question: "Where do I want to sleep tonight",
    //                 answers: [
    //                     {
    //                         answerId: "1", answer: "My bed",
    //                     },
    //                     {
    //                         answerId: "2", answer: "In the cabin",
    //                     },
    //                     {
    //                         answerId: "3", answer: "In my van",
    //                     },
    //                 ],
    //                 type: "single_choice",
    //             },
    //             {
    //                 question: "How many miles do I want to drive today?",
    //                 answers: [
    //                     {
    //                         answerId: "1", answer: "0",
    //                     },
    //                     {
    //                         answerId: "2", answer: "1-3",
    //                     },
    //                     {
    //                         answerId: "3", answer: "3-5",
    //                     },
    //                     {
    //                         answerId: "4", answer: "5-8",
    //                     },
    //                     {
    //                         answerId: "5", answer: "8-10",
    //                     },
    //                     {
    //                         answerId: "6", answer: "10+",
    //                     },
    //                 ],
    //                 type: "single_choice",
    //             }
    //         ],
    //     }
    // ]

    useEffect(() => {
        auth.onAuthStateChanged(user => {
            if (user) {
                setCurrentUser(user);
                getMyQuizzes(user.uid).then((questions) => {
                    setMyQuizzes(questions);
                    setLoading(false);
                })
            } else {
                setCurrentUser(null);
                setLoading(false);
            }
        })        
    }, [currentUser])

    return (
        <General title="Quizzes">
            <RegularButton url={'/dashboard'} text="Back" />
            <QuizContent currentUser={currentUser} myQuizzes={quizzes} setActiveQuiz={setActiveQuiz} activeQuiz={activeQuiz} isEditing={isEditing} setIsEditing={setIsEditing} />
        </General>
    )
}

export default Quizzes;
