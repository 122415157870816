import React from 'react';
import { Button } from "@material-tailwind/react";

export function CTAButton({ onClick }) {
  return (
    <div className="mt-10 flex flex-col items-center justify-center opacity-0 animate-[fadeIn_0.5s_ease-out_0.6s_forwards]">
      <Button 
        onClick={onClick}
        className="w-full sm:w-auto bg-gradient-to-r from-military-green-500 to-slate-blue-500 flex items-center justify-center gap-2 transform hover:scale-[1.02] transition-all duration-200 shadow-lg hover:shadow-xl px-8 py-4"
        size="lg"
      >
        Create Your Free Account <span className="text-lg group-hover:translate-x-1 transition-transform">→</span>
      </Button>
      <div className="mt-3 text-center">
        <p className="text-sm text-military-green-700 font-medium">
          Basic features are free forever.
        </p>
      </div>
    </div>
  );
} 