import React, { useState, useEffect } from 'react';
import { Card, Input, Button, Typography, Switch } from "@material-tailwind/react";
import { useNavigate, useParams } from 'react-router-dom';
import { doc, setDoc, getDoc } from "firebase/firestore";
import { db, auth } from '../../FirebaseConfig';
import General from '../../layouts/General';
import RegularButton from '../buttons/RegularButton';

function CreateCustomer() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        active: true,
    });

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((currentUser) => {
            setUser(currentUser);
        });
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const fetchCustomer = async () => {
            if (id && user) {
                const customerRef = doc(db, `recommengine/users/${user.uid}/data/customers`, id);
                const customerSnap = await getDoc(customerRef);
                if (customerSnap.exists()) {
                    setFormData(customerSnap.data());
                }
            }
        };

        fetchCustomer();
    }, [id, user]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!user) return;

        const customerData = {
            ...formData,
            updatedAt: new Date().toISOString(),
        };

        if (!id) {
            customerData.createdAt = new Date().toISOString();
        }

        try {
            const customerId = id || crypto.randomUUID();
            const customerRef = doc(db, `recommengine/users/${user.uid}/data/customers`, customerId);
            await setDoc(customerRef, customerData);
            navigate('/customers');
        } catch (error) {
            console.error("Error saving customer: ", error);
        }
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSwitchChange = () => {
        setFormData(prev => ({
            ...prev,
            active: !prev.active
        }));
    };

    return (
        <General title={id ? "Edit Customer" : "Create Customer"}>
            <div className='flex flex-col w-full px-2 sm:px-6'>
                <div className='bg-white shadow-md rounded px-3 sm:px-8 pt-4 sm:pt-6 pb-6 sm:pb-8 mb-4'>
                    <form onSubmit={handleSubmit}>
                        <div className='grid grid-cols-1 gap-4 sm:gap-6'>
                            <div className='space-y-3 sm:space-y-4'>
                                <div>
                                    <Typography variant='h6' color='blue-gray' className="text-sm font-medium text-gray-500 mb-2">
                                        Personal Information
                                    </Typography>
                                    <div className="space-y-3 sm:space-y-4">
                                        <Input
                                            size="md"
                                            label="First Name"
                                            name="firstName"
                                            value={formData.firstName}
                                            onChange={handleChange}
                                            required
                                        />
                                        <Input
                                            size="md"
                                            label="Last Name"
                                            name="lastName"
                                            value={formData.lastName}
                                            onChange={handleChange}
                                            required
                                        />
                                        <Input
                                            size="md"
                                            label="Email"
                                            name="email"
                                            type="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            required
                                        />
                                        <Input
                                            size="md"
                                            label="Phone"
                                            name="phone"
                                            value={formData.phone}
                                            onChange={handleChange}
                                            required
                                        />
                                        <div className="flex items-center gap-2">
                                            <Switch
                                                label="Active Customer"
                                                checked={formData.active}
                                                onChange={handleSwitchChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='space-y-3 sm:space-y-4'>
                                <div>
                                    <Typography variant='h6' color='blue-gray' className="text-sm font-medium text-gray-500 mb-2">
                                        Address Information
                                    </Typography>
                                    <div className="space-y-3 sm:space-y-4">
                                        <Input
                                            size="md"
                                            label="Street Address"
                                            name="address"
                                            value={formData.address}
                                            onChange={handleChange}
                                            required
                                        />
                                        <Input
                                            size="md"
                                            label="City"
                                            name="city"
                                            value={formData.city}
                                            onChange={handleChange}
                                            required
                                        />
                                        <Input
                                            size="md"
                                            label="State/Province"
                                            name="state"
                                            value={formData.state}
                                            onChange={handleChange}
                                            required
                                        />
                                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-4">
                                            <Input
                                                size="md"
                                                label="ZIP/Postal Code"
                                                name="zip"
                                                value={formData.zip}
                                                onChange={handleChange}
                                                required
                                            />
                                            <Input
                                                size="md"
                                                label="Country"
                                                name="country"
                                                value={formData.country}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col items-center gap-2 mt-4">
                            <RegularButton 
                                onClick={handleSubmit} 
                                type="submit" 
                                text={id ? "Update Customer" : "Create Customer"}
                                variant="black"
                            />
                            <button
                                onClick={() => navigate('/customers')}
                                className="text-gray-600 underline cursor-pointer p-1"
                            >
                                Cancel
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </General>
    );
}

export default CreateCustomer;
