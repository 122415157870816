import React, { useState, useEffect } from 'react';
import { Typography, Button, Dialog, DialogHeader, DialogBody, DialogFooter } from "@material-tailwind/react";
import { useParams, useNavigate, Link } from 'react-router-dom';
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db, auth } from '../../FirebaseConfig';
import LoadingIndicator from '../LoadingIndicator';
import General from '../../layouts/General';
import RegularButton from '../buttons/RegularButton';

function Request() {
    const { id } = useParams();
    const [request, setRequest] = useState(null);
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);
    const navigate = useNavigate();
    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((currentUser) => {
            setUser(currentUser);
            setLoading(false);
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const fetchRequest = async () => {
            if (!user) return;

            try {
                const requestRef = doc(db, `recommengine/users/${user.uid}/data/requests`, id);
                const requestSnap = await getDoc(requestRef);

                if (requestSnap.exists()) {
                    setRequest({ id: requestSnap.id, ...requestSnap.data() });
                } else {
                    console.log('No such request!');
                }
            } catch (error) {
                console.error("Error fetching request: ", error);
            }
        };

        if (user) {
            fetchRequest();
        }
    }, [id, user]);

    const handleEdit = () => {
        navigate(`/requests/edit/${id}`);
    };

    const handleArchive = async () => {
        if (!user || !request) return;
        
        try {
            const requestRef = doc(db, `recommengine/users/${user.uid}/data/requests`, id);
            await updateDoc(requestRef, {
                archived: true,
                archivedAt: new Date().toISOString()
            });
            setOpenDialog(false);
            navigate('/requests');
        } catch (error) {
            console.error("Error archiving request: ", error);
        }
    };

    const getStatusColor = (priority) => {
        switch (priority?.toLowerCase()) {
            case 'high':
                return 'bg-red-100 text-red-800';
            case 'medium':
                return 'bg-yellow-100 text-yellow-800';
            case 'low':
                return 'bg-green-100 text-green-800';
            default:
                return 'bg-blue-gray-100 text-blue-gray-800';
        }
    };

    if (loading) {
        return (
            <General title="Request Details">
                <LoadingIndicator />
            </General>
        );
    }

    if (!user) {
        return (
            <General title="Request Details">
                <div className='flex flex-col w-full h-full items-center justify-center'>
                    <Typography variant='h4'>Please log in to view this</Typography>
                </div>
            </General>
        );
    }

    if (!request) {
        return (
            <General title="Request Details">
                <div className='flex flex-col w-full h-full items-center justify-center'>
                    <LoadingIndicator />
                </div>
            </General>
        );
    }

    return (
        <General title="Request Details">
            <div className='flex flex-col w-full p-3 sm:p-6'>
                <div className='flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 sm:mb-6 gap-2'>
                    <Typography variant='h2' className="text-xl sm:text-2xl text-gray-800 font-bold tracking-tight">
                        Request #{id}
                    </Typography>
                    <div className={`px-4 py-2 rounded-full text-sm font-medium ${getStatusColor(request.priority)}`}>
                        {request.priority?.charAt(0).toUpperCase() + request.priority?.slice(1)}
                    </div>
                </div>
                
                <div className='bg-white shadow-md rounded px-4 sm:px-8 pt-4 sm:pt-6 pb-6 sm:pb-8 mb-4'>
                    <div className='grid grid-cols-1 gap-6'>
                        <div className='space-y-4'>
                            <div>
                                <Typography variant='h6' color='blue-gray' className="text-sm font-medium text-gray-500">
                                    Customer Information
                                </Typography>
                                <Link 
                                    to={`/customers/${request.customerId}`} 
                                    className="text-blue-500 hover:text-blue-700 text-lg font-medium"
                                >
                                    {request.customerName}
                                </Link>
                            </div>
                            
                            <div>
                                <Typography variant='h6' color='blue-gray' className="text-sm font-medium text-gray-500">
                                    Request Type
                                </Typography>
                                <Typography className="text-lg">
                                    {request.requestType}
                                </Typography>
                            </div>
                        </div>

                        <div className='space-y-4'>
                            <div>
                                <Typography variant='h6' color='blue-gray' className="text-sm font-medium text-gray-500">
                                    Contact Information
                                </Typography>
                                <Typography className="text-lg">
                                    {request.email}
                                </Typography>
                                <Typography className="text-lg">
                                    {request.address}
                                </Typography>
                            </div>
                            
                            <div>
                                <Typography variant='h6' color='blue-gray' className="text-sm font-medium text-gray-500">
                                    Created Date
                                </Typography>
                                <Typography className="text-lg">
                                    {new Date(request.date).toLocaleDateString(undefined, {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit'
                                    })}
                                </Typography>
                            </div>
                        </div>
                    </div>

                    <div className='mt-6'>
                        <Typography variant='h6' color='blue-gray' className="text-sm font-medium text-gray-500">
                            Details
                        </Typography>
                        <Typography className="mt-2 text-gray-700 whitespace-pre-wrap">
                            {request.details || 'No details provided'}
                        </Typography>
                    </div>
                </div>

                <div className='flex flex-col items-center gap-2 mt-6'>
                    <RegularButton 
                        onClick={handleEdit}
                        text="Edit Request"
                        variant="black"
                    />
                    <RegularButton 
                        onClick={() => setOpenDialog(true)}
                        text="Archive Request"
                        variant="red"
                    />
                    <button
                        onClick={() => navigate('/requests')}
                        className="text-gray-600 underline cursor-pointer p-1"
                    >
                        Back to Requests
                    </button>
                </div>
            </div>

            <Dialog open={openDialog} handler={() => setOpenDialog(false)} size="sm">
                <DialogHeader>Confirm Archive</DialogHeader>
                <DialogBody>
                    Are you sure you want to archive this request? This action cannot be undone.
                </DialogBody>
                <DialogFooter className="flex gap-2">
                    <Button 
                        variant="text" 
                        color="gray" 
                        onClick={() => setOpenDialog(false)}
                    >
                        Cancel
                    </Button>
                    <Button 
                        variant="gradient" 
                        color="red" 
                        onClick={handleArchive}
                    >
                        Archive
                    </Button>
                </DialogFooter>
            </Dialog>
        </General>
    );
}

export default Request;
