import React, { useState, useEffect } from 'react'
import {
    Button,
    Checkbox,
    Typography,
    Input,
    List,
    ListItem,
    Card,
    Carousel,
    CardBody
} from "@material-tailwind/react";
import RegularButton from './buttons/RegularButton';

export default function AgentContent({ currentUser, myAgents }) {

    const TABLE_HEAD = ["ID", "Name", "Package Name", "Features", "Created At", ""];

    return (
        <div className='flex flex-col mt-2'>
            <RegularButton text="Create New Agent" url="/create-new-agent" />
            <Card className="h-full w-full overflow-scroll">
                <table className="w-full min-w-max table-auto text-left">
                    <thead>
                    <tr>
                        {TABLE_HEAD.map((head, index) => (
                            <th
                                key={index}
                                className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                            >
                                <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal leading-none opacity-70"
                                >
                                    {head}
                                </Typography>
                            </th>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    {myAgents.map((agent, index) => {
                        const isLast = index === myAgents.length - 1;
                        const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";
                        // console.log(agent)
            
                        return (
                        <tr key={`agent-${agent.id}`}>
                            <td className={classes}>
                            <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal"
                                as="a"
                                href={'/agents/' + agent.id}
                            >
                                {agent.name}
                            </Typography>
                            </td>
                            <td className={classes}>
                            <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal"
                                as="a"
                                href={'/agents/' + agent.id}
                            >
                                {agent.name}
                            </Typography>
                            </td>
                            <td className={classes}>
                            <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal"
                            >
                                {agent.id}
                            </Typography>
                            </td>
                            <td className={classes}>
                            {agent.features && Object.keys(agent.features).map((feature) => (
                                agent.features[feature] === true && (
                                    <Typography color="blue-gray">{feature}</Typography>
                                )
                            ))}
                            </td>
                            <td className={classes}>
                            <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal"
                            >
                                {agent.createdAt}
                            </Typography>
                            </td>
                            <td className={classes}>
                            <Typography
                                as="a"
                                href="#"
                                variant="small"
                                color="blue-gray"
                                className="font-medium"
                            >
                                Edit
                            </Typography>
                            </td>
                        </tr>
                        );
                    })}
                    </tbody>
                </table>
            </Card>
        </div>
    );
}