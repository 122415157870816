import React from 'react';
import {
    Card,
    Typography,
    Button,
} from "@material-tailwind/react";
import RegularButton from '../buttons/RegularButton';
import { useNavigate } from 'react-router-dom';

export default function CustomersContent({ currentUser, myCustomers }) {
    const navigate = useNavigate();

    return (
        <div className='flex flex-col mt-2'>
            <Card className="h-full w-full">
                <div className="grid grid-cols-1 gap-4 p-4">
                    {myCustomers.map((customer, index) => (
                        <div 
                            key={`customer-${customer.id}`}
                            className="flex flex-col sm:flex-row bg-white p-4 rounded-lg shadow-sm hover:shadow-md transition-shadow cursor-pointer"
                            onClick={() => navigate('/customers/' + customer.id)}
                        >
                            <div className="flex flex-col space-y-2">
                                <div>
                                    <Typography variant="small" color="blue-gray" className="font-bold text-base">
                                        {`${customer.firstName} ${customer.lastName}`}
                                    </Typography>
                                    <Typography variant="small" color="gray" className="text-sm">
                                        {customer.email}
                                    </Typography>
                                </div>
                                <div className="flex flex-col space-y-1">
                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                        {customer.phone}
                                    </Typography>
                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                        {`${customer.city}, ${customer.state}`}
                                    </Typography>
                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                        {customer.archived ? "Archived" : "Active"}
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </Card>
        </div>
    );
}