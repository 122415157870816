import React from 'react';
import {
    Card,
    Typography,
} from "@material-tailwind/react";
import RegularButton from '../buttons/RegularButton';

const getStatusColor = (priority) => {
    switch (priority?.toLowerCase()) {
        case 'high':
            return 'bg-red-100 text-red-800';
        case 'medium':
            return 'bg-yellow-100 text-yellow-800';
        case 'low':
            return 'bg-green-100 text-green-800';
        default:
            return 'bg-gray-100 text-gray-800';
    }
};

export default function RequestsContent({ currentUser, myRequests }) {
    return (
        <div className='flex flex-col mt-2'>
            <Card className="h-full w-full">
                <div className="grid grid-cols-1 gap-4 p-4">
                    {[...myRequests]
                        .sort((a, b) => {
                            if (a.archived !== b.archived) {
                                return a.archived ? 1 : -1;
                            }
                            return new Date(b.date) - new Date(a.date);
                        })
                        .map((request, index) => (
                            <a 
                                key={`request-${request.id}`}
                                href={'/requests/' + request.id}
                                className="flex flex-col bg-white p-4 rounded-lg shadow-sm hover:shadow-md transition-shadow cursor-pointer"
                            >
                                <div className="flex flex-col space-y-2">
                                    <div className="flex justify-between">
                                        <Typography variant="small" color="blue-gray" className="font-bold text-base">
                                            #{request.requestNumber || 'N/A'} - {request.customerName || 'No Customer'}
                                        </Typography>
                                        {request.archived && (
                                            <span className="px-2 py-1 bg-gray-100 text-gray-600 rounded-full text-xs font-medium">
                                                Archived
                                            </span>
                                        )}
                                    </div>
                                    <div className="flex space-x-4">
                                        <Typography variant="small" color="gray" className="text-sm">
                                            Created: {request.date ? new Date(request.date).toLocaleDateString() : 'No Date'}
                                        </Typography>
                                        <Typography variant="small" color="gray" className="text-sm">
                                            Due: {request.dueDate ? new Date(request.dueDate).toLocaleDateString() : 'Not Set'}
                                        </Typography>
                                    </div>
                                    <div className="flex items-center space-x-2">
                                        <Typography variant="small" color="blue-gray" className="font-normal">
                                            {request.service || 'No Service'}
                                        </Typography>
                                        <div className={`inline-block px-3 py-1 rounded-full text-xs font-medium ${getStatusColor(request.priority)}`}>
                                            {request.priority ? request.priority.charAt(0).toUpperCase() + request.priority.slice(1) : 'No Priority'}
                                        </div>
                                    </div>
                                </div>
                            </a>
                        ))}
                </div>
            </Card>
        </div>
    );
}

